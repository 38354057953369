import { ButtonConfirm } from "@atoms/button/confirm";
import InputDate from "@atoms/input/input-date";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import { ModalContent } from "@atoms/modal/modal";
import { SideModal } from "@atoms/modal/side-modal";
import Select from "@atoms/select";
import { Section, SectionSmall } from "@atoms/text";
import { useReviewGroups } from "@features/agents/state/use-review-groups";
import { CUSTOMER_TYPES_LABELS } from "@features/customers/utils";
import { KytApiClient } from "@features/kyt/api-client/api-client";
import { useTransactions } from "@features/kyt/state/use-transactions";
import { CreateTransactionInput } from "@features/kyt/types";
import { newBlockchainCurrencies } from "@features/kyt/utils";
import _ from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { atom, useRecoilState } from "recoil";
import { TransactionCustomFields } from "./edition-form/transaction-customFields";
const PREFIX_ID = "manual_record_";

export const TransactionEditionModalAtom = atom({
  key: "TransactionEditionModalAtom",
  default: {
    open: false,
    transaction: undefined as CreateTransactionInput | undefined,
  },
});

export const TransactionEditionModal = () => {
  const [transactionModal, setTransactionModal] = useRecoilState(
    TransactionEditionModalAtom
  );

  return (
    <SideModal
      className="bg-slate-100 dark:bg-slate-900"
      open={transactionModal.open}
      onClose={() => {
        setTransactionModal({ open: false, transaction: undefined });
      }}
    >
      {transactionModal.open && <TransactionEditionModalContent />}
    </SideModal>
  );
};

export const TransactionEditionModalContent = () => {
  const blockchainCurrencies = newBlockchainCurrencies();
  const [transactionModal, setTransactionModal] = useRecoilState(
    TransactionEditionModalAtom
  );
  const { reviewGroups } = useReviewGroups({ access: "KYT_AGENT" });
  const [form, setForm] = useState<CreateTransactionInput>();
  const { refresh } = useTransactions();

  useEffect(() => {
    setForm({
      label: transactionModal.transaction?.label ?? null,
      external_id:
        transactionModal.transaction?.external_id ??
        PREFIX_ID +
          (1000 * Date.now() + Math.floor(Math.random() * 1000)).toString(36),
      date: transactionModal.transaction?.date ?? new Date().toISOString(),
      comment: transactionModal.transaction?.comment ?? "",
      context: transactionModal.transaction?.context ?? "",
      review_groups: transactionModal.transaction?.review_groups ?? [],
      from: {
        account_type: transactionModal.transaction?.from?.account_type ?? "",
        external_id: transactionModal.transaction?.from?.external_id ?? "",
        full_name: transactionModal.transaction?.from?.full_name ?? "",
        device: transactionModal.transaction?.from.device ?? null,
        domicile_code: transactionModal.transaction?.from.domicile_code ?? null,
        payment_institution:
          transactionModal.transaction?.from.payment_institution ?? null,
        payment_method:
          transactionModal.transaction?.from.payment_method ?? null,
      },
      to: {
        account_type: transactionModal.transaction?.to?.account_type ?? "",
        external_id: transactionModal.transaction?.to?.external_id ?? "",
        full_name: transactionModal.transaction?.to?.full_name ?? "",
        device: null,
        domicile_code: null,
        payment_institution: null,
        payment_method: null,
      },
      amount: transactionModal.transaction?.amount ?? 0,
      crypto: {
        blockchain: transactionModal.transaction?.crypto?.blockchain ?? null,
        tx_hash: transactionModal.transaction?.crypto?.tx_hash ?? null,
      },
      fields: {},
      currency: transactionModal.transaction?.currency ?? null,
      search_vector: [],
    });
  }, [transactionModal.transaction]);

  return (
    <ModalContent
      title={
        transactionModal.transaction ? (
          <div className="flex flex-row items-center">
            Edit Transaction
            <ButtonConfirm
              size="sm"
              theme="default"
              className="ml-4"
              onClick={() => {
                setTransactionModal({
                  ...transactionModal,
                  open: false,
                });
                setTimeout(() => {
                  setTransactionModal({
                    open: true,
                    transaction: undefined,
                  });
                }, 500);
              }}
            >
              Create new instead
            </ButtonConfirm>
          </div>
        ) : (
          "Add new transaction"
        )
      }
    >
      <div style={{ maxWidth: "800px", width: "100vw" }} className="space-y-4">
        <div className="flex flex-row space-x-2">
          <InputLabel
            label={"Transaction label"}
            className="grow"
            input={
              <Input
                placeholder="Label"
                value={form?.label ?? ""}
                onChange={(e) => {
                  setForm({ ...form!!, label: e.target.value });
                }}
              />
            }
          />
          <InputLabel
            label={"External Id"}
            className="grow"
            input={
              <Input
                placeholder="External Id"
                value={form?.external_id ?? ""}
                onChange={(e) =>
                  setForm({ ...form!, external_id: e.target.value })
                }
              />
            }
          />
        </div>
        <div className="flex flex-row space-x-2">
          <InputLabel
            label={"Transaction date"}
            className="grow"
            input={
              <InputDate
                placeholder="Transaction date"
                value={form?.date ?? ""}
                onChange={(date) => {
                  setForm({ ...form!, date: date?.toISOString() ?? "" });
                }}
              />
            }
          />
          <InputLabel
            label={"Context"}
            className="grow"
            input={
              <Input
                placeholder="Context"
                value={form?.context ?? ""}
                onChange={(e) => {
                  setForm({ ...form!!, context: e.target.value });
                }}
              />
            }
          />
          <InputLabel
            className="grow"
            label="Review groups"
            input={
              <SelectMultiple
                value={form?.review_groups?.map((a) => a.toString()) ?? []}
                onChange={(e) =>
                  setForm({
                    ...form!,
                    review_groups: e,
                  })
                }
                options={_.uniq(reviewGroups).map((a) => ({
                  value: a,
                  label: a,
                }))}
              />
            }
          />
        </div>
        <InputLabel
          className="w-full"
          label="Comment"
          input={
            <Input
              className="focus:z-10 rounded-r-none  min-h-[10rem]  rounded-t-none"
              placeholder="Transaction comment..."
              multiline
              size="sm"
              value={form?.comment ?? ""}
              onChange={(e) => {
                setForm({ ...form!, comment: e.target.value });
              }}
            />
          }
        />

        {form?.fields ? (
          <>
            <hr className="my-6 -mx-6" />
            <TransactionCustomFields form={form} setForm={setForm} />
            <hr className="my-6 -mx-6" />
          </>
        ) : (
          <hr className="my-6 -mx-6" />
        )}

        <Section>Participants</Section>
        <div className="flex flex-row space-x-2">
          <div className="flex flex-col space-y-2 grow">
            <SectionSmall className="opacity-50">From</SectionSmall>
            <InputLabel
              label={"Customer type"}
              input={
                <Select
                  value={form?.from?.account_type ?? ""}
                  onChange={(e) => {
                    setForm({
                      ...form!,
                      from: {
                        ...form!?.from!,
                        account_type: e.target.value,
                      },
                    });
                  }}
                >
                  <option value={""} disabled>
                    Select customer type
                  </option>
                  {_.uniq([
                    "N",
                    "F",
                    //   form.customers[0].account_type || "N",
                  ]).map((key) => (
                    <option key={key} value={key}>
                      {CUSTOMER_TYPES_LABELS[key]}
                    </option>
                  ))}
                </Select>
              }
            />
            <InputLabel
              label={"External Id"}
              input={
                <Input
                  required
                  placeholder="External Id"
                  value={form?.from?.external_id ?? ""}
                  onChange={(e) => {
                    setForm({
                      ...form!,
                      from: {
                        ...form!?.from!,
                        external_id: e.target.value,
                      },
                    });
                  }}
                />
              }
            />
            <InputLabel
              label={"Full name"}
              input={
                <Input
                  placeholder="Full name"
                  value={form?.from?.full_name ?? ""}
                  onChange={(e) => {
                    setForm({
                      ...form!,
                      from: {
                        ...form!?.from!,
                        full_name: e.target.value,
                      },
                    });
                  }}
                />
              }
            />
          </div>
          <div className="flex flex-col space-y-2 grow">
            <SectionSmall className="opacity-50">To</SectionSmall>
            <InputLabel
              label={"Customer type"}
              input={
                <Select
                  value={form?.to?.account_type ?? ""}
                  onChange={(e) => {
                    setForm({
                      ...form!,
                      to: {
                        ...form!?.to!,
                        account_type: e.target.value,
                      },
                    });
                  }}
                >
                  <option value={""} disabled>
                    Select customer type
                  </option>
                  {_.uniq([
                    "N",
                    "F",
                    //   form.customers[0].account_type || "N",
                  ]).map((key) => (
                    <option key={key} value={key}>
                      {CUSTOMER_TYPES_LABELS[key]}
                    </option>
                  ))}
                </Select>
              }
            />
            <InputLabel
              label={"External Id"}
              input={
                <Input
                  placeholder="External Id"
                  value={form?.to?.external_id ?? ""}
                  onChange={(e) => {
                    setForm({
                      ...form!,
                      to: {
                        ...form!?.to!,
                        external_id: e.target.value,
                      },
                    });
                  }}
                />
              }
            />
            <InputLabel
              label={"Full name"}
              input={
                <Input
                  placeholder="Full name"
                  value={form?.to?.full_name ?? ""}
                  onChange={(e) => {
                    setForm({
                      ...form!,
                      to: {
                        ...form!?.to!,
                        full_name: e.target.value,
                      },
                    });
                  }}
                />
              }
            />
          </div>
        </div>

        <hr className="my-6 -mx-6" />

        <Section>Transaction</Section>
        <div className="flex flex-col space-y-2">
          <SectionSmall className="opacity-50">Amount</SectionSmall>
          <div className="flex flex-row space-x-2">
            <InputLabel
              label="Amount"
              className="w-1/3"
              input={
                <Input
                  highlight
                  required
                  value={form?.amount ?? ""}
                  type="number"
                  onChange={(e) => {
                    setForm({ ...form!, amount: Number(e.target.value) });
                  }}
                  placeholder="Amount"
                />
              }
            />
            <InputLabel
              label="Currency"
              className="w-1/3"
              input={
                <SelectMultiple
                  highlight
                  disabled={!form?.crypto?.blockchain}
                  selectionLimit={1}
                  value={form?.currency ? [form.currency] : []}
                  onChange={(e) => {
                    setForm({
                      ...form!,
                      currency: e[0],
                    });
                  }}
                  options={[
                    { value: "converted", label: "~ Converted (EUR)" },
                    { value: "EUR", label: "EUR" },
                    ...Object.keys(blockchainCurrencies)
                      .flatMap((blockchain) =>
                        blockchainCurrencies[blockchain].map(
                          (currency) => currency
                        )
                      )
                      .map((currency) => ({
                        value: currency.code,
                        label: currency.label,
                      })),
                  ]}
                />
              }
            />
          </div>
          <SectionSmall className="opacity-50">Crypto</SectionSmall>
          <div className="flex flex-row space-x-2">
            <InputLabel
              label="Blockchain"
              className="w-1/3"
              input={
                <SelectMultiple
                  highlight
                  selectionLimit={1}
                  value={
                    form?.crypto?.blockchain
                      ? [form.crypto.blockchain]
                      : ['{ value: "EUR", label: "EUR" }']
                  }
                  onChange={(e) => {
                    setForm({
                      ...form!,
                      crypto: {
                        ...form!?.crypto!,
                        blockchain: e[0],
                      },
                    });
                  }}
                  options={Object.keys(blockchainCurrencies).map(
                    (blockchain) => ({
                      value: blockchain,
                      label: blockchain,
                    })
                  )}
                />
              }
            />
            <InputLabel
              label="Transaction Hash"
              className="w-full"
              input={
                <Input
                  highlight
                  value={form?.crypto?.tx_hash ?? ""}
                  onChange={(e) => {
                    setForm({
                      ...form!,
                      crypto: {
                        ...form!?.crypto!,
                        tx_hash: e.target.value,
                      },
                    });
                  }}
                  placeholder="0x1234567890abcdef1234567890abcdef1234567890abcdef1234567890abcdef"
                />
              }
            />
          </div>
        </div>
        <ButtonConfirm
          className="float-right"
          theme="outlined"
          disabled={
            !form?.date ||
            !form?.external_id ||
            !form?.amount ||
            !form?.from?.external_id ||
            !form?.to?.external_id
          }
          onClick={async () => {
            try {
              if (form?.currency?.includes("CUSTOM_")) {
                form.currency = form?.currency?.replace(/\bCUSTOM_/g, "");
              }
              await KytApiClient.createTransaction(form!);
              refresh();
              setTransactionModal({ open: false, transaction: undefined });
            } catch (error) {
              toast.error("Error creating transaction");
            } finally {
              console.log(form);
            }
          }}
        >
          Create transaction
        </ButtonConfirm>
      </div>
    </ModalContent>
  );
};
