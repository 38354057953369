import Tabs from "@atoms/tabs";
import { RestSearchRequest } from "@components/search-bar/utils/types";
import { buildQueryFromMap } from "@components/search-bar/utils/utils";
import { Table } from "@components/table";
import { useAssignments } from "@features/assignments/state/use-assignment";
import { useAuth } from "@features/auth/state/hooks";
import {
  useRealtimeThreads,
  useThreads,
} from "@features/inbox/state/use-threads";
import { InboxThread, InboxThreadStatus } from "@features/inbox/types";
import { ROUTES } from "@features/routes";
import { ErrorBoundary } from "@sentry/react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import ThreadCard from "./components/thread-card";
import ThreadMessages from "./messages-view";
import { InboxModalContext, InboxModalThreadStateAtom } from "./modal";
import { useThread } from "@features/inbox/state/use-thread";
import { Info } from "@atoms/text";
import SearchBarInbox from "./filters";
import _ from "lodash";

const activeTabLocalStorageKey = "go-chat-active-tab";
function useActiveTab() {
  const [tab, setTab] = useState<string | number>(
    (() => {
      const chatActiveTab = localStorage.getItem(activeTabLocalStorageKey);
      if (!chatActiveTab) {
        return 1;
      }
      const activeTab = parseInt(chatActiveTab);
      if (Number.isNaN(activeTab)) {
        return chatActiveTab;
      }
      return activeTab;
    })()
  );

  const setActiveTab = useCallback((tab: number | string) => {
    localStorage.setItem(activeTabLocalStorageKey, tab.toString());
    setTab(tab);
  }, []);

  return { setActiveTab, activeTab: tab };
}

export default function InboxPage() {
  const { user } = useAuth();
  const { activeTab, setActiveTab } = useActiveTab();
  const [searchBar, setSearchBarState] = useState<RestSearchRequest>({
    query: [],
    options: {},
  });
  const { inModal, customerId: modalCustomerId } =
    useContext(InboxModalContext);

  const searchQuery = useMemo(() => {
    return {
      query: [
        ...(searchBar.query || []),
        ...buildQueryFromMap({
          ...(modalCustomerId && {
            customer_ids: modalCustomerId,
          }),
          ...(activeTab === "mine" && {
            assignees: [user?.id],
          }),
          ...{
            status:
              typeof activeTab === "string"
                ? activeTab == "mine"
                  ? InboxThreadStatus.OPEN
                  : ""
                : activeTab,
          },
        }),
      ],
      options: searchBar.options,
    };
  }, [
    searchBar.query,
    searchBar.options,
    activeTab,
    user?.id,
    modalCustomerId,
  ]);

  const { threads, loading, schema } = useThreads(searchQuery);
  useAssignments("thread", threads?.data?.map((a) => a.id) || []);
  useRealtimeThreads();

  const navigate = useNavigate();
  let { id: selectedThreadId } = useParams();
  const [stateSelectedThread, setStateSelectedThread] = useRecoilState(
    InboxModalThreadStateAtom
  );

  if (inModal) selectedThreadId = stateSelectedThread || "";

  const { thread: currentThread } = useThread(
    selectedThreadId === "all" ? "" : selectedThreadId || ""
  );

  const openThread = (id: string) => {
    if (inModal) {
      setStateSelectedThread(id);
    } else {
      navigate(ROUTES.Inbox.replace(":id", id));
    }
  };

  // Si search / tab / page change on selectionne le premier dès qu'on a de la data du back
  useEffect(() => {
    if (threads?.data && threads.data.length > 0 && selectedThreadId === "all")
      openThread(threads.data[0].id);
  }, [threads]);

  useEffect(() => {
    openThread("all");
  }, [activeTab, searchBar]);

  const setSearchBar = useCallback(
    (newSearchBar: RestSearchRequest) => {
      if (!_.isEqual(newSearchBar.query, searchBar.query)) {
        setActiveTab("");
      }
      setSearchBarState(newSearchBar);
    },
    [searchBar.query, setActiveTab]
  );

  return (
    <div className="flex h-full flex-row">
      <div className="flex w-80 shrink-0 flex-col border-r lg:w-96 dark:border-slate-700">
        <SearchBarInbox
          value={searchBar}
          schema={schema}
          onChange={setSearchBar}
        />
        <div className="ml-5 flex flex-col">
          <div className="grow -ml-7 -mr-4">
            <Tabs
              className="px-4"
              tabs={[
                { value: "mine", label: "Me" },
                { value: InboxThreadStatus.OPEN, label: "Open" },
                { value: InboxThreadStatus.REMINDER, label: "Reminder" },
                { value: InboxThreadStatus.WAITING, label: "Waiting" },
                { value: InboxThreadStatus.CLOSED, label: "Closed" },
                { value: "", label: "All" },
              ]}
              value={activeTab}
              onChange={(e) => setActiveTab(e)}
            />
          </div>
        </div>
        <Table
          className="-mt-px h-full w-full grow"
          tableClassName="!w-full"
          cellClassName={(thread: InboxThread) =>
            "!items-start !p-0 " +
            (selectedThreadId === thread.id &&
              "bg-blue-100 dark:bg-blue-800 border-blue-200 dark:border-slate-600")
          }
          scrollable
          loading={loading}
          showPagination
          initialPagination={{
            page: 1,
            perPage: searchBar.options.limit ?? 10,
          }}
          total={threads?.total || 0}
          data={[
            ...(currentThread &&
            !(threads?.data || []).map((a) => a.id).includes(currentThread.id)
              ? [{ ...currentThread, not_in_list: true }]
              : []),
            ...(threads?.data || []),
          ]}
          onClick={(thread: InboxThread) => openThread(thread.id)}
          columns={[
            {
              className: "p-0",
              render: (thread: InboxThread & { not_in_list?: boolean }) => (
                <ThreadCard thread={thread} noInList={thread?.not_in_list} />
              ),
            },
          ]}
          onRequestData={async ({ page, perPage }) => {
            setSearchBar({
              ...searchBar,
              options: {
                ...searchBar.options,
                offset: (page - 1) * perPage,
                limit: perPage,
              },
            });
          }}
        />
      </div>
      <div className="h-full w-full grow overflow-hidden">
        {!!selectedThreadId && selectedThreadId !== "all" && !loading && (
          <ErrorBoundary>
            <ThreadMessages
              key={selectedThreadId}
              threadId={selectedThreadId!}
            />
          </ErrorBoundary>
        )}
        {(!selectedThreadId || selectedThreadId === "all") && !loading && (
          <div className="flex justify-center items-center h-full w-full">
            <Info>Select a thread on the left first</Info>
          </div>
        )}
      </div>
    </div>
  );
}
