import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import Link from "@atoms/link";
import { Info } from "@atoms/text";
import { AssigneeTag } from "@components/assignations/tag";
import { KYCLevelType } from "@features/customers/types";
import { getKYCLevelFromString } from "@features/customers/utils";
import { ReportSearchResultType } from "@features/press/types";
import {
  pressLevelToNumber,
  REPORT_EDD_STATUS_COLORS,
  REPORT_RISK_LEVEL_COLORS,
} from "@features/press/utils";
import { ROUTES } from "@features/routes";
import {
  BuildingOfficeIcon,
  EyeIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { KYC_LEVEL_LABEL } from "@views/client/customers/customer-details/olkypass";
import { upperFirst } from "lodash";
import { twMerge } from "tailwind-merge";

export const availableColumns = Object.assign({
  primary_external_id: {
    title: "External ID",
    orderable: true,
    render: (row: ReportSearchResultType) => (
      <Info
        className="whitespace-nowrap overflow-hidden text-ellipsis max-w-40"
        data-tooltip={row.customer_external_ids?.[0]}
      >
        {row.customer_external_ids?.[0]}
      </Info>
    ),
  },
  full_name: {
    title: "Name",
    orderable: true,
    thClassName: "w-96",
    render: (row: ReportSearchResultType) => (
      <div className="flex items-center">
        {!!(
          row.customer_trading_names?.[0] || row.customer_company_names?.[0]
        ) && <BuildingOfficeIcon className="h-5 w-5 inline mr-2 shrink-0" />}
        {!(
          row.customer_trading_names?.[0] || row.customer_company_names?.[0]
        ) && <UserIcon className="h-5 w-5 inline mr-2 shrink-0" />}
        {[
          row.customer_company_names?.[0],
          row.customer_trading_names?.[0],
          row.customer_full_names?.[0],
        ].join(" ")}
      </div>
    ),
  },
  customer_date_of_birth: {
    title: "Birth Date",
    thClassName: "whitespace-nowrap",
    orderable: true,
    render: (row: ReportSearchResultType) => (
      <Info className="whitespace-nowrap">
        {
          new Date(row.customer_date_of_birth * 1000)
            .toISOString()
            .split("T")[0]
        }
      </Info>
    ),
  },
  customer_first_names: {
    title: "First Name",
    thClassName: "whitespace-nowrap",
    orderable: true,
    render: (row: ReportSearchResultType) => (
      <Info className="whitespace-nowrap">{row.customer_first_names}</Info>
    ),
  },
  customer_middle_names: {
    title: "Middle Name",
    thClassName: "whitespace-nowrap",
    orderable: true,
    render: (row: ReportSearchResultType) => (
      <Info className="whitespace-nowrap">{row.customer_middle_names}</Info>
    ),
  },
  customer_last_names: {
    title: "Last Name",
    thClassName: "whitespace-nowrap",
    orderable: true,
    render: (row: ReportSearchResultType) => (
      <Info className="whitespace-nowrap">{row.customer_last_names}</Info>
    ),
  },
  last_search_at: {
    title: "Last Search",
    thClassName: "whitespace-nowrap",
    orderable: true,
    render: (row: ReportSearchResultType) => (
      <Info className="whitespace-nowrap">
        {new Date((row.last_search_at || 0) * 1000).toISOString().split("T")[0]}
      </Info>
    ),
  },
  updated_at: {
    title: "Last Updated",
    thClassName: "whitespace-nowrap",
    orderable: true,
    render: (row: ReportSearchResultType) => (
      <Info className="whitespace-nowrap">
        {new Date((row.updated_at || 0) * 1000).toISOString().split("T")[0]}
      </Info>
    ),
  },
  customer_nationality_code: {
    title: "Nationality",
    orderable: true,
    render: (row: ReportSearchResultType) => (
      <Info className="whitespace-nowrap">{row.customer_nationality_code}</Info>
    ),
  },
  review_groups: {
    title: "Groups",
    orderable: true,
    thClassName: "whitespace-nowrap",
    render: (row: ReportSearchResultType) => (
      <div className="flex items-center">
        {row.review_groups?.map((group) => (
          <Tag key={group} className="mx-1 my-1">
            {group}
          </Tag>
        ))}
      </div>
    ),
  },
  risk_categories: {
    title: "Categories",
    render: (row: ReportSearchResultType) => (
      <>
        {row.risk_categories?.map((category) => (
          <Tag key={category} className="mx-1 my-1">
            {category}
          </Tag>
        ))}
      </>
    ),
  },
  "customer.kyc_level": {
    title: KYC_LEVEL_LABEL,
    headClassName: "justify-end",
    orderable: true,
    render: (row: ReportSearchResultType) => {
      const kycLevel = getKYCLevelFromString(row.customer.kyc_level);
      return (
        kycLevel && (
          <Info
            className={twMerge(
              "whitespace-nowrap rounded-md px-2 py-1",
              "text-" + kycLevel.color
            )}
          >
            {kycLevel.type !== KYCLevelType.KYC_LEVEL_UNKNOWN &&
              `${kycLevel.type} - ${kycLevel.label}`}
          </Info>
        )
      );
    },
  },
  robot_overall_risk_level: {
    title: "Unverified Risk",
    render: (row: ReportSearchResultType) => {
      const level =
        pressLevelToNumber(row.robot_overall_risk_level) >
        pressLevelToNumber(row.active_overall_risk_level)
          ? row.robot_overall_risk_level
          : row.active_overall_risk_level;
      return (
        <Tag
          noColor
          className={
            "mx-1 my-1 text-white bg-" +
            (REPORT_RISK_LEVEL_COLORS[level] || "slate-500")
          }
        >
          {upperFirst(level)}
        </Tag>
      );
    },
  },
  active_overall_risk_level: {
    title: "Active Risk",
    orderable: true,
    render: (row: ReportSearchResultType) => (
      <Tag
        noColor
        className={
          "mx-1 my-1 text-white bg-" +
          (REPORT_RISK_LEVEL_COLORS[row.active_overall_risk_level] ||
            "slate-500")
        }
      >
        {upperFirst(row.active_overall_risk_level)}
      </Tag>
    ),
  },
  edd_state: {
    title: "State",
    orderable: true,
    render: (row: ReportSearchResultType) => (
      <>
        <Tag
          noColor
          className={
            "whitespace-nowrap mx-1 my-1 text-white bg-" +
            (REPORT_EDD_STATUS_COLORS[row.edd_state] || "slate-500")
          }
        >
          {row.edd_state.replaceAll("_", " ")}
        </Tag>
      </>
    ),
  },
  assignees: {
    title: "",
    className: "text-right",
    headClassName: "justify-end",
    thClassName: "w-24",
    render: (row: ReportSearchResultType) => (
      <>
        <AssigneeTag type="pressreport" id={row.customer_id} />
      </>
    ),
  },
  actions: {
    className: "text-right justify-end",
    headClassName: "justify-end",
    thClassName: "w-24",
    render: (row: ReportSearchResultType) => (
      <Link
        to={ROUTES.PressReportView.replace(":customer_id", row.customer_id)}
      >
        <Button
          size="sm"
          theme="outlined"
          className="px-2 sticky right-0"
          data-tooltip="Open report"
        >
          <EyeIcon className="w-4 h-4" />
        </Button>
      </Link>
    ),
  },
});
