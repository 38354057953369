import Avatar from "@atoms/avatar/avatar";
import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { Base, InfoSmall, Section } from "@atoms/text";
import { useHasAccess } from "@features/auth/state/use-access";
import { useCustomerStates } from "@features/customers/state/user-customer-states";
import { CustomerEddRevision } from "@features/customers/types";
import { STATES_COLORS } from "@features/customers/utils";
import { formatTime } from "@features/utils/dates";
import { CodeBracketIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { useRecoilState } from "recoil";
import { CustomerStatusUpdateModalAtom } from "./status-update-modal";

export const CustomerTimeline = ({
  disabled,
  revisions,
}: {
  disabled?: boolean;
  revisions: CustomerEddRevision[];
}) => {
  const { states } = useCustomerStates();
  const [modal, setModal] = useRecoilState(CustomerStatusUpdateModalAtom);
  const hasAccess = useHasAccess();

  return (
    <>
      <Section className="mt-4 mb-4">
        Status
        <div className="float-right">
          {hasAccess("CUSTOMER_UPDATE") && (
            <Button
              onClick={() =>
                setModal({
                  ...modal,
                  open: true,
                  customer_id: revisions[0].customer_id,
                })
              }
              className="ml-4"
              theme="outlined"
              size="sm"
              disabled={disabled}
              shortcut={["u"]}
            >
              Update status
            </Button>
          )}
        </div>
      </Section>
      <ol className="relative border-l border-gray-200 dark:border-gray-700 ml-3">
        {_.sortBy(revisions, (d) => -new Date(d.created_at).getTime()).map(
          (revision, index) => (
            <li
              key={index}
              className={
                "mb-2 ml-6 transition-opacity " +
                (index === 0 ? "" : " opacity-75 hover:opacity-100 ")
              }
            >
              <span className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-slate-500 rounded-full">
                {!revision.agent_name && (
                  <CodeBracketIcon className="h-4 w-4 text-white" />
                )}
                {!!revision.agent_name && (
                  <Avatar size={6} fallback={revision.agent_name} />
                )}
              </span>
              {index === 0 && (
                <Tag
                  noColor
                  className={
                    " text-white capitalize block bg-" +
                    (isRevisionPassedWithCustomNextReviewDate(revision)
                      ? "blue-500"
                      : STATES_COLORS[`${revision.edd_state}`])
                  }
                >
                  {(states[revision.edd_state] || "Unknown") +
                    (isRevisionPassedWithCustomNextReviewDate(revision)
                      ? " until " + revision.next_review_date.split("T")[0]
                      : "")}
                </Tag>
              )}
              {index > 0 && (
                <Tag
                  noColor
                  className={
                    " text-white capitalize block bg-" +
                    (isRevisionPassedWithCustomNextReviewDate(revision)
                      ? "blue-500/50"
                      : (STATES_COLORS[`${revision.edd_state}`] + "/50"))
                  }
                >
                  {(states[revision.edd_state] || "Unknown") +
                    (isRevisionPassedWithCustomNextReviewDate(revision)
                      ? " until " + revision.next_review_date.split("T")[0]
                      : "")}
                </Tag>
              )}
              <Base className="whitespace-pre-wrap">{revision.comment}</Base>
              <InfoSmall className="block mt-1">
                {revision.agent_name || "System"} •{" "}
                {formatTime(revision.created_at)}
              </InfoSmall>
            </li>
          )
        )}
      </ol>
    </>
  );
};

const isRevisionPassedWithCustomNextReviewDate = (
  revision: CustomerEddRevision
) => {
  const nextReviewDateAsDate = Date.parse(revision.next_review_date);
  const revisionCreatedDateAsDate = Date.parse(revision.created_at);
  return (
    revision.edd_state === 4 && nextReviewDateAsDate > revisionCreatedDateAsDate
  );
};
