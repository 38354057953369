import InputDate from "@atoms/input/input-date";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import { SearchBar } from "@components/search-bar";
import {
  OutputQuery,
  RestSearchQuery,
  RestSearchRequest,
  SchemaSearchField,
} from "@components/search-bar/utils/types";
import { buildQueryFromMap } from "@components/search-bar/utils/utils";
import { useAgents } from "@features/agents/state/use-agents";
import { useReviewGroups } from "@features/agents/state/use-review-groups";
import { useAlertCategories } from "@features/alerts/state/use-alert-categories";
import { useAlertStates } from "@features/alerts/state/use-alert-states";
import { useAlerts } from "@features/alerts/state/use-alerts";
import { AlertsRequestOptions } from "@features/alerts/types";
import { categoryToName } from "@features/alerts/utils";
import { AuthJWT } from "@features/auth/jwt";
import { useStoredState } from "@features/utils/use-stored-state";
import _, { isEqual } from "lodash";
import { useEffect, useState } from "react";

export const AlertsListFilters = ({
  onChange,
}: {
  onChange: (value: RestSearchRequest) => void;
}) => {
  const [filters, setFilters] = useStoredState<Partial<AlertsRequestOptions>>(
    "alerts_filters",
    {}
  );
  const { categories } = useAlertCategories();
  const { states } = useAlertStates();
  const { reviewGroups } = useReviewGroups({ access: "ALERT" });
  const { members: agents } = useAgents();
  const { schema } = useAlerts();
  const [advancedQuery, setAdvancedQuery] = useState<RestSearchQuery[]>([]);

  useEffect(() => {
    const formDate = _.omitBy(filters?.date, _.isNil);
    const formDateLte = formDate.lte && (new Date(formDate.lte) as Date);
    if (formDateLte) {
      formDateLte.setTime(formDateLte.getTime() + 24 * 60 * 60 * 1000);
      formDateLte.setTime(formDateLte.getTime() - 1);
    }

    onChange({
      query: [
        ...advancedQuery,
        ...buildQueryFromMap(
          {
            ...((filters?.score_from || filters?.score_to) && {
              alert_revision_score: {
                range_number: {
                  gte: filters?.score_from,
                  lte: filters?.score_to,
                },
              },
            }),
            ...(Object.keys(formDate).length > 0 && {
              alert_revision_creation_date: {
                range_date: { ...formDate, lte: formDateLte },
              },
            }),
          },
          "range"
        ),
        ...buildQueryFromMap({
          alert_revision_categories: filters?.categories,
          ...(filters?.states && {
            alert_revision_state: filters.states.map((state) => ({
              number: state,
            })),
          }),
        }),
        ...buildQueryFromMap({
          ...(filters?.assigned_to_member_id && {
            assignees: filters?.assigned_to_member_id,
          }),
        }),
        ...buildQueryFromMap({
          review_groups: filters?.only_without_review_groups
            ? undefined
            : filters?.review_groups,
          assignees: filters?.only_without_assignment
            ? agents.map((a) => a.id)
            : undefined,
        }).map((a) => ({ ...a, not: false })),
      ],
      options: {},
    });
  }, [advancedQuery, filters]);

  // Need agents actives
  const agentsActives = agents.filter(
    (agent) =>
      agent.active &&
      agent.clients.filter(
        (client) => client.active && client.id === AuthJWT.clientId
      ).length > 0
  );

  return (
    <div className="flex flex-col space-y-2 xl:flex-row xl:space-x-2 xl:space-y-0">
      <div className="w-full xl:w-1/2 flex flex-col">
        <InputLabel
          className="min-w-32"
          label="Search"
          input={
            <SearchBar
              schema={{
                table: "alerts",
                fields: [
                  ...schema.filter(
                    (field) => !field.possible_additional_keys?.length
                  ),
                  ...schema
                    .filter((field) => field.possible_additional_keys?.length)
                    .reduce((acc: SchemaSearchField[], field) => {
                      return [
                        ...acc,
                        ...(field.possible_additional_keys || []).map(
                          (key) => ({
                            value_type: key.value_type,
                            external_key: `${field.external_key}.${key.key}`,
                            display_name: `${field.external_key}: ${key.display_name}`,
                            allowed_ops: field.allowed_ops,
                            possible_values: field.possible_values,
                          })
                        ),
                      ];
                    }, []),
                ]
                  .filter((field) => field.external_key !== "assignees") // Due to a strange bug with this one
                  .map((field) => {
                    let type:
                      | "number"
                      | "boolean"
                      | "text"
                      | "date"
                      | `type:${string}`;

                    switch (field.value_type) {
                      case "string":
                        type = "text";
                        break;
                      case "number":
                        type = "number";
                        break;
                      case "boolean":
                        type = "boolean";
                        break;
                      case "date":
                        type = "date";
                        break;
                      // Add more cases if needed
                      default:
                        type = `type:${field.value_type}`;
                    }
                    return {
                      key: field.external_key,
                      label: field.display_name,
                      keywords: (
                        field.display_name +
                        " " +
                        field.display_name.replace(/ /gm, "") +
                        " " +
                        field.external_key
                      ).split(/( |_)/),
                      allowed_ops: field.allowed_ops,
                      possible_values: field.possible_values,
                      type,
                    };
                  }),
              }}
              onSuggest={async (_table: string, column: string) => {
                if (column === "review_groups") {
                  return reviewGroups.map((a) => ({ value: a, label: a }));
                }
                if (column === "assignees") {
                  return agentsActives.map(({ id, name }) => ({
                    value: id.toString(),
                    label: name,
                  }));
                }

                const field = schema.find((f) => f.external_key === column);
                if (!field?.possible_values) return [];
                return [
                  ...field.possible_values.map((v) => ({
                    value: v.value,
                    label: v.label,
                  })),
                  ...((field.allowed_ops || []).includes("is_null")
                    ? [{ value: null, label: "None" }]
                    : []),
                ];
              }}
              onChange={(str: OutputQuery) => {
                if (str.valid && !isEqual(str.fields, advancedQuery)) {
                  setAdvancedQuery(str.fields);
                }
              }}
            />
          }
        />
      </div>
      <div className="flex flex-row gap-2">
        <div className="w-32">
          <InputLabel
            label="Score range"
            input={
              <div className="flex">
                <Input
                  className="rounded-r-none"
                  highlight
                  value={filters?.score_from || ""}
                  type="number"
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      score_from: parseInt(e.target.value) || null,
                    })
                  }
                  placeholder="0"
                />
                <Input
                  className="rounded-l-none -ml-px"
                  highlight
                  value={filters?.score_to || ""}
                  type="number"
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      score_to: parseInt(e.target.value) || null,
                    })
                  }
                  placeholder="100"
                />
              </div>
            }
          />
        </div>
        <div className="w-64">
          <div className="flex flex-row relative">
            <InputLabel
              label="Updated After"
              className="w-full xl:w-1/2 focus-within:z-10 -mr-px"
              input={
                <InputDate
                  className="rounded-r-none"
                  highlight
                  value={filters?.date?.gte ?? null}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      date: { ...filters?.date, gte: e?.toISOString() ?? null },
                    })
                  }
                />
              }
            />
            <InputLabel
              label="Updated Before"
              className="w-full xl:w-1/2 focus-within:z-10"
              input={
                <InputDate
                  className="rounded-l-none"
                  highlight
                  value={filters?.date?.lte ?? null}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      date: { ...filters?.date, lte: e?.toISOString() ?? null },
                    })
                  }
                />
              }
            />
          </div>
        </div>
        <InputLabel
          className="w-32"
          label="Assigned to"
          input={
            <SelectMultiple
              highlight
              selectionLimit={1}
              value={
                filters?.only_without_assignment
                  ? ["none"]
                  : filters?.assigned_to_member_id
                  ? [filters.assigned_to_member_id.toString()]
                  : []
              }
              options={[
                { value: "none", label: "(None)" },
                ..._.sortBy(
                  agentsActives.map((agent) => ({
                    value: agent.id.toString(),
                    label: agent.name,
                  })) || [],
                  "label"
                ),
              ]}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  only_without_assignment: e.includes("none"),
                  assigned_to_member_id:
                    !e.includes("none") && e.length > 0 ? parseInt(e[0]) : null,
                })
              }
            />
          }
        />
        <InputLabel
          className="w-32"
          label="Review groups"
          input={
            <SelectMultiple
              highlight
              value={
                filters?.only_without_review_groups
                  ? ["none"]
                  : filters?.review_groups || []
              }
              options={[
                { value: "none", label: "(None)" },
                ..._.sortBy(
                  reviewGroups.map((c) => ({
                    value: c,
                    label: c,
                  })),
                  "label"
                ),
              ]}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  only_without_review_groups: e.includes("none"),
                  review_groups: (!e.includes("none") && e) || [],
                })
              }
            />
          }
        />
        <InputLabel
          className="w-32"
          label="Categories"
          input={
            <SelectMultiple
              highlight
              value={filters?.categories || []}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  categories: e || [],
                })
              }
              options={_.sortBy(
                Object.keys(categories).filter((c) => categories[c] !== "rca"),
                (c) => (categories[c] || "").replace("rca_", "zzz_")
              ).map((c) => ({
                value: categories[c],
                label: categoryToName[categories[c]] || categories[c],
              }))}
            />
          }
        />
        <InputLabel
          className="w-32"
          label="Status"
          input={
            <SelectMultiple
              highlight
              value={filters?.states?.map((a) => `${a}`) || []}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  states: e.map((a) => parseInt(a)) || [],
                })
              }
              options={Object.keys(states).map((c) => ({
                value: `${c}`,
                label: _.capitalize(states[parseInt(c)]),
              }))}
            />
          }
        />
      </div>
    </div>
  );
};
