import { RestSearchRequest } from "@components/search-bar/utils/types";
import { useScenarios } from "@features/scenarios/state/use-scenarios";
import { delayRequest } from "@features/utils";
import { LoadingAtom } from "@features/utils/loading";
import { renameKYCLevelOnSearchField } from "@views/client/customers/customer-details/olkypass";
import { useCallback } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useRecoilState } from "recoil";
import { SessionsApiClient } from "../api-client/api-client";
import { SessionListType } from "../types";
import { getSessionAvatar, getVersion } from "../utils";

export const useSessions = (query?: RestSearchRequest) => {
  const [loading, setLoading] = useRecoilState(LoadingAtom("useSessions"));
  const { scenarios } = useScenarios();

  const queryClient = useQueryClient();

  const refresh = useCallback(async () => {
    setLoading(true);
    delayRequest("useSessionsRefresh", async () => {
      queryClient.invalidateQueries("sessions");
    });
  }, []);

  const schema = useQuery({
    queryKey: ["sessions-schema"],
    queryFn: SessionsApiClient.getSchema,
    select: (schema) => renameKYCLevelOnSearchField(schema),
  });

  const sessions = useQuery<{ data: SessionListType[]; total: number }>({
    queryKey: ["sessions", query],
    queryFn: async () => {
      const threadSearch = await SessionsApiClient.getSessionsSearch(
        query?.query || [],
        query?.options || {}
      );

      return threadSearch;
    },
    cacheTime: 2000,
  });

  const fetchSessions = async (req: RestSearchRequest) => {
    return await SessionsApiClient.getSessionsSearch(req.query, req.options);
  };

  const fetchAvatarSessionWithExternalID = async (req: RestSearchRequest) => {
    setLoading(true);
    const customerLatestSessions = (await fetchSessions(req)).data;
    if (!customerLatestSessions) {
      setLoading(false);
      return;
    }

    if (customerLatestSessions.length > 0) {
      let i = 0;
      let tempAvatar = undefined;
      while (!tempAvatar && i < customerLatestSessions.length) {
        const session = await SessionsApiClient.getSession(
          customerLatestSessions[i].session_id
        );
        session.version = getVersion(session);
        tempAvatar = getSessionAvatar(session);
        i++;
      }

      setLoading(false);
      return tempAvatar;
    }
    setLoading(false);
  };

  return {
    sessions: sessions?.data,
    schema: schema?.data || [],
    fetchSessions,
    fetchAvatarSessionWithExternalID,
    loading: loading || sessions.isLoading || schema.isLoading,
    scenarios,
    refresh,
  };
};
