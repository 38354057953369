import { DoughnutChart } from "@components/dognut-chart";
import { useDashboard } from "@features/monitoring/state/use-dashboard";
import { formatNumber } from "@features/utils";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

export const CHAT_THREAD_STATE_NAMES = {
  open_threads_count: "Open",
  closed_threads_count: "Closed",
  reminder_threads_count: "Reminded",
  waiting_threads_count: "Waiting",
};

export const CHAT_THREAD_STATE_COLORS = {
  open_threads_count: "#fb923c",
  closed_threads_count: "#10b981",
  reminder_threads_count: "#6b7280",
  waiting_threads_count: "#a18682",
};

export const ChatChart = () => {
  const { chat } = useDashboard();

  const chatInfo = Object.keys(chat?.all_threads_by_status || {})
    .map((a) => ({
      label: a,
      value: (chat?.all_threads_by_status || ({} as any))[a] as number,
    }))
    .filter((a) => a.value > 0);

  const chatInfoAssignment = Object.keys(chat?.assigned_threads_by_status || {})
    .map((a) => ({
      label: a,
      value: (chat?.all_threads_by_status || ({} as any))[a] as number,
    }))
    .filter((a) => a.value > 0);

  return (
    <div className="w-80 h-40">
      <DoughnutChart
        options={{
          animations: false as any,
          plugins: {
            legend: {
              position: "right",
              labels: {
                usePointStyle: true,
              },
            },
          },
          maintainAspectRatio: false,
        }}
        data={{
          labels: chatInfo.map(
            (a) =>
              `${
                (CHAT_THREAD_STATE_NAMES as any)[a.label] || "Unknown"
              }: ${formatNumber(a.value)}`
          ),
          datasets: [
            ...(!chatInfo.length
              ? [
                  {
                    label: "No data",
                    backgroundColor: ["rgba(220,220,220,1.0)"],
                    data: [1],
                  },
                ]
              : []),
            {
              data: chatInfo.map((a) => a.value),
              backgroundColor: chatInfo.map(
                (a) => (CHAT_THREAD_STATE_COLORS as any)[a.label] || "#000000"
              ),
            },
            {
              label: "Assigned",
              data: chatInfoAssignment.map((a) => a.value),
              backgroundColor: chatInfoAssignment.map(
                (a) => (CHAT_THREAD_STATE_COLORS as any)[a.label] || "#000000"
              ),
            },
          ],
        }}
      />
    </div>
  );
};
