import { RestSearchRequest } from "@components/search-bar/utils/types";
import { atom, atomFamily } from "recoil";
import {
  SessionDecisionType,
  SessionListType,
  SessionStatusType,
  SessionType,
} from "../types";

export const CustomerReviewSessionsAtom = atomFamily<
  {
    per_state: { [key: string]: SessionListType[] };
    total: number;
    loading: boolean;
  },
  string
>({
  key: "CustomerReviewSessionsAtom",
  default: () => ({
    per_state: {},
    total: 0,
    loading: true,
  }),
});

export const SessionsStatesAtom = atom<SessionStatusType[]>({
  key: "SessionsStatesAtom",
  default: [],
});

export const SessionsListAtom = atom<{
  data: SessionListType[];
  total: number;
}>({
  key: "SessionsListAtom",
  default: { data: [], total: 0 },
});

export const SessionDetailsAtom = atomFamily<SessionType | null, string>({
  key: "SessionDetailsAtom",
  default: null,
});

export const SessionDecisionsAtom = atomFamily<SessionDecisionType[], string>({
  key: "SessionDecisionsAtom",
  default: [],
});

export const CustomerSessionsAtom = atomFamily<SessionListType[], string>({
  key: "CustomerSessionssAtom",
  default: [],
});

export const SessionsFiltersAtom = atom<RestSearchRequest>({
  key: "SessionsFiltersAtom",
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newValue) => {
        localStorage.setItem(
          "sessions_options",
          JSON.stringify(newValue?.options)
        );
      });
    },
  ],
  default: (() => {
    let saved: Partial<RestSearchRequest["options"]> = {};
    try {
      saved = JSON.parse(localStorage.getItem("sessions_options") || "{}");
    } catch (e) {
      console.error(e);
    }
    return {
      query: [],
      options: {
        order_by: saved.order_by || "date",
        order_way: saved.order_way || "DESC",
        limit: saved.limit || 10,
      },
    };
  })(),
});
