import { Info } from "@atoms/text";
import { useDashboard } from "@features/monitoring/state/use-dashboard";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { ChatChart } from "./charts/chat";

ChartJS.register(ArcElement, Tooltip, Legend);

export const Chat = () => {
  const { chat } = useDashboard();

  if (chat?.all_threads_by_status?.open_threads_count === undefined)
    return <div className="h-44" />;

  return (
    <div className="flex embed-flex-auto-layout flex-col sm:flex-row">
      <div className="sm:w-1/3 py-4" style={{ minWidth: "316px" }}>
        <ChatChart />
      </div>
      <div className="sm:w-1/3 sm:ml-8">
        <div className="mb-2">
          <Info>Close (last 7 days)</Info>
          <br />
          <div className="overflow-hidden mr-2 text-base">
            {chat.last_7_days_threads_by_status.closed_threads_count}
          </div>
        </div>
        <div className="mb-2">
          <Info>Open (last 7 days)</Info>
          <br />
          <div className="overflow-hidden mr-2 text-base">
            {chat.last_7_days_threads_by_status?.open_threads_count}
          </div>
        </div>
        <div className="mb-2">
          <Info>Customer action (last 7 days)</Info>
          <br />
          <div className="overflow-hidden mr-2 text-base">
            {chat.last_7_days_threads_by_status.waiting_threads_count} waiting,{" "}
            {chat.last_7_days_threads_by_status.reminder_threads_count}{" "}
            reminders
          </div>
        </div>
      </div>
    </div>
  );
};
