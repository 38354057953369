import { SectionSmall, Info, Base } from "@atoms/text";
import type { EmitDateClass } from "@features/vid/type";

type IdentityDocumentDetailsProps = {
  firstname?: string[];
  lastname?: string[];
  birth_date?: EmitDateClass | string;
  gender?: string;
  document_number?: string;
  nationality?: string;
  emit_country?: string;
  emit_date?: EmitDateClass | string;
  expiration_date?: EmitDateClass | string;
  mrz?: any[];
};

const IdentityDocumentDetails = ({
  firstname,
  lastname,
  birth_date,
  gender,
  document_number,
  nationality,
  emit_country,
  emit_date,
  expiration_date,
  mrz,
}: IdentityDocumentDetailsProps) => {
  const birthDate =
    typeof birth_date === "string" ? birth_date : getEmitDate(birth_date);
  const emitDate =
    typeof emit_date === "string" ? emit_date : getEmitDate(emit_date);
  const expirationDate =
    typeof expiration_date === "string"
      ? expiration_date
      : getEmitDate(expiration_date);

  return (
    <div className="flex flex-col space-y-2">
      <SectionSmall>Document Details</SectionSmall>
      <Info>
        These document details have been extracted automatically by our systems.
        They might not perfectly reflect the original document.
      </Info>
      <div className="flex flex-col space-y-2 py-2">
        <div className="flex flex-row space-x-2">
          <div className="w-1/3 flex flex-col">
            <Base>First Name</Base>
            <Info>{firstname?.join(" ") || "-"}</Info>
          </div>
          <div className="w-1/3 flex flex-col">
            <Base>Last Name</Base>
            <Info>{lastname?.join(" ") || "-"}</Info>
          </div>
        </div>
        <div className="flex flex-row space-x-2">
          <div className="w-1/3 flex flex-col">
            <Base>Birth Date</Base>
            <Info>{birthDate || "-"}</Info>
          </div>
          <div className="w-1/3 flex flex-col">
            <Base>Gender</Base>
            <Info>{gender ?? "-"}</Info>
          </div>
        </div>
        <div className="flex flex-row space-x-2">
          <div className="w-1/3 flex flex-col">
            <Base>Identity Number</Base>
            <Info>{document_number || "-"}</Info>
          </div>
          <div className="w-1/3 flex flex-col">
            <Base>Nationality</Base>
            <Info>{nationality || "-"}</Info>
          </div>
          <div className="grow flex flex-col">
            <Base>Issuance country</Base>
            <Info>{emit_country || "-"}</Info>
          </div>
        </div>
        <div className="flex flex-row space-x-2">
          <div className="w-1/3 flex flex-col">
            <Base>Issuance Date</Base>
            <Info>{emitDate || "-"}</Info>
          </div>
          <div className="w-1/3 flex flex-col">
            <Base>Expiry Date</Base>
            <Info>{expirationDate || "-"}</Info>
          </div>
        </div>
        <div className="flex flex-col">
          <Base>MRZ</Base>
          {mrz?.length === 0 ? (
            <Info>No MRZ found</Info>
          ) : (
            <Info className="font-mono">
              {(mrz || []).map((a) => (
                <div key={a}>{a}</div>
              ))}
            </Info>
          )}
        </div>
      </div>
    </div>
  );
};

const getEmitDate = (date?: EmitDateClass) => {
  if (!date) return;
  return `${date?.year}-${date?.month}-${date?.day}`;
};

export default IdentityDocumentDetails;
