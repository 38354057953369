import { RestSearchRequest } from "@components/search-bar/utils/types";
import { useScenarios } from "@features/scenarios/state/use-scenarios";
import _ from "lodash";
import { ViewType } from "./custom-view";

export const useColumns = (
  advancedQuery: RestSearchRequest,
  view: string,
  views: ViewType[],
) => {
  const { scenarios } = useScenarios();

  let columns =
    views.find((a) => a.id === view)?.columns ||
    (view === "kyt"
      ? [
          "kyt_data.all.in.sum_1m",
          "kyt_data.all.in.max_1m",
          "kyt_data.all.out.sum_1m",
          "kyt_data.all.out.max_1m",
          "kyt_data.all.in.sum_12m",
          "kyt_data.all.in.max_12m",
          "kyt_data.all.out.sum_12m",
          "kyt_data.all.out.max_12m",
        ]
      : view === "scan"
        ? ["scan"]
        : view === "vid"
          ? [
              ...(scenarios?.data
                ?.filter((a) => a.enabled && (a.result_label || a.code))
                .map((a) => "vid_" + (a.result_label || a.code)) || []),
            ]
          : view === "chat"
            ? ["open_chat_categories", "closed_chat_categories"]
            : view === "risk"
              ? ["risk_factors"]
              : [
                  "kyt_data.all.in.sum_1m",
                  "kyt_data.all.in.max_1m",
                  "kyt_data.all.in.sum_12m",
                  "kyt_data.all.out.sum_12m",
                ]);

  const queryCols = (advancedQuery?.query || []).map((a) => a.key);

  columns = [
    ...columns,
    ...queryCols.filter((a) => a.indexOf("kyt_data") === 0),
    ...queryCols.filter((a) => a.indexOf("custom_fields_label") === 0),
    ...queryCols.filter((a) =>
      [
        "date_of_birth",
        "first_names",
        "last_names",
        "nationality_code",
        "domicile_code",
        "customer_last_updated_at",
        "edd_last_updated_at",
        "trading_names",
        "company_names",
        "edd_comment",
      ].includes(a),
    ),
  ];

  if (queryCols.includes("review_groups")) {
    columns = ["review_groups", ...columns];
  }

  if (queryCols.includes("contacts")) {
    columns = ["contacts", ...columns];
  }

  if (queryCols.some((a) => a.indexOf("_chat_categories") !== -1)) {
    columns = [...columns, "open_chat_categories", "closed_chat_categories"];
  }

  if (queryCols.some((a) => a.indexOf(".vid_") !== -1)) {
    columns = [
      ...columns,
      ...(scenarios?.data
        ?.filter((a) => a.enabled && (a.result_label || a.code))
        ?.map((a) => "vid_" + (a.result_label || a.code)) || []),
    ];
  }

  if (queryCols.some((a) => a.indexOf("risk_factors") === 0)) {
    columns = [...columns, "risk_factors"];
  }

  if (
    ["is_pep", "is_san", "may_be_pep", "may_be_san"].some((a) =>
      queryCols?.includes("computed_fields_labels." + a),
    )
  ) {
    columns = [...columns, "scan"];
  }

  // Add all defaults columns
  columns = _.uniq([
    "primary_external_id",
    "full_names",
    ...columns.filter(
      (a) =>
        ![
          "primary_external_id",
          "full_names",
          "overall_risk",
          "edd_state",
          "assignees",
          "actions",
        ].includes(a),
    ),
    "kyc_level",
    "overall_risk",
    "edd_state",
    "assignees",
    "actions",
  ]);

  return { columns };
};
