import { LinkConfirm } from "@atoms/link/confirm";
import { useMatrixEntries } from "@features/custom-matrix/state/use-matrix-entries";
import { formatDuration, formatTime } from "@features/utils/dates";
import parsePhoneNumber from "libphonenumber-js";

import { Info, SectionSmall } from "@atoms/text";
import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";
import { CustomerAllDetailType } from "@features/customers/types";

import _ from "lodash";
import { Table } from "@components/table";

type RelatedProducts =
  | "header"
  | "identity"
  | "go!scan"
  | "go!vid"
  | "go!risk"
  | "go!chat"
  | "go!press"
  | "go!kyt"
  | "financial"
  | "none";

const getCustomFields = (
  customer: CustomerAllDetailType,
  displayByRelatedProduct: RelatedProducts
) => {
  const { fields } = useCustomFields();

  if (!customer) {
    return { customFields: [], groups: [], fields };
  }

  let customFields = customer?.details.custom_fields.filter((cf) =>
    fields
      .filter((f) => f.field_id === cf.id)
      .map((f) => f.format.related_products?.filter(Boolean))
      .flat()
      .includes(displayByRelatedProduct)
  );

  if (displayByRelatedProduct === "none") {
    customFields = customer?.details.custom_fields.filter(
      (cf) =>
        fields
          .filter((f) => f.field_id === cf.id)
          .filter(
            (f) =>
              f.format.related_products?.length === undefined ||
              f.format.related_products?.filter(Boolean)?.length === 0
          ).length === 1
    );
  }

  const groups = _.sortBy(
    _.uniq(
      customFields
        .map((cf) =>
          fields.filter((f) => f.field_id === cf.id).map((f) => f.group)
        )
        .flat()
    ),
    (group) => group || "zzzzz"
  );
  return { customFields, groups, fields };
};

export const hasCustomFields = (
  customer: CustomerAllDetailType,
  displayByRelatedProduct: RelatedProducts
) => {
  return (
    getCustomFields(customer, displayByRelatedProduct).customFields?.length > 0
  );
};

export const CustomFields = ({
  customer,
  displayByRelatedProduct,
  renderEmpty,
}: {
  customer: CustomerAllDetailType;
  displayByRelatedProduct: RelatedProducts;
  renderEmpty?: boolean;
}) => {
  const { customFields, groups, fields } = getCustomFields(
    customer,
    displayByRelatedProduct
  );
  return (
    <>
      {customFields.length === 0 && renderEmpty && <div>No data</div>}
      {customFields.length > 0 && (
        <div className="space-y-4 mb-2">
          {groups.map((group) => (
            <div key={group} className={"space-y-2"}>
              <SectionSmall className="text-base">
                {group || "Other fields"}
              </SectionSmall>
              <Table
                data={_.sortBy(
                  customFields.filter((cf) =>
                    fields
                      .filter((f) => f.group === group)
                      .map((f) => f.field_id)
                      .includes(cf.id)
                  ),
                  (cf) => fields.find((a) => a.field_id === cf.id)?.header_name
                )}
                showPagination={false}
                columns={[
                  {
                    render: (row) => (
                      <Info>
                        {fields.find((a) => a.field_id === row.id)?.header_name}
                      </Info>
                    ),
                    thClassName: "w-1/3",
                  },
                  {
                    render: (row) =>
                      formatFieldValue(
                        row.value,
                        fields.find((a) => a.field_id === row.id)?.format
                      ),
                  },
                ]}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export const formatFieldValue = (
  value: any,
  format: { type?: string; multiple?: boolean } = {}
) => {
  if (format.multiple) {
    return (
      <>
        {(typeof value === "string" ? value.split(/,|;/) : value || [])
          .map((a: string) => a.trim())
          .map((v: any, i: number) => (
            <span key={i}>
              {i > 0 ? ", " : ""}
              {formatFieldValue(v, { type: format?.type })}
            </span>
          ))}
      </>
    );
  }

  if (
    value === undefined ||
    value === null ||
    Number.isNaN(value) ||
    value === ""
  ) {
    return "-";
  }

  if (typeof value === "boolean") {
    return value ? "Yes" : "No";
  }

  if (format.type?.match(/currency_/)) {
    return Intl.NumberFormat(navigator.language, {
      style: "currency",
      currency: format.type.split("_")[1],
    }).format(value || 0);
  }

  if (format.type === "number") {
    return Intl.NumberFormat(navigator.language, {}).format(value || 0);
  }

  if (format.type?.match(/date_/)) {
    const isSeconds = format.type.split("_")[1] === "s";
    const time = (value || 0) * (isSeconds ? 1000 : 1);
    return formatTime(time);
  }

  if (format.type?.match(/elapsed_/)) {
    const isSeconds = format.type.split("_")[1] === "s";
    const time = (value || 0) * (isSeconds ? 1000 : 1);
    return formatDuration(time);
  }

  if (format.type === "iban") {
    return (value || "").replace(/(.{4})/g, "$1 ").toUpperCase();
  }

  if (format.type === "phone") {
    return parsePhoneNumber(value || "")?.formatInternational() || value;
  }

  if (format.type === "url") {
    return <LinkConfirm href={value}>{value}</LinkConfirm>;
  }

  if (format.type?.match(/matrix_/)) {
    return <MatrixValue value={value} matrixId={format.type.split("_")[1]} />;
  }

  return value;
};

export const useGetMatrixLabelColor = (matrixId: string) => {
  const { matrix } = useMatrixEntries(matrixId);
  return (value: any) => {
    if (!_.isArray(matrix)) {
      return { label: value, color: "" };
    }
    const label = matrix.find((a) => a.key === value)?.label || value;
    const color = matrix.find((a) => a.key === value)?.value || "U";
    return {
      label,
      color: (
        {
          C: "red-600",
          H: "orange-600",
          M: "yellow-600",
          L: "green-600",
        } as any
      )[color],
    };
  };
};

const MatrixValue = ({ value, matrixId }: { value: any; matrixId: string }) => {
  const { label, color } = useGetMatrixLabelColor(matrixId)(value);
  return <span className={"text-" + color}>{label}</span>;
};
