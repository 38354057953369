import type { QuestionAnswerAttachmentType } from "./faces/score";
import Env from "@config/environment";

export const getAnswersAttachementUrl = (
  type: string,
  answersAttachmentUrls: QuestionAnswerAttachmentType[] | string[]
): string[] => {
  const answersAttachmentUrlsOrBase64 = [] as string[];
  if (type === "base64") {
    answersAttachmentUrls?.map((elementBase64) => {
      elementBase64 !== "" &&
        answersAttachmentUrlsOrBase64.push(
          "data:image/png;base64, " + elementBase64
        );
    }) || [];
  } else {
    answersAttachmentUrls?.map((element) => {
      if (typeof element === "object")
        answersAttachmentUrlsOrBase64.push(
          `${Env.server}/api/v1/downloads/${element.id}`
        );
    });
  }
  return answersAttachmentUrlsOrBase64;
};
