import { useAuth } from "@features/auth/state/hooks";
import { useGlobalEffect } from "@features/utils";
import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { AgentsApiClient } from "../api-client/api-client";
import { ApiAgentsRolesCodes } from "../enum";
import { AgentsAtom } from "./store";
import { useAvailableRoles } from "./use-roles";
import { AgentRole } from "../types";
import { AuthJWT } from '@features/auth/jwt';

export const useAgents = () => {
  const { roles } = useAvailableRoles();
  const { clientId } = useAuth();
  const [members, setMembers] = useRecoilState(AgentsAtom);
  const [loading, setLoading] = useRecoilState(LoadingAtom("useAgents"));

  const refresh = useCallback(async () => {
    setLoading(true);
    setMembers(await AgentsApiClient.getAgents());
    setLoading(false);
  }, [setMembers, setLoading]);

  useGlobalEffect(
    "useAgents",
    () => {
      refresh();
    },
    []
  );


  const disable = useCallback(
    async (id: number) => {
      await AgentsApiClient.disableAgent(id);
      await refresh();
      toast.success("Agent disabled successfully");
    },
    [refresh]
  );

  const enable = useCallback(
    async (id: number) => {
      await AgentsApiClient.enableAgent(id);
      await refresh();
    },
    [refresh]
  );

  const resetPassword = useCallback(async (id: number) => {
    return await AgentsApiClient.resetPassword(id);
  }, []);

  const setRoles = useCallback(
    async (id: number, roles: AgentRole[]) => {
      await AgentsApiClient.setRoles(id, roles);
      await refresh();
      toast.success("Roles updated for agent");
    },
    [refresh]
  );

  const create = useCallback(
    async (email: string, name: string) => {
      const existingAgent = members.find(
        (a) =>
          a.email_login.toLocaleLowerCase().trim() ===
          email.toLocaleLowerCase().trim()
      );
      if (existingAgent) {
        const id = existingAgent?.clients.find(
          (c) => c.id === clientId
        )?.agent_id;
        if (id) {
          await enable(id);
          toast.success("Agent enabled");
          return { password: null, agent_id: id };
        }
      }
      let agent = await AgentsApiClient.createAgent(email, name);
      if (agent.password) {
        await refresh();
        toast.success("Agent created");
      } else {
        agent = await AgentsApiClient.inviteAgent(email);
        if (agent.agent_id) {
          await refresh();
          toast.success("Agent invited");
        } else {
          toast.error("Error while creating/inviting agent");
          throw new Error("Error while creating/inviting agent");
        }
      }
      return agent;
    },
    [refresh, members]
  );

  const update = useCallback(async () => {
    throw new Error("Not implemented");
  }, []);

  const developers = (members || []).filter((agent) =>
    agent?.clients
      ?.find((c) => c.id === clientId)
      ?.roles.some((role) => ApiAgentsRolesCodes.includes(role.code))
  );
  const nonDevelopers = (members || []).filter(
    (agent) =>
      agent?.clients?.find((c) => c.id === clientId)?.roles.length === 0 ||
      agent?.clients
        ?.find((c) => c.id === clientId)
        ?.roles.some((role) => !ApiAgentsRolesCodes.includes(role.code))
  );

  const membersAuthList =
    members.filter(
      (agent) =>
        agent.active &&
        agent.clients.filter(
          (client) => client.active && client.id === AuthJWT.clientId
        ).length > 0
    );


  return {
    roles,
    getAgentForClient: (withApiKeys = false) =>
      (withApiKeys ? members : nonDevelopers)
        .filter((a) => a?.clients?.find((c) => c.id === clientId)?.active)
        .map((a) => ({
          ...a,
          agent_id: a.clients.find((c) => c.id === clientId)?.agent_id,
        })),
    members: nonDevelopers.filter(
      (a) => a?.clients?.find((c) => c.id === clientId)?.active
    ),
    membersAuthList: membersAuthList,
    developers: developers.filter(
      (a) => a?.clients?.find((c) => c.id === clientId)?.active
    ),
    disabledAgents: nonDevelopers.filter(
      (a) => !a?.clients?.find((c) => c.id === clientId)?.active
    ),
    disabledDevelopers: developers.filter(
      (a) => !a?.clients?.find((c) => c.id === clientId)?.active
    ),
    refresh,
    resetPassword,
    setRoles,
    disable,
    enable,
    create,
    update,
    loading,
  };
};

export const useAgent = (id: number) => {
  const { members, developers, disable, setRoles, resetPassword } = useAgents();
  const { clientId } = useAuth();
  return {
    agent: [...members, ...developers].find(
      (a) => a.clients.find((c) => c.id === clientId)?.agent_id === id
    ),
    disable: () => disable(id),
    setRoles: (roles: AgentRole[]) => setRoles(id, roles),
    resetPassword: () => resetPassword(id),
  };
};
