import { DoughnutChart } from "@components/dognut-chart";
import { useDashboard } from "@features/monitoring/state/use-dashboard";
import { LABEL_TYPE_COLORS_HEX } from "@features/sessions/utils";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

export const VidChart = () => {
  const { onboarding } = useDashboard();

  const onboardingInfoAssignment = [
    ...(onboarding?.assigned_status_statistics?.items || []),
    {
      total: onboarding?.assigned_status_statistics?.total_without_status || 0,
      status: {
        label: "No status",
        type: "UNDEFINED",
      },
    },
  ].filter((a) => a.total > 0);

  const onboardingInfo = [
    ...(onboarding?.status_statistics?.items || []),
    {
      total: onboarding?.status_statistics?.total_without_status || 0,
      status: {
        label: "No status",
        type: "UNDEFINED",
      },
    },
  ].filter((a) => a.total > 0);

  return (
    <div className="w-80 h-40">
      <DoughnutChart
        options={{
          animations: false as any,
          plugins: {
            legend: {
              position: "right",
              labels: {
                usePointStyle: true,
              },
            },
          },
          maintainAspectRatio: false,
        }}
        data={{
          labels: onboardingInfo.map(
            (a) => `${a.status.label || "Unknown"}: ${a.total}`
          ),
          datasets: [
            ...(!onboardingInfo.length
              ? [
                  {
                    label: "No data",
                    backgroundColor: ["rgba(220,220,220,1.0)"],
                    data: [1],
                  },
                ]
              : []),
            {
              data: onboardingInfo.map((a) => a.total),
              backgroundColor: onboardingInfo.map(
                (a) =>
                  (LABEL_TYPE_COLORS_HEX as any)[a.status.type] || "#000000"
              ),
            },
            {
              label: "Assigned",
              data: onboardingInfoAssignment.map((a) => a.total),
              backgroundColor: onboardingInfoAssignment.map(
                (a) =>
                  (LABEL_TYPE_COLORS_HEX as any)[a.status.type] || "#000000"
              ),
            },
          ],
        }}
      />
    </div>
  );
};
