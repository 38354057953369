import { twMerge } from "tailwind-merge";

export const Alert = (props: {
  theme: "success" | "danger" | "warning" | "gray";
  title: string;
  icon: any;
  bullets?: string[];
  className?: string;
  children?: React.ReactNode;
}) => {
  let color = "bg-blue-500";
  let textColor = "text-white";
  if (props.theme === "success") color = "bg-green-500";
  if (props.theme === "danger") color = "bg-red-500";
  if (props.theme === "warning") color = "bg-orange-500";
  if (props.theme === "gray") {
    color = "bg-slate-200";
    textColor = "text-slate-900";
  }

  return (
    <div
      className={twMerge(
        `${textColor} ${color} p-4 flex items-center`,
        props.className
      )}
    >
      <div className="flex items-center h-full" style={{ minHeight: 48 }}>
        <div className="flex-shrink-0">
          <props.icon className={`h-6 w-6`} aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium`}>{props.title}</h3>
          {(props.bullets || []).length > 0 && (
            <div className={`mt-2 text-sm`}>
              <ul className="list-disc pl-5 space-y-1">
                {(props.bullets || []).map((bullet) => (
                  <li key={bullet}>{bullet}</li>
                ))}
              </ul>
            </div>
          )}
          {props.children}
        </div>
      </div>
    </div>
  );
};
