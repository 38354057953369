import { is2XX, useFetch } from "@features/utils";
import {
  InboxMessage,
  InboxMessageAttachment,
  InboxMessageCreateInput,
  InboxThread,
  InboxThreadCreateInput,
  InboxUploadAttachmentToCustomersDocumentInput,
  MessageDeliveryStatus,
  ThreadHistoryItemType,
} from "../types";
import { AssignmentType } from "@features/assignments/types";
import Env from "@config/environment";
import { AuthJWT } from "@features/auth/jwt";
import { RestSearchQuery } from "./type";
import { SchemaSearchField } from "@components/search-bar/utils/types";

export class InboxApiClient {
  static getThreadSearchSchema = async () => {
    const response = await useFetch(`/web/thread/schema`, {
      method: "GET",
    });
    const data = await response.json();
    return data.searchable_fields as SchemaSearchField[];
  };

  static getThreadsSearchAdapter = async (
    query: RestSearchQuery[] | null,
    options: Partial<{
      limit: number;
      offset: number;
      order_by: string;
      order_way: "ASC" | "DESC";
    }>
  ) => {
    const response = await useFetch(`/web/thread/search`, {
      method: "POST",
      body: JSON.stringify({
        query,
        options,
      }),
    });
    const data = await response.json();
    if (!data.data) {
      data.data = [];
    }
    return data as { data: InboxThread[]; total: number };
  };

  static getThread = async (threadId: string) => {
    const response = await useFetch(`/web/thread/${threadId}`, {
      method: "GET",
    });
    const data = await response.json();
    return data as InboxThread;
  };

  static createThread = async (threadInput: InboxThreadCreateInput) => {
    const response = await useFetch(`/web/thread`, {
      method: "POST",
      body: JSON.stringify({
        ...threadInput,
      }),
    });
    if (!is2XX(response.status)) {
      console.error("Failed to create thread", response);
      throw new Error("Failed to create thread");
    }
    const data = await response.json();
    return data as InboxThread;
  };

  static editThread = async (
    threadId: string,
    threadUpdateInput: InboxThreadCreateInput
  ) => {
    const response = await useFetch(`/web/thread/${threadId}`, {
      method: "POST",
      body: JSON.stringify({
        ...threadUpdateInput,
      }),
    });
    const data = await response.json();
    return data as InboxThread;
  };

  static getHistoryByThread = async (
    threadId: string,
    offset?: number,
    limit?: number
  ) => {
    // offset and limit are the same as on_page and per_page
    // add them to as query params to the url if they are not null
    let query = "";
    if (offset !== undefined && limit !== undefined) {
      query = `?offset=${offset}&limit=${limit}`;
    }

    const response = await useFetch(`/web/thread/${threadId}/history${query}`, {
      method: "GET",
    });
    const data = await response.json();
    return {
      data: (data.history_items ?? []) as ThreadHistoryItemType[],
      total: data.total,
    };
  };

  static getAssignmentHistory = async (threadId: string) => {
    const response = await useFetch(
      `/web/thread/${threadId}/assignment_history`,
      {
        method: "GET",
      }
    );
    const data = await response.json();
    return (data as AssignmentType[]) ?? [];
  };

  static getMessagesByThread = async (
    threadId: string,
    offset?: number,
    limit?: number
  ) => {
    // offset and limit are the same as on_page and per_page
    // add them to as query params to the url if they are not null
    let query = "";
    if (offset !== undefined && limit !== undefined) {
      query = `?offset=${offset}&limit=${limit}`;
    }

    const response = await useFetch(
      `/web/thread/${threadId}/messages${query}`,
      {
        method: "GET",
      }
    );
    const data = await response.json();
    return { data: (data.messages ?? []) as InboxMessage[], total: data.total };
  };

  static createMessage = async (
    threadId: string,
    message: Partial<InboxMessageCreateInput>
  ) => {
    const response = await useFetch(`/web/message`, {
      method: "POST",
      body: JSON.stringify({
        external_msg_id: "",
        reply_to_external_msg_id: "",
        delivery: 1,
        subject: "",
        from: "",
        to: "",
        cc: "",
        bcc: "",
        content: "",
        attachments: [],
        set_reminder: null,
        ...message,
        thread_id: threadId,
      }),
    });
    if (!is2XX(response.status)) {
      console.error("Failed to create message", response);
      throw new Error("Failed to create message");
    }
    return response;
  };

  static readMessage = async (id: string) => {
    await useFetch(`/web/message/${id}`, {
      method: "POST",
      body: JSON.stringify({
        read: true,
      }),
    });
  };

  static markAsReadyToBeSent = async (id: string) => {
    await useFetch(`/web/message/${id}`, {
      method: "POST",
      body: JSON.stringify({
        delivery: MessageDeliveryStatus.READY_TO_BE_SENT,
      }),
    });
  };

  static updateMessage = async (
    messageId: string,
    message: InboxMessageCreateInput
  ) => {
    const response = await useFetch(`/web/message/${messageId}`, {
      method: "POST",
      body: JSON.stringify({
        ...message,
      }),
    });
    if (!is2XX(response.status)) {
      console.error("Failed to create message", response);
      throw new Error("Failed to create message");
    }
    return response;
  };

  static uploadAttachmentToCustomersDocuments = async (
    attachmentToCustomersInput: InboxUploadAttachmentToCustomersDocumentInput
  ) => {
    const response = await useFetch(
      `web/chat/attachment/to_customer_document`,
      {
        method: "POST",
        body: JSON.stringify({ ...attachmentToCustomersInput }),
      }
    );
    if (!is2XX(response.status)) {
      console.error("Failed to transfer attachment", response);
      throw new Error("Failed to transfer attachment");
    }
    return response;
  };

  static getAttachmentDownloadRoute = (attachment: InboxMessageAttachment) => {
    return `${Env.server.replace(/\/^/, "")}/web/chat/attachment/${
      attachment.id
    }?clients=${AuthJWT.clientId}`;
  };
}
