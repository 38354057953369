export interface IdentityAnalysisType {
  algoreg_score: number;
  facematch_info: FacematchInfo;
  extracted_info: ExtractedInfo;
  facematch_score: number;
  faceA: string;
  faceB: string;
}

export interface FacematchInfo {
  identifier: string;
  titleMsg: string;
  resultMsg: string;
  result: Result;
  score: string;
  details: string;
  controls: Control[];
  sub_controls?: Control[];
}

export interface ExtractedInfo {
  id: number;
  other_images: OtherImage[];
  id_type: string;
  document_detail: DocumentDetail;
  holder_detail: HolderDetail;
  mrz: string[];
  controls: Control[];
}

export interface Control {
  identifier: string;
  titleMsg: string;
  resultMsg: string;
  result: Result;
  score: number;
  details: string;
  controls: Control[];
  sub_controls?: Control[];
  control?: Control[]; // Previous version compatibility
}

export enum Result {
  Success = "SUCCESS",
  Undefined = "UNDEFINED",
  Warning = "WARNING",
  Error = "ERROR",
}

export interface DocumentDetail {
  emit_country: string;
  emit_date: EmitDateClass;
  expiration_date: EmitDateClass;
  document_number: string;
  extra_infos: ExtraInfo[];
}

export interface EmitDateClass {
  day: number;
  month: number;
  year: number;
}

export interface ExtraInfo {
  data_key: string;
  data_value: string;
  title: string;
}

export interface HolderDetail {
  last_name: string[];
  first_name: string[];
  nationality: string;
  gender: string;
  birth_date: EmitDateClass;
  birth_place: string;
}

export interface OtherImage {
  Type: string;
  Data: string;
}

export interface IdentityAnalysisPayload {
  base_64_encoded_front_image?: string;
  base_64_encoded_back_image?: string;
  base_64_encoded_face_images?: string[];
}
