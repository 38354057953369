import { useFetch } from "@features/utils";
import { AlertCategoryDTOLabel, AlertListType, AlertType } from "../types";
import { RestSearchQuery, SchemaSearchField } from "@components/search-bar/utils/types";

export class AlertsApiClient {
  static getAlertsSchema = async () => {
    const response = await useFetch(`/web/alerts/schema`, {
      method: "GET",
    });
    const data = await response.json();
    return data.searchable_fields as SchemaSearchField[];
  };

  static getAlerts = async (
    query: RestSearchQuery[] | null,
    options: Partial<{
      limit: number;
      offset: number;
      order_by: string;
      order_way: "ASC" | "DESC";
    }>
  ) => {
    const response = await useFetch(`/web/alerts/search`, {
      method: "POST",
      body: JSON.stringify({
        query,
        options,
      }),
    });
    const data = await response.json();
    if (!data.data) {
      data.data = [];
    }
    return data as { data: AlertListType[]; total: number };
  };

  static updateAlertStatus = async (
    id: string,
    status: number,
    comment: string
  ) => {
    await useFetch(`/web/casemanagement/modify-alert`, {
      method: "POST",
      body: JSON.stringify({
        alert_id: id,
        comment: comment,
        alert_state: status,
      }),
      formData: true,
    });
  };

  static getAlert = async (id: string) => {
    const response = await useFetch(`/web/alerts/${id}`, {
      method: "GET",
    });
    const data = await response.json();
    return data as AlertType;
  };

  static getAlertsCategories = async () => {
    const response = await useFetch(`/web/alert/categories`, {
      method: "GET",
    });
    const data = await response.json();
    return data as { [key: string]: string };
  };

  static getAlertsState = async () => {
    const response = await useFetch(`/web/alert/states`, {
      method: "GET",
    });
    const data = await response.json();
    return data as AlertCategoryDTOLabel;
  };
}
