import { Tag } from "@atoms/badge/tag";
import { OptionsType as DropDownMenuType, Menu } from "@atoms/dropdown";
import { Checkbox } from "@atoms/input/input-checkbox";
import Select from "@atoms/input/input-select";
import { Input } from "@atoms/input/input-text";
import { Loader } from "@atoms/loader";
import { Info, InfoSmall } from "@atoms/text";
import { formatTime } from "@features/utils/dates";
import {
  InformationCircleIcon,
  MagnifyingGlassIcon as SearchIcon,
} from "@heroicons/react/24/outline";
import { ReactNode, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { FieldInSearchBar } from "./components/field";
import { CaretPositionType } from "./hooks/use-caret";
import { Suggestions } from "./hooks/use-suggestions";
import { SearchField } from "./utils/types";
import { convertStrToDate, labelToVariable } from "./utils/utils";

type SearchBarSuggestionsProps = {
  suggestions: Suggestions;
  selected: number;
  afterOnClick: () => void;
  caret: CaretPositionType;
  replaceAtCursor: (value: string) => void;
  searching: boolean;
  schema: { table: string; fields: SearchField[] };
  loadingSuggestionsValues: boolean;
};

export const SearchBarSuggestions = ({
  suggestions,
  selected,
  afterOnClick,
  caret,
  replaceAtCursor,
  searching,
  schema,
  loadingSuggestionsValues,
}: SearchBarSuggestionsProps) => {
  const currentField = schema.fields.find(
    (a) => labelToVariable(a.label) === caret.filter?.key
  );

  const operators = suggestions.filter((a) => a.type === "operator");
  const fields = suggestions.filter((a) => a.type === "field");
  const values = suggestions.filter((a) => a.type === "value");
  const [operatorInput, setOperatorInput] = useState("");
  const [value1Input, setValue1Input] = useState<null | string>(null);
  const [value2Input, setValue2Input] = useState<null | string>(null);
  searching =
    searching &&
    !["boolean", "date", "number"].includes(currentField?.type || "");

  // Detect changes from search input
  useEffect(() => {
    const raw = caret.value?.raw || "";
    const operator =
      raw.indexOf(">=") >= 0
        ? ">="
        : raw.indexOf("<=") >= 0
        ? "<="
        : raw.indexOf("->") >= 0
        ? "->"
        : "";
    const values = raw.split("->").map((a) => a.replace(/[^0-9-]/gm, ""));
    if (
      operator !== operatorInput ||
      values[0] !== value1Input ||
      values[1] !== value2Input
    ) {
      setOperatorInput(operator);
      setValue1Input(values[0]);
      setValue2Input(values[1]);
    }
  }, [caret.value?.raw]);

  // Detect changes from suggestion modal
  useEffect(() => {
    if (!operatorInput && !value1Input && !value2Input) return;
    const raw =
      operatorInput === "->"
        ? (value1Input || "") + operatorInput + (value2Input || "")
        : operatorInput + (value1Input || "");

    if (
      caret.filter &&
      caret.filter?.key &&
      (currentField?.type === "date" || currentField?.type === "number") &&
      caret.value
    ) {
      replaceAtCursor(
        caret.filter.key +
          ":" +
          [...caret.filter.values_raw_array, ""]
            .map((v, i) => (i === (caret.value?.index || 0) ? raw : v))
            .filter((a) => a)
            .join(",")
      );
    }
  }, [operatorInput, value1Input, value2Input]);

  return (
    <Menu
      menu={[
        ...(currentField
          ? ([
              {
                type: "label",
                label: (
                  <>
                    <Info className="block my-2 flex items-center">
                      {searching && !!currentField && (
                        <>
                          <SearchIcon className="w-4 h-4 inline-block mr-1" />
                          <span>
                            Search in values of <b>{currentField?.label}</b>
                            ...
                          </span>
                        </>
                      )}
                      {!searching && !!currentField && (
                        <>
                          <InformationCircleIcon className="w-4 h-4 inline-block mr-1" />
                          <span>
                            <b>{currentField?.label}</b>{" "}
                            {!caret.filter?.not ? "matches" : "does not match"}{" "}
                            {caret.filter?.values
                              .map((a) => `"${a}"`)
                              .join(" ou ")}
                            .
                          </span>
                        </>
                      )}
                    </Info>
                    {(currentField.type === "date" ||
                      currentField.type === "number") &&
                      (caret.value ||
                        caret.text.all
                          .slice(0, caret.caret.current)
                          .match(/:$/)) && (
                        <div className="mb-2 flex space-x-2 items-center max-w-md">
                          <Select
                            size="sm"
                            className="shrink-0 w-max"
                            value={operatorInput}
                            onChange={(e) => setOperatorInput(e.target.value)}
                          >
                            <option value="">Equals</option>
                            <option value=">=">Larger than</option>
                            <option value="<=">Lower than</option>
                            <option value="->">Between</option>
                          </Select>
                          {currentField.type === "date" && (
                            <>
                              <Input
                                placeholder="YYYY-MM-DD"
                                size="sm"
                                className="shrink-0 w-32"
                                value={value1Input || ""}
                                onChange={(e) =>
                                  setValue1Input(
                                    convertStrToDate(e.target.value)
                                  )
                                }
                              />
                              {operatorInput === "->" && (
                                <>
                                  <span>and</span>
                                  <Input
                                    placeholder="YYYY-MM-DD"
                                    size="sm"
                                    className="shrink-0 w-32"
                                    value={value2Input || ""}
                                    onChange={(e) =>
                                      setValue2Input(
                                        convertStrToDate(e.target.value)
                                      )
                                    }
                                  />
                                </>
                              )}
                            </>
                          )}
                          {currentField.type === "number" && (
                            <>
                              <Input
                                type="number"
                                placeholder="0"
                                pattern="\d*"
                                size="sm"
                                className="shrink-0 w-32"
                                value={value1Input || ""}
                                onChange={(e) => setValue1Input(e.target.value)}
                              />
                              {operatorInput === "->" && (
                                <>
                                  <span>and</span>
                                  <Input
                                    type="number"
                                    placeholder="0"
                                    pattern="\d*"
                                    size="sm"
                                    className="shrink-0 w-32"
                                    value={value2Input || ""}
                                    onChange={(e) =>
                                      setValue2Input(e.target.value)
                                    }
                                  />
                                </>
                              )}
                            </>
                          )}
                        </div>
                      )}
                  </>
                ),
              },
            ] as DropDownMenuType[])
          : []),
        // Add suggested values, or date picker for date fields
        ...(values.length || (searching && !!currentField)
          ? ([
              {
                type: "label",
                label: (
                  <Info className={twMerge("block", !values.length && "mb-2")}>
                    {loadingSuggestionsValues && (
                      <>
                        <Loader className={"w-4 h-4 mr-2"} />
                        <Info>Recherche...</Info>
                      </>
                    )}
                    {!loadingSuggestionsValues && !!values.length && "Valeurs"}
                    {!loadingSuggestionsValues &&
                      !values.length &&
                      "Aucune valeur ne correspond à votre recherche."}
                  </Info>
                ),
              },
              ...values.map((a: Suggestions[0], i) => ({
                type: "menu",
                className: "group/item",
                label: (
                  <div className="flex flex-row space-x items-center overflow-hidden text-ellipsis whitespace-nowrap">
                    <Checkbox className="mr-2" value={a.active} />
                    {a.field?.type.indexOf("type:") !== 0 && (
                      <span>{(a.render || a.value) as string | ReactNode}</span>
                    )}
                    {a.field?.type.indexOf("type:") === 0 && (
                      <Tag
                        noColor
                        className={twMerge("bg-white dark:bg-slate-900 pr-1")}
                      >
                        {a.render}
                      </Tag>
                    )}
                    <InfoSmall
                      className={twMerge(
                        "ml-1 group-hover/item:opacity-100 opacity-0",
                        i === selected && "opacity-100"
                      )}
                    >
                      {!!a.count && (
                        <>
                          {" • "}
                          {a.count > 10 ? "~" : ""}
                          {a.count} documents
                        </>
                      )}
                      {!!a.updated && (
                        <>
                          {" • "}dernière utilisation
                          {" " + formatTime(a.updated, "", { keepTime: false })}
                        </>
                      )}
                    </InfoSmall>
                  </div>
                ),
                shortcut: i === selected ? ["enter"] : [],
                active: i === selected,
                onClick: () => {
                  a.onClick?.();
                  afterOnClick();
                },
              })),
            ] as DropDownMenuType[])
          : []),

        // Auto-suggest fields
        ...(fields.length
          ? ([
              {
                type: "label",
                label: <Info>Filtres</Info>,
                onClick: () => {
                  return null;
                },
              },
              ...fields.map(({ field, onClick }, i) => ({
                type: "menu",
                label: FieldInSearchBar({ field } as any),
                shortcut: i + values.length === selected ? ["enter"] : [],
                active: i + values.length === selected,
                onClick: () => {
                  onClick?.();
                  afterOnClick();
                },
              })),
            ] as DropDownMenuType[])
          : []),
        // When field is chosen, show the operations (invert, toggle fuzzy, add 'or' value, etc.)
        ...(operators.length
          ? ([
              {
                type: "label",
                label: <Info>Opérations</Info>,
                onClick: () => {
                  return null;
                },
              },
              ...operators.map((a, i) => ({
                type: "menu",
                label: (
                  <span>
                    {((a as any).render || a.value) as string | ReactNode}
                  </span>
                ),
                shortcut:
                  i + fields.length + values.length === selected
                    ? ["enter"]
                    : [],
                active: i + fields.length + values.length === selected,
                onClick: () => {
                  a.onClick?.();
                  afterOnClick();
                },
              })),
            ] as DropDownMenuType[])
          : []),
      ]}
    />
  );
};
