import { Button } from "@atoms/button/button";
import { Info, Title } from "@atoms/text";
import DragDropImage, { type ImageInfo } from "@components/drag-drop/images";
import { PageLoader } from "@components/page-loader";
import { useIdentityAnalysis } from "@features/vid/state/use-identity-analysis";
import { useState, type MouseEventHandler } from "react";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";
import { Page } from "@atoms/layout/page";
import { PageBlock } from "@atoms/layout/page-block";
import type { IdentityAnalysisPayload } from "@features/vid/type";
import _ from "lodash";
import IdentityAnalysisDetails from "./details";

export const IdentityCheckPage = () => {
  const [analysis, setAnalysis] = useState<IdentityAnalysisPayload>({});
  const { fetchIdentityAnalysis } = useIdentityAnalysis();

  const {
    mutate: fetch,
    isLoading: loading,
    data: identityAnalysis,
  } = fetchIdentityAnalysis(analysis);

  const handleButtonClick: MouseEventHandler<HTMLButtonElement> = async (
    event
  ) => {
    try {
      event.preventDefault();
      await fetch();
    } catch (e: any) {
      toast.error(
        "An error has occurred while executing the request, please try again later."
      );
    }
  };

  return (
    <Page>
      <Title>Identity check</Title>
      <Info>
        Perform an identity check by uploading images of the identity card,
        driver license or passport.
      </Info>
      <PageBlock className="bg-white my-8 px-4 w-full">
        <div className="flex justify-between flex-wrap">
          <IdentityCheckPictureUpload
            title="Front ID document"
            onChange={(value) => {
              const element = typeof value === "string" ? value : "";
              setAnalysis((prev) => {
                if (!element) {
                  const { ...rest } = prev;
                  return rest;
                } else {
                  return {
                    ...prev,
                    base_64_encoded_front_image: element,
                  };
                }
              });
            }}
          />
          <IdentityCheckPictureUpload
            title="Back ID document"
            onChange={(value) => {
              const element = typeof value === "string" ? value : "";
              setAnalysis((prev) => {
                if (!element) {
                  const { ...rest } = prev;
                  return rest;
                } else {
                  return {
                    ...prev,
                    base_64_encoded_back_image: element,
                  };
                }
              });
            }}
          />
          <IdentityCheckPictureUpload
            title="Face"
            multiple={true}
            onChange={(values) => {
              const element = Array.isArray(values) ? values : [];
              setAnalysis((prev) => {
                if (!element) {
                  const { ...rest } = prev;
                  return rest;
                } else {
                  return {
                    ...prev,
                    base_64_encoded_face_images: element,
                  };
                }
              });
            }}
          />
        </div>
        <div className="flex justify-center pt-4 pb-4">
          <Button onClick={handleButtonClick} disabled={_.isEmpty(analysis)}>
            Analyse documents
          </Button>
        </div>
      </PageBlock>
      {loading && (
        <div className="absolute h-full bg-gray-100 opacity-72 top-0 left-64 right-0  z-50">
          <PageLoader />
        </div>
      )}
      {identityAnalysis &&
      identityAnalysis?.extracted_info?.controls?.length > 0 ? (
        <IdentityAnalysisDetails
          analysis={analysis}
          identityAnalysis={identityAnalysis}
        />
      ) : (
        <></>
      )}
    </Page>
  );
};

const IdentityCheckPictureUpload = ({
  title,
  onChange,
  className,
  multiple,
}: {
  title: string;
  onChange: (value: string | string[]) => void;
  className?: string;
  multiple?: boolean;
}) => {
  const handleImageUploaded = (imageInfos: ImageInfo[]) => {
    if (imageInfos.length > 0) {
      toast.success("Uploaded file");
      const imagesList = imageInfos.map((element) => element.base64);
      if (multiple) {
        onChange(imagesList);
      } else if (imagesList.length > 0) {
        onChange(imagesList[0]);
      }
    } else {
      onChange([]);
    }
  };

  return (
    <div className={twMerge("flex flex-col items-center", className)}>
      <DragDropImage
        title={title}
        onImageUploaded={handleImageUploaded}
        multiple={multiple}
      />
    </div>
  );
};

export default IdentityCheckPage;
