import { useDashboardTimeseries } from "@features/monitoring/state/use-dashboard";
import { useSessionsLabels } from "@features/sessions-labels/state/use-sessions-labels";
import { formatTime } from "@features/utils/dates";
import _ from "lodash";
import { Line } from "react-chartjs-2";
import { commonProductsOptions, getMetric } from "./utils";

const orangePalette = (seed: string) => {
  return `hsla(${
    (seed.split("").reduce((a, b) => a + b.charCodeAt(0), 0) % 30) + 30
  }, 100%, 50%, 100%)`;
};

const greenPalette = (seed: string) => {
  return `hsla(${
    (seed.split("").reduce((a, b) => a + b.charCodeAt(0), 0) % 45) + 100
  }, 80%, 50%, 100%)`;
};

const redPalette = (seed: string) => {
  return `hsla(${
    (seed.split("").reduce((a, b) => a + b.charCodeAt(0), 0) % 50) + 320
  }, 100%, 50%, 100%)`;
};

export const PastVid = () => {
  const { data } = useDashboardTimeseries();
  const { labels: sessionsLabel } = useSessionsLabels();

  const labels = data.map((a) =>
    formatTime(a.date, "", {
      keepDate: false,
      keepSeconds: false,
      keepTime: false,
    })
  );

  const sessionsLabelSorted = [...sessionsLabel];
  sessionsLabelSorted.sort(
    (a, b) =>
      // Neutral first, then passed, then failed
      a.type.localeCompare(b.type) || a.label.localeCompare(b.label)
  );

  const categories = sessionsLabelSorted.map((a) =>
    a.label.toLocaleLowerCase().replace(/[^a-z0-9]/gm, "_")
  );

  const colors = sessionsLabelSorted.map((a) =>
    a.type === "NEUTRAL"
      ? orangePalette(a.label)
      : a.type === "POSITIVE"
      ? greenPalette(a.label)
      : redPalette(a.label)
  );

  return (
    <div className="mt-2 flex sm:flex-row flex-col justify-between w-full h-72 space-y-4 sm:space-y-0 sm:space-x-4">
      <Line
        data={{
          labels,
          datasets: [
            ...categories.map((c, i) => ({
              label: _.upperFirst(c.replace(/_/g, " ")),
              data: data.map((a) =>
                getMetric(
                  a,
                  ("monitoringmetric_govid_sessionsbystatus_" + c) as any
                )
              ),
              borderColor: colors[i],
              backgroundColor: colors[i].replace("100%)", "70%)"),
              fill: true,
            })),
          ],
        }}
        options={commonProductsOptions as any}
      />
    </div>
  );
};
