import { PageBlockHr } from "@atoms/layout/page-block";
import Env from "@config/environment";
import { SessionAlertType } from "@features/sessions/types";
import { flatMapControls } from "@features/sessions/utils";
import { AlertTag } from "./alert-tag";
import { SessionBlock } from "./session-block";
import { Controls } from "@components/vid/controls";
import FaceScore from "@components/vid/faces/score";

export default function FaceMatchReview(props: {
  alert: SessionAlertType;
  anonymized: boolean;
}) {
  return (
    <SessionBlock
      id="face-question"
      title="Face Match"
      subtitle="Face analysis and comparison"
      suffix={<AlertTag state={props.alert.state} />}
      tries={props.alert.questions?.length}
      render={(tryIndex) => {
        const similarityScore =
          props.alert.controls?.[tryIndex as number]?.find(
            (a) => a.identifier === "face.matched.similarity"
          )?.score || (props.alert.score || 0) / 100;

        return (
          <>
            {props.alert.score !== undefined && (
              <>
                {props.alert.questions?.[tryIndex as number].answers
                  ?.videos?.[0] &&
                  !props.anonymized && (
                    <>
                      <div className="flex flex-wrap gap-4 mt-4 justify-center">
                        <video
                          className="bg-black h-[32rem] w-auto object-cover rounded-md border border-slate-300"
                          src={`${Env.server}${
                            props.alert.questions?.[tryIndex as number].answers
                              ?.videos[0].url
                          }`}
                          controls
                          preload="auto"
                        />
                      </div>
                    </>
                  )}
                <PageBlockHr />
                <Controls
                  title="Face Controls"
                  annotable
                  controls={flatMapControls(
                    props.alert.controls?.[tryIndex as number] || []
                  )}
                />
                {!props.anonymized && (
                  <>
                    <PageBlockHr />
                    <FaceScore
                      similarityScore={similarityScore}
                      identity_faces={
                        props.alert.identity_faces?.[tryIndex as number]
                      }
                      answers_attachment_urls={
                        props.alert.questions?.[tryIndex as number]?.answers
                          ?.attachment_urls
                      }
                    />
                  </>
                )}
              </>
            )}
          </>
        );
      }}
    />
  );
}
