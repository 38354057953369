import Annotation from "@components/annotations";
import { Result } from "@features/vid/type";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  MinusCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { twMerge } from "tailwind-merge";

type ControlProps = {
  title: string;
  description?: string;
  details?: string;
  identifier: string;
  result: Result;
  className?: string;
  depth?: number;
  depthMap?: boolean[];
  annotable?: boolean;
};

export function Control({
  className,
  title,
  description,
  details,
  identifier,
  result,
  depth = 1,
  depthMap = [],
  annotable,
}: ControlProps) {
  const tick = "h-5 w-5 absolute bg-white m-auto left-0 right-0 top-0 bottom-0";
  return (
    <div className={twMerge("relative grow", className)}>
      <div className="flex flex-row">
        {Array.from(Array(depth).keys()).map((_a, i) => (
          <div
            className="shrink-0 flex flex-col items-center w-4 relative"
            key={i}
          >
            {i === depth - 1 && (
              <>
                <div className="w-4 h-4 shrink-0 relative">
                  {result === Result.Success && (
                    <CheckCircleIcon
                      className={twMerge(tick, "text-green-500")}
                    />
                  )}
                  {result === Result.Warning && (
                    <ExclamationCircleIcon
                      className={twMerge(tick, "text-orange-500")}
                    />
                  )}
                  {result === Result.Error && (
                    <XCircleIcon className={twMerge(tick, "text-red-500")} />
                  )}
                  {result === Result.Undefined && (
                    <MinusCircleIcon
                      className={twMerge(tick, "text-slate-500")}
                    />
                  )}
                </div>
                {i > 0 && (
                  <>
                    <div className="absolute w-2 h-px bg-gray-200 -left-2 top-2 -translate-y-px" />
                  </>
                )}
              </>
            )}
            {i === depth - 2 && <div className="w-px bg-gray-200 h-2" />}
            {depthMap[i] && <div className="w-px bg-gray-200 grow" />}
          </div>
        ))}
        <div className="grow ml-2">
          <div className="mb-4 -mt-1">
            {annotable ? (
              <Annotation
                enabled={annotable}
                identifier={`annotated-${identifier}`}
              >
                <span data-tooltip={description + " - " + details}>
                  {title}
                </span>
              </Annotation>
            ) : (
              title
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
