import { useAuth } from "@features/auth/state/hooks";
import { useHasAccess } from "@features/auth/state/use-access";
import { queryClient } from "@features/query";
import { websocketBus } from "@features/websockets";
import _ from "lodash";
import { useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { CustomersApiClient } from "../api-client/api-client";
import {
  ChildParentCustomerRelation,
  CustomerAllDetailType,
  CustomerEddHistoryType,
  CustomerRiskFactorsType,
  UpdateCustomerSocketEventData,
} from "../types";
import { CustomerRelationsAtom } from "./store";

export const useCustomer = (id: string) => {
  const [relations, setRelations] = useRecoilState(CustomerRelationsAtom(id));
  const hasAccess = useHasAccess();

  const customer = useQuery<CustomerAllDetailType | null>({
    queryKey: ["customer", id],
    queryFn: async () => {
      if (!id) {
        return null;
      }

      const details = await CustomersApiClient.getCustomerDetails(id);
      const riskFactors: CustomerRiskFactorsType = hasAccess("RISK_SETTINGS")
        ? await CustomersApiClient.getCustomerRiskFactors(id)
        : { risk_factors: [], total_number_of_risk_factors: 0 };
      const eddHistory: CustomerEddHistoryType = hasAccess("CUSTOMER_READ")
        ? await CustomersApiClient.getCustomerEddHistory(id)
        : { edd_revisions: [], totalnumberofrevisions: 0 };
      return {
        details: details,
        risk_factors: riskFactors,
        edd_history: eddHistory,
      };
    },
    onError: (error) => {
      toast.error("Error loading customer details");
      console.log(error);
    },
    cacheTime: 2000,
  });

  const update = useMutation({
    mutationFn: async (params: {
      state: number;
      comment: string;
      customNextReviewDate?: Date;
    }) => {
      if (id && params.comment) {
        const tmp = await CustomersApiClient.updateCustomerStatus(
          id,
          params.state,
          params.comment,
          params.customNextReviewDate
        );
        queryClient.invalidateQueries({ queryKey: ["customer", id] });
        return tmp;
      }
    },
    onError: (error) => {
      toast.error("Error on updating customer");
      console.log(error);
    },
  });

  const disable = useMutation({
    mutationFn: async (disable: boolean) => {
      if (customer?.data?.details?.customer?.external_id) {
        await CustomersApiClient.updateCustomer({
          verify_input: false,
          disable_risk_scan: true,
          customers: [
            {
              external_id: customer?.data?.details.customer.external_id,
              disabled: disable,
            } as any,
          ],
          relations: [],
        });
        queryClient.invalidateQueries({ queryKey: ["customer", id] });
      }
    },
    onError: (error) => {
      toast.error("Error on disabling customer");
      console.log(error);
    },
  });

  const refreshRelations = useCallback(
    async (
      focusCustomer?: Partial<CustomerAllDetailType["details"]["customer"]>
    ) => {
      try {
        const focusCustomerRisk = customer?.data?.details.overall_risk;
        focusCustomer = focusCustomer || customer?.data?.details.customer;
        const focusId = focusCustomer!.id;
        const related = await CustomersApiClient.getCustomersRelated(focusId!);
        const newRelations = [
          ...(related.child_relations || []).map((r) => ({
            ...r,
            child: {
              customer: focusCustomer as any,
              risk: focusCustomerRisk,
            },
            parent: { customer: r.customer, risk: r.customer_risk },
          })),
          ...(related.parent_relations || []).map((r) => ({
            ...r,
            child: { customer: r.customer, risk: r.customer_risk },
            parent: {
              customer: focusCustomer as any,
              risk: focusCustomerRisk,
            },
          })),
        ] as ChildParentCustomerRelation[];
        const allRelations = _.uniqBy(
          focusId ? [...relations.relations, ...newRelations] : newRelations,
          "relation_external_id"
        );

        setRelations({
          relations: allRelations,
          known: [...relations.known, focusId!],
        });
      } catch (e) {
        toast.error("Error loading related customers");
        console.log(e);
      }
    },
    [
      relations,
      setRelations,
      customer?.data?.details.customer,
      customer?.data?.details.overall_risk,
    ]
  );

  return {
    customer: customer.data,
    loading: customer.isLoading || update.isLoading || disable.isLoading,
    refresh: customer.refetch,
    update: update.mutateAsync,
    disable: disable.mutateAsync,
    relations,
    refreshRelations,
  };
};

export const useCustomerRealtime = (id: string) => {
  const { agent, clientId } = useAuth();

  useEffect(() => {
    if (clientId && id) {
      const room = `client/${clientId}/customer/${id}`;
      websocketBus.emit("join", null, { room });
      websocketBus.on(
        room,
        (event: { data: UpdateCustomerSocketEventData }) => {
          if (event.data.customer_id === id) {
            queryClient.invalidateQueries({ queryKey: ["customer", id] });
            if (event.data.update_by_agent_id !== agent?.agent_id) {
              toast.success("Customer updated by another agent");
            }
          }
        }
      );
      return () => {
        websocketBus.emit("leave", null, { room });
        websocketBus.off(room);
      };
    }
  }, [clientId, id]);

  return {};
};
