import { Button } from "@atoms/button/button";
import { useTransactions } from "@features/kyt/state/use-transactions";
import { ROUTES } from "@features/routes";
import { useControlledEffect } from "@features/utils";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Bars4Icon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export const TransactionNavigation = ({ id }: { id: string }) => {
  const { transactions, setQuery, query } = useTransactions();

  const index = (transactions?.data || []).findIndex((a) => a.id === id) || 0;
  const previous = (transactions?.data || [])[index - 1];
  const next = (transactions?.data || [])[index + 1];

  useControlledEffect(() => {
    if (!next) {
      console.log("Fetching more transactions", previous?.id);
      const direction = !next ? 1 : -1;
      setQuery({
        ...query,
        options: {
          ...query.options,
          offset:
            (query.options?.offset || 0) +
            (query.options?.limit || 0) * direction,
        },
      });
    }
  }, [next?.id]);

  return (
    <div className="float-right">
      <div className="m-auto inline-flex items-center">
        <Link
          className="flex"
          to={
            (!!previous?.id &&
              ROUTES.TransactionView.replace(":id", previous?.id)) ||
            ""
          }
        >
          <Button
            size="sm"
            disabled={!previous?.id}
            theme="outlined"
            className="rounded-r-none"
          >
            <ArrowLeftIcon className="h-5 w-5" />
            <span className="lg:ml-2 lg:inline hidden">
              {" "}
              Previous transaction
            </span>
          </Button>
        </Link>

        <Link to={ROUTES.LiveTransactions} className="flex">
          <Button size="sm" theme="outlined" className="rounded-none -ml-px">
            <Bars4Icon className="h-5 w-5" />
            <span className="lg:ml-2 lg:inline hidden">
              {" "}
              List of transactions
            </span>
          </Button>
        </Link>

        <Link
          to={
            (!!next?.id && ROUTES.TransactionView.replace(":id", next?.id)) ||
            ""
          }
          className="flex"
        >
          <Button
            size="sm"
            disabled={!next?.id}
            theme="outlined"
            className="rounded-l-none  -ml-px"
          >
            <span className="lg:inline lg:mr-2 hidden">Next transaction </span>
            <ArrowRightIcon className="h-5 w-5" />
          </Button>
        </Link>
      </div>
    </div>
  );
};
