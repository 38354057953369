import { RestSearchRequest } from "@components/search-bar/utils/types";
import { atom, atomFamily } from "recoil";
import { AlertListType, AlertType } from "../types";

export const CustomerReviewAlertsAtom = atomFamily<
  {
    per_state: { [key: string]: AlertListType[] };
    total: number;
    loading: boolean;
  },
  string
>({
  key: "CustomerReviewAlertsAtom",
  default: () => ({
    per_state: {},
    total: 0,
    loading: true,
  }),
});

export const AlertsCategoriesAtom = atom<{ [key: string]: string }>({
  key: "AlertsCategoriesAtom",
  default: {},
});

export const AlertsStatesAtom = atom<{ [key: number]: string }>({
  key: "AlertsStatesAtom",
  default: {},
});

export const AlertsListAtom = atom<{ data: AlertListType[]; total: number }>({
  key: "AlertsListAtom",
  default: { data: [], total: 0 },
});

export const AlertsDetailsAtom = atomFamily<AlertType | null, string>({
  key: "AlertsDetailsAtom",
  default: null,
});

export const AlertsFiltersAtom = atom<RestSearchRequest>({
  key: "AlertsFiltersAtom",
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newValue) => {
        localStorage.setItem(
          "alerts_options",
          JSON.stringify(newValue?.options)
        );
      });
    },
  ],
  default: (() => {
    let saved: Partial<RestSearchRequest["options"]> = {};
    try {
      saved = JSON.parse(localStorage.getItem("alerts_options") || "{}");
    } catch (e) {
      console.error(e);
    }
    return {
      query: [],
      options: {
        order_by: saved.order_by || "LAST-UPDATE",
        order_way: saved.order_way || "DESC",
        limit: saved.limit || 10,
      },
    };
  })(),
});
