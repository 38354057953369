import { Info } from "@atoms/text";
import { useDashboard } from "@features/monitoring/state/use-dashboard";
import { formatNumber } from "@features/utils";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { PressChart } from "./charts/press";
import { PressRiskLevelsChart } from "./charts/press-risk-levels";

ChartJS.register(ArcElement, Tooltip, Legend);

export const Press = () => {
  const { press } = useDashboard();

  if (press?.assigned_reports_by_edd_status?.no_status_count === undefined)
    return <div className="h-44" />;

  return (
    <div className="flex embed-flex-auto-layout flex-col sm:flex-row">
      <div className="sm:w-1/3 py-4" style={{ minWidth: "316px" }}>
        <PressChart />
      </div>
      <div className="sm:w-1/3 sm:ml-8">
        <div className="mb-2">
          <Info>Total press reports</Info>
          <br />
          <div className="overflow-hidden mr-2 text-base">
            {formatNumber(
              Object.keys(press?.reports_by_active_risk_level || {}).reduce(
                (sum, a) =>
                  sum +
                  ((press?.reports_by_active_risk_level || ({} as any))[
                    a as keyof typeof press.reports_by_active_risk_level
                  ] as number),
                0
              ) || 0
            )}
          </div>
        </div>
        <div className="mb-2">
          <Info>Reports risk levels</Info>
          <br />
          <PressRiskLevelsChart />
        </div>
      </div>
    </div>
  );
};
