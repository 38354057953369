import { Title } from "@atoms/text";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Button } from "@atoms/button/button";
import { SearchBar } from "@components/search-bar";
import { OutputQuery, SchemaSearchField } from "@components/search-bar/utils/types";
import {
  RestSearchRequest
} from "@features/inbox/api-client/type";
import { isEqual } from "lodash";
import { useContext } from "react";
import { InboxModalContext } from "./modal";
import { useSetChatMessageModal } from "./components/modals/create-message-modal";
import { useReviewGroups } from "@features/agents/state/use-review-groups";

const SearchBarInbox = ({
  value,
  schema,
  onChange,
}: {
  value: RestSearchRequest;
  schema: SchemaSearchField[];
  onChange: (value: RestSearchRequest) => void;
}) => {
  const setCreateModal = useSetChatMessageModal();

  const { customerId: modalCustomerId } = useContext(InboxModalContext);
  const { reviewGroups } = useReviewGroups({ access: "CHAT" });

  return (
    <div className="flex flex-col items-center gap-y-2 px-4 pt-4">
      <div className="flex w-full flex-row justify-between">
        <Title className="hidden lg:mr-4 lg:block">Inbox</Title>
        <Button
          theme="primary"
          onClick={() =>
            setCreateModal({
              open: true,
              customerId: modalCustomerId,
            })
          }
        >
          <PlusIcon className="-ml-1 mr-2 inline h-4 w-4 shrink-0" />
          New
        </Button>
      </div>
      <div className="w-full">
        <SearchBar
          schema={{
            table: "message_threads",
            fields: [
              ...schema.filter(
                (field) => !field.possible_additional_keys?.length
              ),
              ...schema
                .filter((field) => field.possible_additional_keys?.length)
                .reduce((acc: SchemaSearchField[], field): SchemaSearchField[] => {
                  return [
                    ...acc,
                    ...(field.possible_additional_keys || []).map((key) => ({
                      value_type: key.value_type,
                      external_key: `${field.external_key}.${key.key}`,
                      display_name: `${field.external_key}: ${key.display_name}`,
                      allowed_ops: field.allowed_ops,
                      possible_values: field.possible_values,
                    })),
                  ];
                }, []),
            ].map((field) => {
              let type:
                | "number"
                | "boolean"
                | "text"
                | "date"
                | `type:${string}`;

              switch (field.value_type) {
                case "string":
                  type = "text";
                  break;
                case "number":
                  type = "number";
                  break;
                case "boolean":
                  type = "boolean";
                  break;
                case "date":
                  type = "date";
                  break;
                // Add more cases if needed
                default:
                  type = `type:${field.value_type}`;
              }
              return {
                key: field.external_key,
                label: field.display_name,
                keywords: (
                  field.display_name +
                  " " +
                  field.display_name.replace(/ /gm, "") +
                  " " +
                  field.external_key
                ).split(/( |_)/),
                allowed_ops: field.allowed_ops,
                possible_values: field.possible_values,
                type,
              };
            }),
          }}
          onSuggest={async (_table: string, column: string) => {
            if (column === "review_groups") {
              return reviewGroups.map((a) => ({ value: a, label: a }));
            }
            const field = schema.find((f) => f.external_key === column);
            if (!field?.possible_values) return [];
            return [
              ...field.possible_values.map((v) => ({
                value: v.value,
                label: v.label,
              })),
              ...((field.allowed_ops || []).includes("is_null")
                ? [{ value: null, label: "None" }]
                : []),
            ];
          }}
          onChange={(str: OutputQuery) => {
            if (str.valid && !isEqual(str.fields, value.query)) {
              onChange({
                query: [...str.fields],
                options: { ...value.options, offset: 0 },
              });
            }
          }}
        />
      </div>
    </div>
  );
};

export default SearchBarInbox;
