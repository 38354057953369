import { DoughnutChart } from "@components/dognut-chart";
import { useDashboard } from "@features/monitoring/state/use-dashboard";
import { formatNumber } from "@features/utils";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TRANSACTIONS_STATES = {
  allowed: "Allowed",
  blocked: "Blocked",
  pending: "Pending",
};

const TRANSACTIONS_STATES_COLORS = {
  allowed: "#10B981",
  blocked: "#EF4444",
  pending: "#FBBF24",
};

export const KYTChart = () => {
  const { kyt } = useDashboard();

  const KytInfoAssignment = Object.keys(
    kyt?.assigned_transactions_by_status || {}
  )
    .map((a) => ({
      id: a.split("_")[0],
      value: (kyt?.assigned_transactions_by_status as any)?.[a] || 0,
    }))
    .filter((a) => a.value > 0);

  const KytInfoEdd = Object.keys(kyt?.last_30_days_transactions_by_status || {})
    .map((a) => ({
      id: a.split("_")[0],
      value: (kyt?.last_30_days_transactions_by_status as any)?.[a] || 0,
    }))
    .filter((a) => a.value > 0);

  return (
    <>
      <div className="w-80 h-40">
        <DoughnutChart
          options={{
            animations: false as any,
            plugins: {
              legend: {
                position: "right",
                labels: {
                  usePointStyle: true,
                },
              },
            },
            maintainAspectRatio: false,
          }}
          data={{
            labels: KytInfoEdd.map(
              (a) =>
                `${
                  (TRANSACTIONS_STATES as any)[a.id] || "Unknown"
                }: ${formatNumber(a.value)}`
            ),
            datasets: [
              ...(!KytInfoEdd.length
                ? [
                    {
                      label: "No data",
                      backgroundColor: ["rgba(220,220,220,1.0)"],
                      data: [1],
                    },
                  ]
                : []),
              {
                data: KytInfoEdd.map((a) => a.value),
                backgroundColor: KytInfoEdd.map(
                  (a) => (TRANSACTIONS_STATES_COLORS as any)[a.id] || "#000000"
                ),
              },
              {
                label: "Assigned",
                data: KytInfoAssignment.map((a) => a.value),
                backgroundColor: KytInfoAssignment.map(
                  (a) => (TRANSACTIONS_STATES_COLORS as any)[a.id] || "#000000"
                ),
              },
            ],
          }}
        />
      </div>
    </>
  );
};
