import environment from "@config/environment";
import { isEqual } from "lodash";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { atomFamily } from "recoil";
import { AuthJWT } from "./auth/jwt";
import { isEmbed } from "./utils/use-embed-utils";

const globalEffectDepsMap = new Map<string, ReadonlyArray<any>>();

export const flushGlobalEffects = () => {
  globalEffectDepsMap.clear();
};

export const useGlobalEffect = (
  key: string,
  callback: (...args: any[]) => any,
  deps: ReadonlyArray<any>,
) => {
  //Hack to avoid linter telling us what to do
  (useEffect as any)(() => {
    if (isEqual(globalEffectDepsMap.get(key), deps) === false) {
      globalEffectDepsMap.set(key, deps);
      return (() => {
        callback();
      })();
    }
  }, deps as any[]);
};

export const useControlledEffect = useEffect as (
  callback: () => void | (() => void),
  dependencies: any[],
) => void;

const toFormData = (object: any) => {
  const formData = new FormData();
  for (const property in object) {
    if (!object.hasOwnProperty(property) || !object[property]) continue;
    formData.append(property, object[property]);
  }
  return formData;
};

export const useFetch = async (
  url: string,
  options: {
    formData?: boolean;
    allClients?: boolean;
  } & RequestInit,
): Promise<Response> => {
  if (options?.formData) {
    options.body = toFormData(JSON.parse((options.body || "{}") as string));
  }
  if (!url.startsWith("http"))
    url = `${environment.server.replace(/\/$/, "")}/${url.replace(/^\//, "")}`;
  options.headers = {
    ...(options?.formData ? {} : { "Content-Type": "application/json" }),
    ...(AuthJWT.token ? { Authorization: `Bearer ${AuthJWT.token}` } : {}),
    ...options.headers,
  };
  // ALG-250
  // Toute opération de doit se faire avec un unique client_id dans l'url
  // (ou aucun auquel cas le premier du JWT est utilisé) sinon on retourne une erreur.
  const currentId =
    !options?.allClients && AuthJWT.clientId && !url.includes("?clients=")
      ? `${url.includes("?") ? "&" : "?"}clients=${AuthJWT.clientId}`
      : "";
  const data = await fetch(url + currentId, options);
  if (data.status === 401 && !isEmbed()) {
    document.location.reload();
  }
  return data;
};

export const LoadingState = atomFamily({
  key: "LoadingState",
  //It is possible to set here what loaders should init as loading
  default: (type: string) => ["useAuth"].includes(type) || false,
});

const delayedRequests: Map<string, () => void | Promise<void>> = new Map();
const delayedRequestsHasTimout: Map<string, boolean> = new Map();

export const delayRequest = (
  key: string,
  request: () => void | Promise<void>,
  options: { timeout: number; doInitialCall: boolean } = {
    timeout: 1000,
    doInitialCall: true,
  },
) => {
  if (!delayedRequestsHasTimout.has(key)) {
    delayedRequestsHasTimout.set(key, true);
    if (options.doInitialCall) request();
    else delayedRequests.set(key, request);
    setTimeout(() => {
      const request = delayedRequests.get(key);
      delayedRequestsHasTimout.delete(key);
      request &&
        delayRequest(key, request, { ...options, doInitialCall: true });
      delayedRequests.delete(key);
    }, options.timeout);
  } else {
    delayedRequests.set(key, request);
  }
};

export const stringToColor = (
  str: string,
  saturation = 100,
  lightness = 75,
) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  return `hsl(${hash % 360}, ${saturation}%, ${lightness}%)`;
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
  toast.success("Copied to clipboard");
};

export const formatNumber = (number: number) => {
  return (number || 0).toLocaleString("fr-FR");
};

export const is2XX = (status: number) => {
  return status >= 200 && status < 300;
};

export const is4XX = (status: number) => {
  return status >= 400 && status < 500;
};

export const is5XX = (status: number) => {
  return status >= 500 && status < 600;
};
