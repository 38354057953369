import Env from "@config/environment";
import { throwBackendError } from "@features/errorresponse";
import { is2XX, useFetch } from "@features/utils";
import {
  CreateSessionInput,
  SessionDecisionType,
  SessionListType,
  SessionSettings,
  SessionsRequestOptions,
  SessionStatusType,
  SessionType,
} from "../types";
import { RestSearchQuery, SchemaSearchField } from "@components/search-bar/utils/types";

export class SessionsApiClient {
  static getSchema = async () => {
    const response = await useFetch(`/web/sessions/schema`, {
      method: "GET",
    });
    const data = await response.json();
    return data.searchable_fields as SchemaSearchField[];
  };

  static getSessionsSearch = async (
    query: RestSearchQuery[] | null,
    options: Partial<{
      limit: number;
      offset: number;
      order_by: string;
      order_way: "ASC" | "DESC";
    }>
  ) => {
    const response = await useFetch(`/web/sessions/search`, {
      method: "POST",
      body: JSON.stringify({
        query,
        options,
      }),
    });
    const data = await response.json();
    if (!data.data) {
      data.data = [];
    }
    return data as { data: SessionListType[]; total: number };
  };

  static getSessions = async (options: Partial<SessionsRequestOptions>) => {
    const response = await useFetch(`/web/sessions`, {
      method: "POST",
      body: JSON.stringify({
        external_id: null,
        search: null,
        language: null,
        date_from: null,
        date_to: null,
        status_id: null,
        include_unfinished: false,
        origin: ["vid", "pix"],
        score_min: null,
        score_max: null,
        scenario_ref: null,
        only_last: false,
        on_page: 1,
        per_page: 10,
        order_by: "date",
        order_way: "desc",
        ...options,
      }),
    });
    const data = await response.json();
    return data as { data: SessionListType[]; total: number };
  };

  static getSession = async (id: string) => {
    const response = await useFetch(`/web/session/${id}`, {
      method: "GET",
    });
    const data = await response.json();
    return data as SessionType;
  };

  static getSessionDecisions = async (id: string) => {
    const response = await useFetch(`/web/session/${id}/decision`, {
      method: "GET",
    });
    const data = await response.json();
    return data as {
      total_number_of_results: number;
      decisions: SessionDecisionType[];
    };
  };

  static rescoreSession = async (session_id: string) => {
    const response = await useFetch(`/web/session/${session_id}/rescore`, {
      method: "POST",
      formData: true,
    });
    if (!is2XX(response.status)) {
      throw new Error("Failed to create session decision");
    }
  };

  static changeSessionExpirationDate = async (
    session_id: string,
    date: Date,
    comment: string
  ) => {
    const response = await useFetch(`/web/session/${session_id}`, {
      method: "POST",
      body: JSON.stringify({
        expiration_date: date,
        comment: comment,
      }),
    });
    if (!is2XX(response.status)) {
      throw new Error("Failed to change session expiration date");
    }
  };

  static createSessionDecision = async (
    session_id: string,
    status: number,
    comment: string,
    annotated: string
  ) => {
    const response = await useFetch(`/web/session/${session_id}/decision`, {
      method: "POST",
      formData: true,
      body: JSON.stringify({
        session_id: session_id,
        status_id: status,
        comment: comment,
        last: true,
        annotated: annotated,
      }),
    });
    if (!is2XX(response.status)) {
      throw new Error("Failed to create session decision");
    }
  };

  static getCustomerSessions = async (external_id: string) => {
    const response = await useFetch(`/web/session/other-sessions`, {
      method: "POST",
      body: JSON.stringify({
        external_id: external_id,
      }),
    });
    const data = await response.json();
    return data as { data: SessionListType[]; total: number };
  };

  static getSessionsStates = async () => {
    const response = await useFetch(`/web/session/status`, {
      method: "GET",
    });
    const data = await response.json();
    return data as { status: SessionStatusType[] };
  };

  static createSessionAndGetHostURL = async (input: CreateSessionInput) => {
    const response = await useFetch(`/web/session/hosturl`, {
      method: "POST",
      body: JSON.stringify({
        ...input,
      }),
    });
    if (!is2XX(response.status)) {
      await throwBackendError(response);
    }
    const responseBody = await response.json();
    if ("session_host_url" in responseBody) {
      const sessionUrl: string = responseBody["session_host_url"];
      return Env.vid.concat(sessionUrl);
    }
  };

  static getSessionsSettings = async () => {
    const response = await useFetch(`/web/session/settings`, {
      method: "GET",
    });
    const data = await response.json();
    return data as SessionSettings;
  };

  static updateSessionSettings = async (settings: SessionSettings) => {
    const response = await useFetch(`/web/session/settings`, {
      method: "POST",
      body: JSON.stringify(settings),
    });
    if (!is2XX(response.status)) {
      throw new Error("Unable to update sessions settings");
    }
  };
}
