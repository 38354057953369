import { Button } from "@atoms/button/button";
import { ROUTES } from "@features/routes";
import { SessionsFiltersAtom } from "@features/sessions/state/store";
import { useSessions } from "@features/sessions/state/use-sessions";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Bars4Icon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";

export const SessionNavigation = ({ id }: { id: string }) => {
  const [filters] = useRecoilState(SessionsFiltersAtom);
  const { sessions } = useSessions(filters);

  const index = (sessions?.data || []).findIndex((a) => a.session_id === id);
  const previous = (sessions?.data || [])[index - 1];
  const next = (sessions?.data || [])[index + 1];

  return (
    <div className="float-right">
      <div className="m-auto inline-flex items-center">
        <Link
          className="flex"
          to={
            (!!previous?.session_id &&
              ROUTES.SessionView.replace(":id", previous?.session_id)) ||
            ""
          }
        >
          <Button
            size="sm"
            disabled={!previous?.session_id}
            theme="outlined"
            className="rounded-r-none"
          >
            <ArrowLeftIcon className="h-5 w-5" />
            <span className="lg:ml-2 lg:inline hidden"> Previous session</span>
          </Button>
        </Link>

        <Link to={ROUTES.Sessions} className="flex">
          <Button size="sm" theme="outlined" className="rounded-none -ml-px">
            <Bars4Icon className="h-5 w-5" />
            <span className="lg:ml-2 lg:inline hidden"> List of sessions</span>
          </Button>
        </Link>

        <Link
          to={
            (!!next?.session_id &&
              ROUTES.SessionView.replace(":id", next?.session_id)) ||
            ""
          }
          className="flex"
        >
          <Button
            size="sm"
            disabled={!next?.session_id}
            theme="outlined"
            className="rounded-l-none  -ml-px"
          >
            <span className="lg:inline lg:mr-2 hidden">Next session </span>
            <ArrowRightIcon className="h-5 w-5" />
          </Button>
        </Link>
      </div>
    </div>
  );
};
