import { Page } from "@atoms/layout/page";
import Link from "@atoms/link";
import { Info, Title } from "@atoms/text";
import { PageLoader } from "@components/page-loader";
import { InstantReportOptionsAtom } from "@features/press/state/store";
import { useInstantReport } from "@features/press/state/use-instant-report";
import { formatTime } from "@features/utils/dates";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { PressReportBodyDetailsPage } from "../report-details/body-details";
import { InstantPressFilters } from "./filters";

let interval: any = 0;

export default function PressInstantReportPage() {
  const [fakeStep, setFakeStep] = useState(0);
  const [options, setOptions] = useRecoilState(InstantReportOptionsAtom);
  const {
    instantReport,
    loading,
    requestNewInstantReport,
    requestExistingInstantReport,
  } = useInstantReport();

  useEffect(() => {
    if (loading) {
      setFakeStep(0);
      interval = setInterval(() => {
        if (fakeStep < 4) setFakeStep((prev) => (prev + 1) % 5);
      }, 5000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [loading]);

  useEffect(() => {
    // Update url
    const searchParams = new URLSearchParams();
    searchParams.set("report_id", instantReport?.report_body_id || "");
    if (options.entity_type)
      searchParams.set("entity_type", options.entity_type.toString());
    if (options.name) searchParams.set("name", options.name);
    if (options.date_of_birth)
      searchParams.set("date_of_birth", options.date_of_birth);
    if (options.country_codes)
      searchParams.set("country_codes", options.country_codes.join(","));
    window.history.pushState(
      {},
      "",
      `${window.location.pathname}?${searchParams.toString()}`
    );
  }, [instantReport]);

  useEffect(() => {
    if (options.report_id && !loading) {
      requestExistingInstantReport(options.report_id);
    }
  }, [options.report_id]);

  return (
    <>
      <Page>
        <Title className="flex-row flex space-x-2 items-center">
          <div className="flex-row flex space-x-2">Transactional Report</div>
        </Title>
        <div className="mt-4">
          {!options.report_id && (
            <InstantPressFilters
              value={options}
              onChange={setOptions}
              onSubmit={(options) => {
                if (!loading) requestNewInstantReport(options);
              }}
              loading={loading}
            />
          )}{" "}
          {!!options.report_id && (
            <Info>
              Consulting report generated on{" "}
              {formatTime(instantReport?.created_at || 0, undefined, {
                keepDate: true,
              })}
              {" - "}
              <Link
                className="cursor-pointer"
                onClick={() => {
                  setOptions({
                    ...options,
                    report_id: "",
                  });
                }}
              >
                New search
              </Link>
            </Info>
          )}
        </div>
        {!loading && !instantReport && (
          <div
            style={{ height: "50vh" }}
            className="flex justify-center items-center h-full w-full"
          >
            <Info>Nothing to display yet</Info>
          </div>
        )}
        {loading && (
          <div
            style={{ height: "50vh" }}
            className="flex justify-center items-center h-full w-full flex-col"
          >
            <div className="flex flex-col space-y-4 items-center">
              <PageLoader />
              <Info>
                {
                  [
                    "This can take up to 30 seconds...",
                    "Searching the web...",
                    "Reading web pages...",
                    "Compiling results...",
                    "Almost there...",
                  ][fakeStep]
                }
              </Info>
              <div className="w-64 rounded h-2 bg-slate-500/15 overflow-hidden inline-block">
                <div
                  className="h-2 bg-blue-500 transition-all duration-2000 ease-in-out"
                  style={{
                    width: Math.min(1, (fakeStep + 1) / 6) * 0.9 * 100 + "%",
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {!loading && !!instantReport && (
          <div className="space-y-2 mt-6">
            <PressReportBodyDetailsPage reportBody={instantReport} />
          </div>
        )}
      </Page>
    </>
  );
}
