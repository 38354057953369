import { useDashboardTimeseries } from "@features/monitoring/state/use-dashboard";
import { formatTime } from "@features/utils/dates";
import _ from "lodash";
import { Line } from "react-chartjs-2";
import { commonProductsOptions, getMetric } from "./utils";

export const PastChat = () => {
  const { data } = useDashboardTimeseries();

  const labels = data.map((a) =>
    formatTime(a.date, "", {
      keepDate: false,
      keepSeconds: false,
      keepTime: false,
    })
  );

  const categories = ["closed", "open", "reminder", "waiting"];

  const colors = ["#10B981", "#3B82F6", "#6366F1", "#8B5CF6"];

  return (
    <div className="mt-2 flex sm:flex-row flex-col justify-between w-full h-72 space-y-4 sm:space-y-0 sm:space-x-4">
      <Line
        data={{
          labels,
          datasets: [
            ...categories.map((c, i) => ({
              label: _.upperFirst(c.replace(/_/g, " ")),
              data: data.map((a) =>
                getMetric(a, ("monitoringmetric_gochat_threads_" + c) as any)
              ),
              borderColor: colors[i],
              backgroundColor: colors[i] + "EE",
              fill: true,
            })),
          ],
        }}
        options={commonProductsOptions as any}
      />
    </div>
  );
};
