import { useRefreshDashboardScan } from "@features/monitoring/state/use-dashboard";
import { useGlobalEffect } from "@features/utils";
import { LoadingAtom } from "@features/utils/loading";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { AlertsApiClient } from "../api-client/api-client";
import { AlertListType } from "../types";
import { AlertsDetailsAtom } from "./store";
import { useQuery, useQueryClient } from "react-query";
import { RestSearchRequest, SchemaSearchField } from "@components/search-bar/utils/types";
import { renameKYCLevelOnSearchField } from "@views/client/customers/customer-details/olkypass";

export const useAlerts = (query?: RestSearchRequest) => {
  const queryClient = useQueryClient();
  const refresh = useCallback(async () => {
    queryClient.invalidateQueries({ queryKey: ["alerts", query] });
  }, [queryClient, query]);

  const schema = useQuery({
    queryKey: ["alerts-schema"],
    queryFn: AlertsApiClient.getAlertsSchema,
    select:  (schema: SchemaSearchField[]) => renameKYCLevelOnSearchField(schema)
  });

  const newAlerts = useQuery<{ data: AlertListType[]; total: number }>({
    queryKey: ["alerts", query],
    queryFn: async () => {
      const threadSearch = await AlertsApiClient.getAlerts(
        query?.query || [],
        query?.options || {}
      );
      return threadSearch;
    },
    cacheTime: 2000,
  });

  return {
    schema: schema?.data || [],
    alerts: newAlerts?.data,
    loading: newAlerts.isFetching,
    refresh,
  };
};

export const useAlert = (id: string) => {
  const [alert, setAlert] = useRecoilState(AlertsDetailsAtom(id));
  const [loading, setLoading] = useRecoilState(LoadingAtom("useAlert-" + id));
  const refreshScanMonitoring = useRefreshDashboardScan();

  const refresh = useCallback(async () => {
    setLoading(true);
    const alert = await AlertsApiClient.getAlert(id);
    if (alert?.alert_id) {
      setAlert(alert);
    }
    setLoading(false);
  }, [setAlert, id, setLoading]);

  const update = useCallback(
    async (state: number, comment: string) => {
      if (id && comment) {
        setLoading(true);
        await AlertsApiClient.updateAlertStatus(id, state, comment);
        await refresh();
        refreshScanMonitoring();
      } else {
        toast.error("Missing comment and/or state");
      }
    },
    [setLoading, id, refresh, refreshScanMonitoring]
  );

  useGlobalEffect(
    "useAlert-" + id,
    () => {
      if (id) refresh();
    },
    []
  );

  return { alert, loading, update, refresh };
};
