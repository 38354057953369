import { Badge } from "@atoms/badge";
import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import { Title } from "@atoms/text";
import { Table } from "@components/table";
import { Pagination } from "@components/table/table";
import { useAssignments } from "@features/assignments/state/use-assignment";
import { useHasAccess } from "@features/auth/state/use-access";
import { useDashboard } from "@features/monitoring/state/use-dashboard";
import { useTransactions } from "@features/kyt/state/use-transactions";
import { KytTransaction } from "@features/kyt/types";
import _ from "lodash";
import { useRecoilState } from "recoil";
import { availableColumns, defaultColumns } from "./columns";
import { TransactionEditionModalAtom } from "./edition-modal";
import { KytTransactionsListFilter } from "./filters";

export default function LiveTransactionsPage() {
  const hasAccess = useHasAccess();
  const { kyt } = useDashboard();

  const { transactions, loading, setQuery, query, fetchTransactions } =
    useTransactions();
  const [, setTransactionModal] = useRecoilState(TransactionEditionModalAtom);

  useAssignments(
    "transaction",
    transactions?.data?.map((transaction) => transaction.id) || []
  );

  return (
    <Page>
      {hasAccess("KYT_MANAGE") && (
        <Button
          className="float-right"
          theme="primary"
          onClick={() =>
            setTransactionModal({
              open: true,
              transaction: undefined,
            })
          }
          size="sm"
        >
          Add transaction
        </Button>
      )}
      <Title className="flex-row flex space-x-2 items-center">
        <div className="flex-row flex space-x-2">Transactions</div>
        <div className="flex-row flex space-x-2">
          {(kyt?.last_30_days_transactions_by_status
            ?.delayed_transactions_count ?? 0) !== 0 && (
            <Badge className="bg-yellow-500">
              {
                kyt?.last_30_days_transactions_by_status
                  ?.delayed_transactions_count
              }{" "}
              Pending
            </Badge>
          )}
        </div>
      </Title>
      <div className="mt-4" />
      <KytTransactionsListFilter
        value={query}
        onChange={(value) => {
          const options = {
            ...query,
            ..._.omit(value, "on_page", "per_page"),
          };
          setQuery(options);
        }}
        loading={loading}
      />
      <Table
        rowIndex="external_id"
        page={
          1 +
          Math.ceil(
            (query?.options?.offset || 0) / (query?.options?.limit || 1)
          )
        }
        total={transactions?.total ?? 0}
        totalApproximate
        initialPagination={{
          page: 1,
          perPage: query.options.limit ?? 10,
          orderBy: defaultColumns.indexOf(query.options.order_by as string),
          order: query.options.order_way,
        }}
        options={{
          exportCsv: hasAccess("KYT_MANAGE")
            ? {
                fetchData: async (params: Pagination) => {
                  const result = await fetchTransactions({
                    ...query,
                    options: {
                      ...query.options,
                      limit: params.perPage,
                      offset: (params.page - 1) * params.perPage,
                    },
                  });
                  return result.data;
                },
              }
            : {},
        }}
        data={transactions?.data || []}
        columns={defaultColumns.map(
          (col) =>
            availableColumns[col] || {
              title: col,
              orderable: true,
              className: "text-right",
              headClassName: "justify-end",
              thClassName: "w-24",
              render: (row: KytTransaction) => _.get(row, col),
            }
        )}
        onRequestData={async ({ page, perPage, orderBy, order }) => {
          setQuery({
            ...query,
            options: {
              ...query.options,
              offset: (page - 1) * perPage,
              limit: perPage,
              order_by: defaultColumns[orderBy!] || undefined,
              order_way: order ?? "ASC",
            },
          });
        }}
        loading={loading}
      />
    </Page>
  );
}
