import { Button } from "@atoms/button/button";
import { UpdateCustomersRequest } from "@features/customers/types";
import { ROUTES } from "@features/routes";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import {
  buildTransactionalPressQueries,
  buildTransactionalQueries,
} from "../review";

export default function PressScanTest({
  form,
}: {
  form: UpdateCustomersRequest;
}) {
  return (
    <>
      <Link
        target="_blank"
        to={
          ROUTES.NameSearch +
          buildTransactionalQueries({
            customer_type: form.customers[0].account_type,
            company_name: form.customers[0].company_name,
            first_name: form.customers[0].first_name,
            last_name: form.customers[0].last_name,
            date_of_birth: form.customers[0].date_of_birth,
            domicile_code: form.customers[0].domicile_codes[0] ?? "",
            nationality_code: form.customers[0].nationality_codes[0] ?? "",
          })
        }
      >
        <Button theme="default" className="mt-2" size="sm">
          <ArrowTopRightOnSquareIcon className="w-5 inline mr-2" />
          Test in Go!Scan
        </Button>
      </Link>
      <Link
        target="_blank"
        to={
          ROUTES.PressInstantReport +
          buildTransactionalPressQueries({
            customer_type: form.customers[0].account_type,
            company_name: form.customers[0].company_name,
            first_name: form.customers[0].first_name,
            last_name: form.customers[0].last_name,
            date_of_birth: form.customers[0].date_of_birth,
            domicile_code: form.customers[0].domicile_codes[0] ?? "",
            nationality_code: form.customers[0].nationality_codes[0] ?? "",
          })
        }
      >
        <Button theme="default" className="mt-2" size="sm">
          <ArrowTopRightOnSquareIcon className="w-5 inline mr-2" />
          Test in Go!Press
        </Button>
      </Link>
    </>
  );
}
