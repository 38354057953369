import { Checkbox } from "@atoms/input/input-checkbox";
import InputDate from "@atoms/input/input-date";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import Select from "@atoms/select";
import { SearchBar } from "@components/search-bar";
import {
  OutputQuery,
  RestSearchQuery,
  RestSearchRequest,
  SchemaSearchField,
} from "@components/search-bar/utils/types";
import { buildQueryFromMap } from "@components/search-bar/utils/utils";
import { useAgents } from "@features/agents/state/use-agents";
import { useReviewGroups } from "@features/agents/state/use-review-groups";
import { AuthJWT } from "@features/auth/jwt";
import { RISK_CUSTOMERS_ACTIONS } from "@features/risk-decisions/utils";
import { useScenarios } from "@features/scenarios/state/use-scenarios";
import { useSessions } from "@features/sessions/state/use-sessions";
import { useSessionStates } from "@features/sessions/state/use-sessions-states";
import { SessionsRequestOptions, SessionStateIndex } from "@features/sessions/types";
import { SESSIONS_LANGUAGES } from "@features/sessions/utils";
import { useStoredState } from "@features/utils/use-stored-state";
import _, { isEqual } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";

const monthAgo = new Date();
monthAgo.setMonth(monthAgo.getMonth() - 1);

export default function SessionsListFilters({
  onChange,
}: {
  onChange: (value: RestSearchRequest) => void;
}) {
  const { states } = useSessionStates();
  const { schema } = useSessions();
  const { scenarios } = useScenarios();
  const { reviewGroups } = useReviewGroups({ access: "SESSION_UPDATE" });
  const { members } = useAgents();
  const [advancedQuery, setAdvancedQuery] = useState<RestSearchQuery[]>([]);

  const [filters, setFilters] = useStoredState<Partial<SessionsRequestOptions>>(
    "sessions_filters",
    {}
  );

  // Need agents actives
  const agentsActives = members.filter(
    (agent) =>
      agent.active &&
      agent.clients.filter(
        (client) => client.active && client.id === AuthJWT.clientId
      ).length > 0
  );

  const scenariosCategories =
    scenarios?.data.map((scenario) => ({
      id: scenario.id,
      label: scenario.result_label,
    })) ?? [];

  useEffect(() => {
    const dates = {
      gte: filters?.date_from && moment(filters.date_from),
      lte: filters?.date_to && moment(filters.date_to),
    };

    onChange({
      query: [
        ...advancedQuery,
        ...buildQueryFromMap({
          ...(filters?.assigned_to_member_id && {
            assignees: filters?.assigned_to_member_id,
          }),
          ...(filters?.review_groups && {
            review_groups: filters?.review_groups,
          }),
          ...(filters?.status_id && {
            status: filters.status_id,
          }),
          ...(filters?.result_label && {
            scenario_id: parseInt(filters.result_label),
          }),
          ...(filters?.scenario_ref && {
            scenario_ref: filters.scenario_ref,
          }),
          ...(filters?.only_last && {
            is_last: filters.only_last,
          }),
          ...(!filters?.include_unfinished && {
            state: {
              number: SessionStateIndex.Completed,
            },
          }),
          ...(filters?.language && {
            language: filters.language,
          }),
        }),
        ...buildQueryFromMap(
          {
            start_timestamp: {
              range_date: { ...dates },
            },
          },
          "range"
        ),
        ...buildQueryFromMap({
          review_groups: filters?.only_without_review_groups
            ? reviewGroups
            : undefined,
          assignees: filters?.only_without_assignment
            ? agentsActives.map((a) => a.id)
            : undefined,
        }).map((a) => ({ ...a, not: true })),
      ],
      options: {},
    });
  }, [advancedQuery, filters]);

  return (
    <>
      <div className="flex flex-col w-full space-y-2 xl:flex-row xl:space-x-2 xl:space-y-0">
        <div className="flex flex-col w-full">
          <InputLabel
            label="Search"
            input={
              <SearchBar
                schema={{
                  table: "sessions",
                  fields: [
                    ...schema.filter(
                      (field) => !field.possible_additional_keys?.length
                    ),
                    ...schema
                      .filter((field) => field.possible_additional_keys?.length)
                      .reduce((acc: SchemaSearchField[], field) => {
                        return [
                          ...acc,
                          ...(field.possible_additional_keys || []).map(
                            (key) => ({
                              value_type: key.value_type,
                              external_key: `${field.external_key}.${key.key}`,
                              display_name: `${field.external_key}: ${key.display_name}`,
                              allowed_ops: field.allowed_ops,
                              possible_values: field.possible_values,
                            })
                          ),
                        ];
                      }, []),
                  ]
                    .filter((field) => field.external_key !== "assignees") // Due to a strange bug with this one
                    .map((field) => {
                      let type:
                        | "number"
                        | "boolean"
                        | "text"
                        | "date"
                        | `type:${string}`;

                      switch (field.value_type) {
                        case "string":
                          type = "text";
                          break;
                        case "number":
                          type = "number";
                          break;
                        case "boolean":
                          type = "boolean";
                          break;
                        case "date":
                          type = "date";
                          break;
                        // Add more cases if needed
                        default:
                          type = `type:${field.value_type}`;
                      }
                      return {
                        key: field.external_key,
                        label: field.display_name,
                        keywords: (
                          field.display_name +
                          " " +
                          field.display_name.replace(/ /gm, "") +
                          " " +
                          field.external_key
                        ).split(/( |_)/),
                        allowed_ops: field.allowed_ops,
                        possible_values: field.possible_values,
                        type,
                      };
                    }),
                }}
                onSuggest={async (_table: string, column: string) => {
                  if (
                    column === "review_groups" ||
                    column === "customer.review_groups"
                  ) {
                    return reviewGroups.map((a) => ({ value: a, label: a }));
                  }
                  if (column === "customer.edd_state") {
                    return Object.entries(RISK_CUSTOMERS_ACTIONS).map((e) => ({
                      value: e[0],
                      label: e[1],
                    }));
                  }
                  if (column === "language") {
                    return Object.entries(SESSIONS_LANGUAGES).map((e) => ({
                      value: e[0],
                      label: e[1],
                    }));
                  }
                  if (column === "assignees") {
                    return agentsActives.map(({ id, name }) => ({
                      value: id.toString(),
                      label: name,
                    }));
                  }

                  const field = schema.find((f) => f.external_key === column);
                  if (!field?.possible_values) return [];
                  return [
                    ...field.possible_values.map((v) => ({
                      value: v.value,
                      label: v.label,
                    })),
                    ...((field.allowed_ops || []).includes("is_null")
                      ? [{ value: null, label: "None" }]
                      : []),
                  ];
                }}
                onChange={(str: OutputQuery) => {
                  if (str.valid && !isEqual(str.fields, advancedQuery)) {
                    setAdvancedQuery(str.fields);
                  }
                }}
              />
            }
          />
        </div>
        <div className="flex flex-col gap-2 items-end flex-1">
          <div className="flex gap-2">
            <InputLabel
              className="w-36"
              label="Scenario"
              input={
                <Select
                  highlight
                  value={filters?.scenario_ref || ""}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      scenario_ref: e.target.value || null,
                    })
                  }
                >
                  <option value="">All</option>
                  {(scenarios?.data || []).map((scenario) => (
                    <option key={"review_" + scenario.id} value={scenario.code}>
                      {scenario.code}
                    </option>
                  ))}
                </Select>
              }
            />
            <div className="w-74">
              <div className="flex flex-row relative">
                <InputLabel
                  label="Session from"
                  className="w-full xl:w-1/2 focus-within:z-10 -mr-px"
                  input={
                    <InputDate
                      className="rounded-r-none"
                      highlight
                      value={filters?.date_from ?? null}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          date_from: e,
                        })
                      }
                    />
                  }
                />
                <InputLabel
                  label="Session to"
                  className="w-full xl:w-1/2 focus-within:z-10"
                  input={
                    <InputDate
                      className="rounded-l-none"
                      highlight
                      value={filters?.date_to ?? null}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          date_to: e,
                        })
                      }
                    />
                  }
                />
              </div>
            </div>
            <InputLabel
              label="Review status"
              className="w-36"
              input={
                <Select
                  highlight
                  value={
                    filters?.exclude_if_status ? "-1" : filters?.status_id || ""
                  }
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      exclude_if_status: e.target.value === "-1",
                      status_id:
                        e.target.value === "-1"
                          ? null
                          : Number(e.target.value) || null,
                    })
                  }
                >
                  <option value="">All</option>
                  <option value="-1">No status</option>
                  {states.map((status) => (
                    <option key={"review_" + status.id} value={status.id}>
                      {status.label}
                    </option>
                  ))}
                </Select>
              }
            />
          </div>
          <div className="flex gap-2">
            <InputLabel
              label="Review groups"
              className="w-36"
              input={
                <SelectMultiple
                  highlight
                  value={
                    filters?.only_without_review_groups
                      ? ["none"]
                      : filters?.review_groups || []
                  }
                  options={[
                    { value: "none", label: "(None)" },
                    ..._.sortBy(
                      reviewGroups.map((c) => ({
                        value: c,
                        label: c,
                      })),
                      "label"
                    ),
                  ]}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      only_without_review_groups: e.includes("none"),
                      review_groups: (!e.includes("none") && e) || [],
                    })
                  }
                />
              }
            />
            <InputLabel
              label="Language"
              className="w-36"
              input={
                <Select
                  highlight
                  value={filters.language || ""}
                  onChange={(e) =>
                    setFilters({ ...filters, language: e.target.value || null })
                  }
                >
                  <option value="">All</option>
                  {Object.keys(SESSIONS_LANGUAGES).map((languageCode) => (
                    <option key={"lang_" + languageCode} value={languageCode}>
                      {_.capitalize(SESSIONS_LANGUAGES[languageCode])}
                    </option>
                  ))}
                </Select>
              }
            />
            <InputLabel
              label="Category"
              className="w-36"
              input={
                <Select
                  highlight
                  value={filters?.result_label || ""}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      result_label: e.target.value || null,
                    })
                  }
                >
                  <option value="">All</option>
                  {scenariosCategories
                    .filter((value) => !!value?.label)
                    .map((category) => (
                      <option
                        key={"category_" + category.label}
                        value={category.id}
                      >
                        {_.capitalize(category.label)}
                      </option>
                    ))}
                </Select>
              }
            />

            <InputLabel
              label="Assigned to"
              className="w-36"
              input={
                <SelectMultiple
                  highlight
                  selectionLimit={1}
                  value={
                    filters?.only_without_assignment
                      ? ["none"]
                      : filters?.assigned_to_member_id
                      ? [filters.assigned_to_member_id.toString()]
                      : []
                  }
                  options={[
                    { value: "none", label: "(None)" },
                    ..._.sortBy(
                      agentsActives.map((agent) => ({
                        value: agent.id.toString(),
                        label: agent.name,
                      })) || [],
                      "label"
                    ),
                  ]}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      only_without_assignment: e.includes("none"),
                      assigned_to_member_id:
                        !e.includes("none") && e.length > 0
                          ? parseInt(e[0])
                          : null,
                    })
                  }
                />
              }
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end gap-x-2 mt-2">
        <InputLabel
          label="Only last by customer/scenario"
          input={
            <Checkbox
              label="Show only last"
              value={filters?.only_last || false}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  only_last: e,
                });
              }}
            />
          }
        />
        <InputLabel
          label="Unfinished sessions"
          input={
            <Checkbox
              label="Include unfinished"
              value={filters?.include_unfinished || false}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  include_unfinished: e,
                });
              }}
            />
          }
        />
      </div>
    </>
  );
}
