import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { Checkbox } from "@atoms/input/input-checkbox";
import { PageBlock } from "@atoms/layout/page-block";
import { Loader } from "@atoms/loader";
import { Info } from "@atoms/text";
import { Table } from "@components/table";
import { useScheduledAccess } from "@features/scheduled-access/state/use-scheduled-access";
import { ScheduledAccessData } from "@features/scheduled-access/types";
import { useState } from "react";
import { ScheduledAccessModal } from "./scheduled-access-modal";

export const ScheduledAccess = () => {
  const {
    scheduledAccess,
    enableScheduledAccess,
    isLoading,
    deleteAccess,
    deletingAccess,
    isRefetching,
  } = useScheduledAccess();

  const [scheduledAccessModal, setScheduledAccessModal] =
    useState<boolean>(false);

  const [editAccessModal, setEditAccessModal] =
    useState<ScheduledAccessData | null>(null);

  return (
    <PageBlock className="py-4">
      <Info>
        Enable this feature to restrict access for your users based on time.
      </Info>
      <div className="flex flex-col gap-2 mt-4">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Checkbox
              label={"Enabled"}
              disabled={isRefetching}
              value={scheduledAccess?.enabled}
              onChange={async (status: boolean) => {
                enableScheduledAccess(status);
              }}
            />
            {scheduledAccess?.enabled && (
              <div className="flex flex-col items-end">
                <Button
                  size="sm"
                  onClick={() => {
                    setScheduledAccessModal(true);
                  }}
                >
                  Add scheduled access
                </Button>
                <Table
                  className="w-full"
                  showPagination={false}
                  data={scheduledAccess.access || []}
                  columns={[
                    {
                      title: "IP",
                      render: (item) => (
                        <div className="flex gap-2">
                          {(item.ips || []).map((ip, i) => (
                            <Tag key={i}>{ip}</Tag>
                          ))}
                        </div>
                      ),
                    },
                    {
                      title: "Review groups",
                      render: (item) => (
                        <div className="flex gap-2">
                          {(item.review_groups || []).map((group, i) => (
                            <Tag key={i}>{group}</Tag>
                          ))}
                        </div>
                      ),
                    },
                    ...([
                      {
                        title: "Actions",
                        headClassName: "justify-end",
                        className: "text-right",
                        thClassName: "w-24",
                        render: (item: ScheduledAccessData) => (
                          <div className="flex gap-2">
                            <Button
                              theme="outlined"
                              size="sm"
                              onClick={() => {
                                setScheduledAccessModal(true);
                                setEditAccessModal(item);
                              }}
                            >
                              Edit
                            </Button>
                            <ButtonConfirm
                              theme="danger"
                              confirmTitle="Delete scheduled access"
                              confirmMessage={`The rule for ip : ${
                                item.ips.length ? item.ips.join("; ") : "None"
                              } and review groups : ${
                                item.review_groups.length
                                  ? item.review_groups.join(", ")
                                  : "None"
                              } will be deleted, are you sure to continue ?`}
                              size="sm"
                              loading={deletingAccess || isRefetching}
                              onClick={async () => {
                                await deleteAccess(item);
                              }}
                            >
                              Delete
                            </ButtonConfirm>
                          </div>
                        ),
                      },
                    ]),
                  ]}
                />
              </div>
            )}
          </>
        )}
      </div>
      <ScheduledAccessModal
        onClose={() => {
          setScheduledAccessModal(false);
          setEditAccessModal(null);
        }}
        open={scheduledAccessModal}
        scheduledAccess={editAccessModal}
      />
    </PageBlock>
  );
};
