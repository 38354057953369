import { Tag } from "@atoms/badge/tag";
import { Base, BaseSmall, Info, InfoSmall } from "@atoms/text";
import { AssigneeTag } from "@components/assignations/tag";
import { useAuth } from "@features/auth/state/hooks";
import { extractCustomerFullName } from "@features/customers/utils";
import { useThread } from "@features/inbox/state/use-thread";
import { InboxThread } from "@features/inbox/types";
import {
  INBOX_STATUS_COLORS,
  INBOX_STATUS_LABELS,
} from "@features/inbox/utils";
import { useMessagesLabels } from "@features/messages-labels/state/use-messages-labels";
import { stringToColor } from "@features/utils";
import { formatTime } from "@features/utils/dates";
import { htmlDecodeRawTextContent } from "@features/utils/strings";
import { RectangleStackIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import { getFileType } from "@views/client/customers/customer-details/document-manager";
import _ from "lodash";
import SimpleBar from "simplebar-react";
import { twMerge } from "tailwind-merge";

export default function ThreadCard(props: {
  thread: InboxThread;
  noInList?: boolean;
}) {
  const { labels: tags } = useMessagesLabels();
  const { agent } = useAuth();

  const { thread } = useThread(props.thread.id);

  if (!thread) return <></>;

  const attachmentsCount = thread.attachments?.length;
  const messagesCount = thread.message_count;
  const read =
    !thread?.last_message ||
    thread?.last_message?.read_by_agents?.includes(agent?.agent_id || 0) ||
    thread?.last_message?.delivery !== 4 ||
    false;

  return (
    <div
      className={twMerge(
        "flex w-full flex-col",
        props.noInList &&
          "bg-white p-1 transform transition-transform duration-300 ease-in-out border"
      )}
    >
      {props.noInList && (
        <div className="px-2 bg-blue-300 text-blue-500 rounded-t-sm">
          <BaseSmall noColor>
            Thread moved to{" "}
            <strong>{INBOX_STATUS_LABELS[thread.status]}</strong>
          </BaseSmall>
        </div>
      )}
      <div
        className={twMerge(
          "flex h-32 w-full flex-row py-2 " +
            (thread.status !== 1 && "opacity-50"),
          props.noInList && "pt-1 border border-blue-300 rounded-b-sm"
        )}
      >
        {/* Side icons (unread, has attachments etc) */}
        <div className="w-8 shrink-0 justify-center">
          <div className="flex h-8 items-center justify-center">
            <div
              className={`h-3 w-3 rounded-full ${
                thread.status === 1 && !read
                  ? "bg-" +
                    INBOX_STATUS_COLORS[thread.status] +
                    " ring-2 ring-blue-500 ring-offset-1"
                  : thread.status === 1 && read
                  ? "bg-" + INBOX_STATUS_COLORS[thread.status] + ""
                  : thread.status !== 2
                  ? "bg-" + INBOX_STATUS_COLORS[thread.status]
                  : "bg-gray-500 opacity-0"
              }`}
            />
          </div>
          <div className="flex flex-col items-center justify-center space-y-1">
            {!!attachmentsCount && (
              <PaperClipIcon className="h-4 w-4 text-gray-500 opacity-50" />
            )}
            {messagesCount > 1 && (
              <RectangleStackIcon className="h-4 w-4 text-gray-500 opacity-50" />
            )}
          </div>
        </div>
        {/* Main body */}
        <div className="flex grow flex-col overflow-hidden pr-2">
          {/* Header */}
          <div className="flex h-8 shrink-0 flex-row items-center justify-center">
            <div className="grow overflow-hidden">
              <Base className="block grow overflow-hidden text-ellipsis whitespace-nowrap !font-medium">
                {(thread.customers || [])
                  .map((customer) => {
                    return (
                      _.capitalize(extractCustomerFullName(customer)) +
                      (thread.customers.length < 2
                        ? " (" + customer.external_id + ")"
                        : "")
                    );
                  })
                  .join(", ") || "-"}
              </Base>
            </div>
            <AssigneeTag
              className="ml-2 shrink-0"
              type="thread"
              id={thread.id}
            />
          </div>

          {/* Last msg title */}
          <div className="flex h-4 flex-row items-center justify-center">
            <div className="grow overflow-hidden">
              <BaseSmall className="block grow overflow-hidden text-ellipsis whitespace-nowrap">
                {thread.last_message
                  ? htmlDecodeRawTextContent(thread.last_message?.subject) ||
                    "(No subject)"
                  : "(No message yet)"}
              </BaseSmall>
            </div>
            <div className="shrink-0">
              <InfoSmall>
                {messagesCount > 1 && messagesCount + " items - "}
                {!!thread.last_message &&
                  formatTime(thread.last_message?.created_at || 0)}
              </InfoSmall>
            </div>
          </div>

          {/* Last msg body */}
          <div className="mt-1 flex h-10 flex-row items-start justify-center">
            <div className="grow overflow-hidden">
              <Info
                className="block grow overflow-hidden text-ellipsis whitespace-pre-wrap"
                style={{
                  lineHeight: "1rem",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {thread.last_message
                  ? htmlDecodeRawTextContent(thread.last_message?.content) ||
                    "(No content)"
                  : "-"}
              </Info>
            </div>
          </div>

          <div className="flex h-8 items-center">
            {!!attachmentsCount && (
              <SimpleBar className="h-8 w-1/2 overflow-auto whitespace-nowrap rounded">
                {(thread.attachments || [])?.map((attachment) => {
                  const { icon } = getFileType(attachment);
                  return (
                    <Tag
                      data-tooltip={attachment.file_name}
                      key={attachment.id}
                      ellipsis
                      className="relative top-1 mr-1 h-6 pt-0.5"
                    >
                      {icon({
                        className:
                          "w-4 h-4 inline relative -top-px mr-px -ml-px",
                      })}
                      {attachment.file_name?.replace(
                        /^(.{6})(.*?)(.{6})$/,
                        "$1…$3"
                      )}
                    </Tag>
                  );
                })}
              </SimpleBar>
            )}

            <SimpleBar
              className={
                "h-8 overflow-auto whitespace-nowrap rounded " +
                (attachmentsCount ? "ml-2 w-1/2" : "grow")
              }
            >
              {!thread.tags?.length && (
                <Tag className="relative top-1 mt-px h-6 pt-0.5 opacity-25">
                  No tags
                </Tag>
              )}
              {(thread.tags || [])?.map((tagCode) => {
                const tag = tags.find((t) => t.code === tagCode);
                if (!tag) return null;
                return (
                  <Tag
                    key={tag.code}
                    ellipsis
                    noColor
                    className="relative top-1 mr-1 h-6 pt-0.5 capitalize text-black"
                    style={{ backgroundColor: stringToColor(tag.code) }}
                  >
                    {tag.name}
                  </Tag>
                );
              })}{" "}
            </SimpleBar>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}
