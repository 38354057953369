import environment from "@config/environment";
import { AuthJWT } from "@features/auth/jwt";
import { useAuth } from "@features/auth/state/hooks";
import EventBus from "js-event-bus";
import _ from "lodash";
import { useEffect } from "react";
import { Socket, io } from "socket.io-client";

let ready = false;
let joined: any[] = [];
let socket: Socket | null = null;

export const websocketBus = new EventBus();

websocketBus.on("join", (event) => {
  if (!ready) joined.push(event);
});
websocketBus.on("leave", (event) => {
  if (!ready) joined = joined.filter((j) => j !== event);
});

export const useWebSockets = () => {
  const { user } = useAuth();
  useEffect(() => {
    if (user?.id) {
      let server = environment.server.replace(/\/$/, "");
      if (!server.match(/:[0-9]+$/)) {
        server = server + (server.includes("https://") ? ":443" : ":80");
      }
      socket = io(server + "/backoffice", {
        ...("WebSocket" in window || "MozWebSocket" in window
          ? { transports: ["websocket"] }
          : {}),
        auth: {
          token: AuthJWT.token,
        },
      });

      // Event Update, and Object AssignmentType
      // Need to be either generic or passed as props
      socket.on("message", (event: string) => {
        const data: any = JSON.parse(event);
        websocketBus.emit(data._room, null, data);
      });

      socket.on("connect", () => {
        ready = true;
        console.log("websockets connected");

        websocketBus.detach("join"); //Detach buffer
        websocketBus.on("join", (event) => {
          joined.push(event);
          if (socket) socket.emit("join", event.room);
        });

        websocketBus.detach("leave"); //Detach buffer
        websocketBus.on("leave", (event) => {
          joined = joined.filter((j) => j !== event);
          if (socket) socket.emit("leave", event.room);
        });

        joined = _.uniqBy(joined, "room");
        joined.forEach((event) => socket && socket.emit("join", event.room));
      });

      socket.on("disconnect", () => {
        ready = false;
        console.log("websockets disconnected");
      });
    } else {
      socket?.close();
      socket = null;
    }

    return () => {
      socket?.close();
      socket = null;
    };
  }, [user?.id]);
};
