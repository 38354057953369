import { useRef, useEffect } from "react";
import * as d3 from "d3";
import { KytEdge } from "@features/kyt/types";
import { formatNumberLinkVolume } from "@features/kyt/d3-kyt-utils";
import { FocusedNodeAtom } from "@features/kyt/state/store";
import { useRecoilValue } from "recoil";

const defaultLinkColor = "#bfdbfe";
const incomingLinkColor = "#4ade80";
const outgoingLinkColor = "#b91c1c";

const Link = ({
  link,
  size,
  type,
}: {
  link: KytEdge;
  size: number;
  type: string;
}) => {
  const ref = useRef<SVGLineElement>(null);

  useEffect(() => {
    d3.select(ref.current).data([link]);
  }, [link]);

  // Determine the stroke color based on link type
  let strokeColor;
  if (type === "incoming") {
    strokeColor = incomingLinkColor;
  } else if (type === "outgoing") {
    strokeColor = outgoingLinkColor;
  } else {
    strokeColor = defaultLinkColor; // Default color
  }

  return (
    <g>
      {link.doubleway ? (
        <path
          id={link.start_id + link.end_id + "-path"}
          className="link"
          ref={ref}
          stroke={strokeColor}
          strokeWidth={size / 20 + (link.group ?? 0)}
          fill="none"
          markerEnd={`url(#arrowhead-${type})`}
        />
      ) : (
        <line
          id={link.start_id + link.end_id + "-path"}
          className="link"
          ref={ref}
          strokeWidth={size / 20 + (link.group ?? 0)}
          stroke={strokeColor}
          markerEnd={`url(#arrowhead-${type})`}
        />
      )}
      <text
        className="link-label"
        fill={d3.rgb(strokeColor).darker().formatHex()}
        textAnchor="middle"
        fontSize={size / 2}
      >
        <textPath
          href={"#" + link.start_id + link.end_id + "-path"}
          startOffset="50%"
        >
          <tspan dy="-10px" fontWeight="bold">
            {formatNumberLinkVolume(link.volume[0])}€
          </tspan>{" "}
          / {link.count[0]}
        </textPath>
      </text>
    </g>
  );
};

export const GraphLinks = ({
  links,
  size,
}: {
  links: KytEdge[];
  size: number;
}) => {
  const focusedNode = useRecoilValue(FocusedNodeAtom);

  return (
    <>
      <defs>
        <marker
          id="arrowhead-default"
          viewBox="0 0 10 10"
          refX="5"
          refY="5"
          markerWidth={5}
          markerHeight={5}
          orient="auto-start-reverse"
        >
          <path d="M 0 0 L 10 5 L 0 10 z" fill={defaultLinkColor} />
        </marker>
        <marker
          id="arrowhead-incoming"
          viewBox="0 0 10 10"
          refX="5"
          refY="5"
          markerWidth={5}
          markerHeight={5}
          orient="auto-start-reverse"
        >
          <path d="M 0 0 L 10 5 L 0 10 z" fill={incomingLinkColor} />
        </marker>
        <marker
          id="arrowhead-outgoing"
          viewBox="0 0 10 10"
          refX="5"
          refY="5"
          markerWidth={5}
          markerHeight={5}
          orient="auto-start-reverse"
        >
          <path d="M 0 0 L 10 5 L 0 10 z" fill={outgoingLinkColor} />
        </marker>
      </defs>
      <g className="links">
        {links.map((link: KytEdge) => {
          // Determine link type
          let linkType;
          if (link.start_id === focusedNode?.id) {
            linkType = "outgoing";
          } else if (link.end_id === focusedNode?.id) {
            linkType = "incoming";
          } else {
            linkType = "default";
          }

          return (
            <Link
              key={link.start_id + link.end_id}
              link={link}
              size={size}
              type={linkType}
            />
          );
        })}
      </g>
    </>
  );
};
