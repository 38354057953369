import { SchemaSearchField } from "@components/search-bar/utils/types";
import { CustomersApiClient } from "@features/customers/api-client/api-client";
import { KYC_LEVEL, KYCLevelType } from "@features/customers/types";
import _ from "lodash";
import { useQuery } from "react-query";

export const isOlkyDomain = () => {
  return !!window.location.origin.includes("olky");
};

export const KYC_LEVEL_LABEL = isOlkyDomain() ? "OlkyPass Level" : "KYC level";
export const KYC_LEVEL_REQUESTED_LABEL = isOlkyDomain() ? "OlkyPass Level requested" : "KYC level requested"

export const renameKYCLevelOnSearchField = (fields: SchemaSearchField[]) => {
  return fields.map(field => {
    if (field.external_key.endsWith(".kyc_level")) {
      return {...field, display_name: KYC_LEVEL_LABEL}
    }
    if (field.external_key.endsWith(".kyc_level_requested")) {
      return {...field, display_name: KYC_LEVEL_REQUESTED_LABEL}
    }
    return field;
  });
}

export const useKycLevel = (id: string, state: string | number) => {
  const isNull = _.isNull(state);
  let newState = typeof state === "string" ? parseInt(state) : state;

  if (isNull) {
    newState = KYCLevelType.KYC_LEVEL_UNKNOWN;
  }

  const lastKYCLevelUpdate = useQuery({
    queryKey: ["kyclevel-history", id],
    queryFn: async () => {
      if (!newState) return null;
      const resUnsorted = await CustomersApiClient.getRevisions(id);
      const res = _.sortBy(resUnsorted, (a) =>
        new Date(a.created_at).getTime()
      );
      let lastUpdated = res[0].created_at;
      for (let i = 1; i < res.length; i++) {
        if (
          res[i].custom_fields[newState] !==
          res[i - 1]?.custom_fields?.[newState]
        ) {
          lastUpdated = res[i].created_at;
        }
      }
      return new Date(lastUpdated).getTime();
    },
  });

  return {
    color: KYC_LEVEL[newState]?.color || "slate-500",
    state: KYC_LEVEL[newState]?.label || "None",
    updated: new Date(lastKYCLevelUpdate?.data || 0).getTime(),
  };
};
