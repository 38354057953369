import Avatar from "@atoms/avatar/avatar";
import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import Link from "@atoms/link";
import { Base, BaseSmall, Info, InfoSmall } from "@atoms/text";
import { useAgents } from "@features/agents/state/use-agents";
import { useInboxMailboxes } from "@features/inbox-settings/state/use-inbox-mailboxes";
import { useReadMessage } from "@features/inbox/state/use-read-message";
import {
  InboxMessage,
  MessageDeliveryStatus,
  MessageOrigin,
} from "@features/inbox/types";
import {
  MESSAGE_DELIVERY_STATUS_COLORS,
  MESSAGE_DELIVERY_STATUS_LABELS,
} from "@features/inbox/utils";
import { formatTime } from "@features/utils/dates";
import {
  htmlDecodeRawTextContent,
  htmlParseAndSecure,
} from "@features/utils/strings";
import {
  ArrowUturnLeftIcon,
  ChatBubbleLeftEllipsisIcon,
  EnvelopeIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import { useSetChatMessageModal } from "../../components/modals/create-message-modal";
import { InboxAttachment } from "./message-attachment";
import { TextWithLinksAndHTML } from "./text-with-links";

const separateContentAndReplies = (content: string) => {
  //Trim content from empty lines or lines with only spaces
  content = content.replace(/^[ \t]+$/gm, "");
  content = content.replace(/\n+$/, "");
  //Start from last line and goes up while lines starts with "> " then split the content in two
  const lines = content.split("\n");
  let i = lines.length - 1;
  while (i >= 0 && (!lines[i] || lines[i].match(/^>+ /))) {
    i--;
  }
  if (i >= 0 && lines[i].match(/^--+ *$/)) i--;
  while (i >= 0 && (!lines[i] || lines[i].match(/^>+ /))) {
    i--;
  }

  let message = lines.slice(0, i + 1).join("\n");
  let history = lines.slice(i + 1).join("\n");

  if (history === "" && content.match(/&gt; On/)) {
    const matches = content.match(/&gt; On/);
    if (matches) {
      [message, history] = content.split(matches?.[0]);
    }
    history = (matches?.[0] || "") + (history || "");
  }

  if (
    history === "" &&
    content.includes("*From:*") &&
    content.includes("*To:*")
  ) {
    [message, history] = content.split("*From:*");
    history = "*From:*" + (history || "");
  }

  if (history === "" && content.match(/^\*.*?\*.*?\n+.*?[0-9]{4}.*?$/gm)) {
    const matches = content.match(/^\*.*?\*.*?\n+.*?[0-9]{4}.*?$/gm);
    if (matches) {
      [message, history] = content.split(matches?.[1]);
      history = (matches?.[1] || "") + (history || "");
    }
  }

  /**
   * Outlook format:
   * De : Test Algoreg
   * Envoyé : mardi 28 janvier 2025 16:51
   * À : Nicolas FEITH (NFH)
   * Objet : Vid session re
   * => regex suivante :
   * \n([A-Z].+: .+\n){4,6}\n
   */
  const regexOutlook = /(\n\n|\n\n------.+$\n)(^[^ ].+: .+$\n){4,6}\n/gm;
  if (history === "" && content.match(regexOutlook)) {
    const matches = content.match(regexOutlook);
    if (matches) {
      [message, history] = content.split(matches?.[0]);
      history = (matches?.[0] || "") + (history || "");
    }
  }

  for (const separator of [
    "Original Message",
    "Message d'origine",
    "Messaggio originale",
  ]) {
    if (history === "" && content.includes(`-----${separator}-----`)) {
      [message, history] = content.split(`-----${separator}-----`);
      history = `-----${separator}-----` + history;
    }
  }

  if (history === "") {
    //Split after 100 lines
    const lines = message.split("\n");
    if (lines.length > 100) {
      history = lines.slice(100).join("\n");
      message = lines.slice(0, 100).join("\n");
    }
  }

  return [
    message.replace(/\n\n\n+/gm, "\n\n").trimEnd(),
    history.replace(/\n\n\n+/gm, "\n\n").trimStart(),
  ];
};

export default function ThreadMessageCard(props: {
  threadId: string;
  message: InboxMessage;
  replyTo?: InboxMessage;
}) {
  const { getAgentForClient } = useAgents();
  const setEditModal = useSetChatMessageModal();
  const { mailboxes } = useInboxMailboxes();

  const senderName = props.message.from;
  const senderAgent = getAgentForClient().find(
    (a) => props.message.agent_id === a.agent_id
  );

  const contentAndReplies = separateContentAndReplies(
    htmlParseAndSecure(props.message.content) ?? ""
  );

  const [showReplies, setShowReplies] = useState(false);

  const { ref, read } = useReadMessage(props.threadId, props.message.id);

  const mailbox = mailboxes.find((a) => a.id === props.message.mailbox_id);

  return (
    <>
      {props.replyTo && (
        <div className="p-2 bg-blue-50 dark:bg-blue-900 border border-blue-500 flex flex-col rounded-t">
          <InfoSmall>
            Reply to {props.replyTo.from},{" "}
            {formatTime(props.replyTo.created_at)}
          </InfoSmall>
          <BaseSmall className="max-h-4 overflow-hidden whitespace-nowrap text-ellipsis">
            {htmlDecodeRawTextContent(props.replyTo.content)}
          </BaseSmall>
        </div>
      )}
      <div
        ref={ref}
        className={
          "relative rounded-sm flex flex-col border-b border-r border-l divide-y divide-slate-300 dark:divide-slate-700 transition-all " +
          (props.replyTo ? "" : "border-t ") +
          (read
            ? "bg-white dark:bg-slate-800 border-slate-300 dark:border-slate-700"
            : "bg-blue-50 dark:bg-blue-50/10 border-blue-500")
        }
        style={{
          boxShadow: "0 0 16px rgba(0,0,0,0.1)",
        }}
      >
        {!read && (
          <div className="absolute mx-auto left-0 right-0 -top-2 w-24 h-4 text-center rounded-full text-white bg-blue-500 ">
            <InfoSmall
              noColor
              className="h-4 flex items-center w-24 justify-center"
            >
              New message
            </InfoSmall>
          </div>
        )}
        <div className="flex flex-row  p-2 space-x-2">
          <Avatar
            className="mt-1"
            size={10}
            fallback={senderAgent?.name ? senderAgent?.name : senderName}
          />
          <div className="grow flex flex-col">
            <Base>
              {senderAgent?.name
                ? `${senderAgent?.name} via ${senderName}`
                : senderName}
            </Base>
            <div>
              <BaseSmall>To: </BaseSmall>
              <Info>
                {props.message.to
                  .split(/,|;/g)
                  .map((a) => a.trim())
                  .filter((a) => a)
                  .join(", ") ||
                  (mailbox ? (
                    <>
                      <b>{mailbox?.from_email}</b> ({mailbox?.account_name})
                      using{" "}
                      <b>
                        {props.message.origin === MessageOrigin.IMAP
                          ? "mail app"
                          : "web support"}
                      </b>
                    </>
                  ) : (
                    ""
                  ))}
              </Info>
            </div>
            {props.message.cc && props.message.cc.length > 0 && (
              <div>
                <BaseSmall>Cc: </BaseSmall>
                <Info>
                  {props.message.cc
                    .split(/,|;/g)
                    .map((a) => a.trim())
                    .filter((a) => a)
                    .join(", ")}
                </Info>
              </div>
            )}
            {props.message.bcc && props.message.bcc.length > 0 && (
              <div>
                <BaseSmall>Bcc: </BaseSmall>
                <Info>
                  {props.message.bcc
                    .split(/,|;/g)
                    .map((a) => a.trim())
                    .filter((a) => a)
                    .join(", ")}
                </Info>
              </div>
            )}
          </div>
          <div className="flex flex-col space-y-1 items-end">
            <div className="flex flex-row space-x-1 ">
              {props.message.origin === MessageOrigin.IMAP && (
                <span
                  data-tooltip={"Message sent from an external email server"}
                  tabIndex={0}
                  className="flex"
                >
                  <Tag noColor className={"text-red-500 flex items-center"}>
                    <EnvelopeIcon className="w-4 h-4" />
                  </Tag>
                </span>
              )}
              {props.message.origin === MessageOrigin.APIENDUSER && (
                <span
                  data-tooltip={"Message sent from the web support page"}
                  tabIndex={0}
                  className="flex"
                >
                  <Tag noColor className={"text-slate-500 flex items-center"}>
                    <ChatBubbleLeftEllipsisIcon className="w-4 h-4" />
                  </Tag>
                </span>
              )}
              <Tag
                noColor
                className={
                  "text-white bg-" +
                  MESSAGE_DELIVERY_STATUS_COLORS[props.message.delivery]
                }
              >
                {MESSAGE_DELIVERY_STATUS_LABELS[props.message.delivery]}
              </Tag>
            </div>
            <InfoSmall>{formatTime(props.message.created_at)}</InfoSmall>
          </div>
        </div>
        <div className="flex flex-row divide-x divide-slate-300 dark:divide-slate-700">
          <div className="p-2 py-1">
            <InfoSmall>SUBJECT</InfoSmall>
          </div>
          <div className="grow p-2 py-1">
            {props.message.priority !== 3 && (
              <InfoSmall
                className={
                  "mr-1 " +
                  ["", "text-red-500", "text-orange-500", "", "(low)", "(low)"][
                    props.message.priority
                  ]
                }
                noColor
              >
                {["", "!!", "!", "", "(low)", "(low)"][props.message.priority]}
              </InfoSmall>
            )}
            <BaseSmall>
              {htmlDecodeRawTextContent(props.message.subject)}
            </BaseSmall>
          </div>
        </div>
        <div className="p-3 whitespace-pre-wrap">
          <Base>
            <TextWithLinksAndHTML text={contentAndReplies[0]} />
          </Base>
          {contentAndReplies[1]?.length > 0 && (
            <div className="mt-2">
              <BaseSmall>
                <Link onClick={() => setShowReplies(!showReplies)}>
                  See {!showReplies ? "more" : "less"}
                </Link>
              </BaseSmall>
              {showReplies && (
                <BaseSmall>
                  <br />
                  <TextWithLinksAndHTML text={contentAndReplies[1]} />
                </BaseSmall>
              )}
            </div>
          )}
          {!!props.message.attachments?.length && (
            <div
              className="mt-2 whitespace-normal flex flex-wrap items-center"
              style={{ lineHeight: 0 }}
            >
              {props.message.attachments.map((a) => {
                return (
                  <InboxAttachment
                    key={a.id}
                    file={a}
                    threadId={props.threadId}
                  />
                );
              })}
            </div>
          )}
        </div>
        {(props.message.delivery === MessageDeliveryStatus.DRAFT ||
          props.message.delivery === MessageDeliveryStatus.RECEIVED) && (
          <div className="p-2 flex flex-row space-x-2">
            {props.message.delivery === MessageDeliveryStatus.RECEIVED && (
              <Button
                size="sm"
                theme="outlined"
                className="space-x-1"
                onClick={() => {
                  setEditModal({
                    open: true,
                    threadId: props.threadId,
                    message: {
                      subject: props.message?.subject || "",
                      to:
                        (props.message?.delivery === 4
                          ? props.message.from
                          : props.message.to) || "",
                    },
                  });
                }}
              >
                <ArrowUturnLeftIcon className="-ml-1 w-4 h-4" />
                <span>Reply</span>
              </Button>
            )}
            {props.message.delivery === MessageDeliveryStatus.DRAFT && (
              <>
                <Button
                  size="sm"
                  theme="outlined"
                  className="space-x-1"
                  onClick={() => {
                    setEditModal({
                      open: true,
                      message: props.message,
                      threadId: props.threadId,
                    });
                  }}
                >
                  <PencilSquareIcon className="-ml-1 w-4 h-4" />
                  <span>Edit</span>
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}
