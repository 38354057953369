import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import Select from "@atoms/select";
import { Title } from "@atoms/text";
import { Table } from "@components/table";
import { useAssignments } from "@features/assignments/state/use-assignment";
import { useHasAccess } from "@features/auth/state/use-access";
import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";
import {
  CustomerTableViewAtom,
  CustomersQueryInputAtom,
} from "@features/customers/state/store";
import { useCustomers } from "@features/customers/state/use-customers";
import { CustomerSearchType } from "@features/customers/types";
import { ROUTES } from "@features/routes";
import { useScenarios } from "@features/scenarios/state/use-scenarios";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { CustomerEditionModalAtom } from "../customer-details/edition-form";
import { getColumns } from "./columns";
import { CustomersListFilters } from "./filters";
import { useColumns } from "./use-columns";
import { CustomView, CustomViewAtom, useCustomViews } from "./custom-view";
import { Pagination } from "@components/table/table";

export const CustomersListPage = () => {
  const hasAccess = useHasAccess();
  const setOpenCreateModal = useSetRecoilState(CustomerEditionModalAtom);
  const { scenarios } = useScenarios();
  const { fields } = useCustomFields();
  const { schema } = useCustomers();
  const kytContexts = schema.find((a) => a.external_key === "kyt_contexts")
    ?.possible_values || [
    { label: "No context", value: "no_context" },
    { label: "All", value: "all" },
  ];

  const [view, setView] = useRecoilState(CustomerTableViewAtom);
  const [advancedQuery, setAdvancedQuery] = useRecoilState(
    CustomersQueryInputAtom
  );

  const { customers, fetchCustomers, loading } = useCustomers();
  useAssignments("customer", customers.data?.map((a) => a.customer_id) || []);

  const { views } = useCustomViews();
  const { columns } = useColumns(advancedQuery, view, views.data || []);
  const renderColumns = getColumns(
    fields,
    scenarios,
    kytContexts.map((context) => context.value)
  );
  const setViewModal = useSetRecoilState(CustomViewAtom);

  return (
    <Page>
      {hasAccess("CUSTOMER_UPDATE") && (
        <>
          <Link to={ROUTES.CustomerManager}>
            <Button
              className="float-right rounded-l-none -ml-px"
              theme="outlined"
              size="sm"
            >
              Import file
            </Button>
          </Link>
          <Button
            className="float-right rounded-r-none"
            theme="primary"
            onClick={() =>
              setOpenCreateModal({
                open: true,
                customer: undefined,
              })
            }
            size="sm"
          >
            Add customer
          </Button>
          <Select
            className="float-right mr-6 w-max"
            size="sm"
            value={view}
            onChange={(e) => {
              if (e.target.value === "custom") {
                setViewModal({
                  id: "new",
                  name:
                    "Copy of " +
                    ((views?.data || []).find((a) => a.id === view)?.name ||
                      view ||
                      "default"),
                  columns: columns,
                });
              } else {
                setView(e.target.value as any);
              }
            }}
          >
            <option value="">General view</option>
            <option value="risk">Go!Risk view</option>
            <option value="scan">Go!Scan view</option>
            <option value="kyt">Go!KYT view</option>
            <option value="vid">Go!Vid view</option>
            <option value="chat">Go!Chat view</option>
            {(views?.data || []).map((view) => (
              <option key={view.id} value={view.id}>
                - {view.name}
              </option>
            ))}
            <option value="custom">+ Custom view</option>
          </Select>
          {(views?.data || []).map((a) => a.id).includes(view) && (
            <Button
              className="float-right mr-2"
              size="sm"
              theme="default"
              onClick={() => setViewModal({ id: view })}
            >
              Edit view
            </Button>
          )}
          <CustomView
            columns={Object.keys(renderColumns).map((a) => ({
              key: a,
              title: renderColumns[a].title || a,
            }))}
          />
        </>
      )}

      <Title>Customers</Title>
      <div className="mt-4" />

      <CustomersListFilters value={advancedQuery} onChange={setAdvancedQuery} />

      <Table
        page={
          1 +
          Math.ceil(
            (advancedQuery?.options?.offset || 0) /
              (advancedQuery?.options?.limit || 1)
          )
        }
        total={customers.total || 0}
        showPagination={true}
        initialPagination={{
          page: 1,
          perPage: advancedQuery.options.limit ?? 10,
          orderBy: columns.indexOf(advancedQuery.options.order_by as string),
          order: advancedQuery.options.order_way,
        }}
        data={customers.data || []}
        columns={columns.map(
          (col) =>
            renderColumns[col] || {
              title: col,
              orderable: true,
              className: "text-right",
              headClassName: "justify-end",
              thClassName: "w-24",
              render: (row: CustomerSearchType) => _.get(row, col),
            }
        )}
        onRequestData={async ({ page, perPage, orderBy, order }) => {
          setAdvancedQuery({
            ...advancedQuery,
            options: {
              ...advancedQuery.options,
              offset: (page - 1) * perPage,
              limit: perPage,
              order_by: columns[orderBy!] || "full_names",
              order_way: order ?? "ASC",
            },
          });
        }}
        loading={loading}
        options={{
          exportCsv:
            // Any "supervisor" role can export CSV
            hasAccess("RISK_SETTINGS_WRITE") ||
            hasAccess("PRESS_MANAGE") ||
            hasAccess("KYT_MANAGE") ||
            hasAccess("SCENARIO_UPDATE") ||
            hasAccess("POST_FILTERING_UPDATE")
              ? {
                  fetchData: async (params: Pagination) => {
                    const result = await fetchCustomers({
                      ...advancedQuery.options,
                      offset: (params.page - 1) * params.perPage,
                      limit: params.perPage,
                      order_by: columns[params.orderBy!],
                      order_way: params.order ?? "ASC",
                    });

                    return result?.data || { data: [], total: 0 };
                  },
                }
              : {},
        }}
      />
    </Page>
  );
};
