import { IdentityAnalysisApiClient } from "../api-client/identity-analysis";
import { useMutation } from 'react-query';
import type { IdentityAnalysisPayload } from '../type';
import toast from 'react-hot-toast';

export const useIdentityAnalysis = () => {
  const FetchIdentityAnalysis = (postIdentityAnalysis: IdentityAnalysisPayload) => useMutation({
    mutationFn: async () => await IdentityAnalysisApiClient.postIdentityAnalysis(postIdentityAnalysis),
    onError(err: any) {
      console.log({ err })
      toast.error(err.message)
    },
  })

  return {
    fetchIdentityAnalysis: FetchIdentityAnalysis
  };
};
