import countries from "@assets/countries.json";
import Avatar from "@atoms/avatar/avatar";
import { Tag } from "@atoms/badge/tag";
import { PageBlock } from "@atoms/layout/page-block";
import { Base, Info, InfoSmall, Section } from "@atoms/text";
import { buildQueryFromMap } from "@components/search-bar/utils/utils";
import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";
import { useCustomerStates } from "@features/customers/state/user-customer-states";
import { CustomerAllDetailType } from "@features/customers/types";
import {
  CUSTOMERS_RISKS_BACKEND_COLORS,
  CUSTOMERS_RISKS_BACKEND_LABEL,
  extractCustomerFullName,
  STATES_COLORS,
} from "@features/customers/utils";
import { useDashboard } from "@features/monitoring/state/use-dashboard";
import { useSessions } from "@features/sessions/state/use-sessions";
import { SessionCustomerAvatar, SessionStateIndex } from "@features/sessions/types";
import { useControlledEffect } from "@features/utils";
import { formatTime } from "@features/utils/dates";
import { BuildingOfficeIcon, UserIcon } from "@heroicons/react/24/outline";
import { useCallback, useState } from "react";
import { twMerge } from "tailwind-merge";
import { formatFieldValue } from "./custom-fields";
import { useKycLevel } from "./olkypass";

export default function CustomerSummary({
  customer,
}: {
  customer: CustomerAllDetailType;
}) {
  const customFields = customer?.details.custom_fields ?? [];
  const customerDetails = customer.details.customer;
  const { risk } = useDashboard();
  const { states } = useCustomerStates();
  const { fields } = useCustomFields();
  const { fetchAvatarSessionWithExternalID } = useSessions();
  const [avatar, setAvatar] = useState<SessionCustomerAvatar>();
  const [loadingAvatar, setLoadingAvatar] = useState<boolean>(true);

  const findLatestSessionWithFace = useCallback(async () => {
    const avatar = await fetchAvatarSessionWithExternalID({
      query: buildQueryFromMap({
        external_id: customer?.details.customer.external_id,
        state: SessionStateIndex.Completed
      }),
      options: { limit: 10, order_by: "date", order_way: "DESC" },
    });
    setAvatar(avatar);
    setLoadingAvatar(false);
  }, [customer, fetchAvatarSessionWithExternalID]);

  useControlledEffect(() => {
    findLatestSessionWithFace();
    setLoadingAvatar(true);
  }, [customer]);

  const lastRiskDate = Math.max(
    // Global scan end-date
    new Date(risk?.last_risk_scoring_info?.end_date || 0).getTime(),
    // Customer last updated
    new Date(customerDetails.created_at || 0).getTime()
  );

  const kycLevel = useKycLevel(
    customer?.details?.customer?.id,
    customer?.details?.customer?.kyc_level
  );

  return (
    <>
      <Section className="mt-4">Customer summary</Section>
      <div className="mt-4 flex flex-row">
        <PageBlock className="w-1/2 grow">
          <div className="flex w-full flex-row items-center py-2">
            <Avatar
              size={24}
              className="mr-4 shrink-0"
              key={`profile_picture_${customerDetails.external_id}`}
              avatar={avatar?.url}
              type={avatar?.type}
              loading={loadingAvatar}
            />
            <div className="grow">
              <Section className="flex flex-row items-center">
                {customerDetails.customer_type !== "3" ? (
                  <UserIcon className="mr-2 inline h-5 w-5 shrink-0" />
                ) : (
                  <BuildingOfficeIcon className="mr-2 inline h-5 w-5 shrink-0" />
                )}
                {extractCustomerFullName(customerDetails)}
              </Section>

              <div className="-mt-1">
                <Base className="mr-4">
                  <Info>ID</Info> {customerDetails?.external_id}
                </Base>
                {customerDetails?.registration_number && (
                  <Base className="mr-4">
                    <Info>Registration Number</Info>{" "}
                    {customerDetails?.registration_number}
                  </Base>
                )}
              </div>

              {customerDetails?.date_of_birth && (
                <Base className="mr-4">
                  <Info>Date of birth</Info>{" "}
                  {(customerDetails?.date_of_birth
                    ? new Date(customerDetails?.date_of_birth)
                        .toISOString()
                        .split("T")[0]
                    : null) || "Not defined"}
                </Base>
              )}
              {customerDetails?.nationality_codes.length > 0 && (
                <Base className="mr-4">
                  <Info>{"Nationality"}</Info>{" "}
                  {customerDetails?.nationality_codes.map((code) => (
                    <span key={code}>
                      {countries.find((c) => c["alpha-3"] === code)?.name ||
                        "Invalid"}{" "}
                      <Info>({code})</Info>{" "}
                    </span>
                  ))}
                </Base>
              )}
              {customerDetails?.domicile_codes.length > 0 && (
                <Base className="mr-4">
                  <Info>{"Domicile"}</Info>{" "}
                  {customerDetails?.domicile_codes.map((code) => (
                    <span key={code}>
                      {countries.find((c) => c["alpha-3"] === code)?.name ||
                        "Invalid"}{" "}
                      <Info>({code})</Info>{" "}
                    </span>
                  ))}
                </Base>
              )}

              <div>
                {customFields
                  .filter((cf) =>
                    fields
                      .filter((f) => f.field_id === cf.id)
                      .map((f) => f.format.related_products)
                      .flat()
                      .includes("header")
                  )
                  .map((f) => (
                    <Base key={f.id} className="mr-4">
                      <Info>
                        {fields.find((a) => a.field_id === f.id)?.header_name}
                      </Info>{" "}
                      {formatFieldValue(
                        f.value,
                        fields.find((a) => a.field_id === f.id)?.format
                      )}
                    </Base>
                  ))}
              </div>
            </div>
            <div className={twMerge("text-left", "w-2/5")}>
              <div className="flex flex-row space-x-px">
                <StatusCard
                  label="Level"
                  className="rounded-r-none"
                  color={kycLevel.color}
                  status={kycLevel.state}
                  updatedAt={kycLevel.updated}
                />
                <StatusCard
                  color={
                    STATES_COLORS[
                      customerDetails.disabled
                        ? "disabled"
                        : `${customer.details.edd_status.edd_state}`
                    ] || "slate-500"
                  }
                  label="Status"
                  status={
                    states[customer.details.edd_status.edd_state] || "Unknown"
                  }
                  updatedAt={new Date(
                    customer.details.edd_status.created_at || 0
                  ).getTime()}
                  className={twMerge("rounded-none")}
                />
                <StatusCard
                  color={
                    CUSTOMERS_RISKS_BACKEND_COLORS[
                      `${customer.details.overall_risk}`
                    ] || "slate-500"
                  }
                  label="Risk"
                  status={
                    CUSTOMERS_RISKS_BACKEND_LABEL[
                      customer.details.overall_risk
                    ] || "Undefined"
                  }
                  updatedAt={new Date(lastRiskDate).getTime() || 0}
                  className="rounded-l-none"
                />
              </div>
              <div className="mt-2 block space-x-1">
                <div className="inline-block">
                  <InfoSmall>
                    Customer created{" "}
                    <b>
                      {formatTime(
                        customer.edd_history.edd_revisions[
                          customer.edd_history.edd_revisions.length - 1
                        ]?.created_at || customer?.details?.customer?.created_at
                      )}
                    </b>
                  </InfoSmall>
                </div>
                <div className="inline-block">
                  <InfoSmall>• Review groups</InfoSmall>{" "}
                  {customer?.details?.customer?.review_groups?.map(
                    (group, i) => (
                      <Tag key={i} className="mr-1">
                        {group}
                      </Tag>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </PageBlock>
      </div>
    </>
  );
}

const StatusCard = ({
  updatedAt,
  color,
  status,
  label,
  className,
}: {
  updatedAt: number;
  color: string;
  label: string;
  status: string;
  className?: string;
}) => {
  return (
    <div
      className={twMerge(
        "rounded",
        "grid grow grid-cols-1 grid-rows-2 items-center gap-y-1 p-2",
        "bg-" + color + "/10",
        className
      )}
    >
      <div className="flex items-center justify-between">
        <Tag
          noColor
          className={twMerge("text-md !px-3 !py-1 text-white", "bg-" + color)}
        >
          <InfoSmall noColor>{label}</InfoSmall>{" "}
          <span className="capitalize">{status}</span>
        </Tag>
      </div>
      <InfoSmall data-tooltip={new Date(updatedAt).toLocaleString()}>
        Updated{" "}
        {new Date(updatedAt).getTime() > 0
          ? new Date(updatedAt).toISOString().split("T")[0]
          : "-"}
      </InfoSmall>
    </div>
  );
};
