import { getPeriodEnd } from "@features/utils/dates";
import { flattenKeys } from "@features/utils/flatten";
import {
  MatchedStringFilter,
  OutputQuery,
  RestSearchQuery,
  RestSearchQueryOp,
  SearchField,
} from "./types";
import _ from "lodash";

export const schemaToSearchFields = (
  schema: any,
  translations: {
    [key: string]: string | { label: string; keywords: string };
  } = {}
) => {
  return Object.entries(flattenKeys(schema)).map(([key, value]) => {
    key = key.replace(/\[0\]$/, "");
    const tr =
      typeof translations[key] === "string"
        ? { label: translations[key], keywords: translations[key] }
        : (translations[key] as any);
    return {
      key,
      label: tr?.label || key,
      keywords: [...(tr?.keywords || "").split(" "), key, tr?.label]
        .filter((a) => a)
        .map((a) =>
          a
            .toLocaleLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        ),
      type: value as SearchField["type"],
    };
  });
};

export const labelToVariable = (label: string) =>
  label
    .toLocaleLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^a-z0-9]/g, "_");

// Filters have this form: field:"value with spaces","value2","value3" or just field:value,value2
export const extractFilters = (str: string): MatchedStringFilter[] => {
  const filters =
    str.match(/(!?[^ :]+:~?([^" ]+|("[^"]*("|$),?)+[^" ]*)|[^ ]+)/gm) || [];
  return filters.map((filter) => {
    const parts = filter.match(
      /(([^ :]+):(~([^"]|$)|~?[^~" ]+|(~?"[^"]*("|$),?)+[^" ]*)?)/
    );
    if (!parts)
      return {
        key: "",
        not: false,
        regex: false,
        raw: filter,
        values: [],
        range: [],
        values_raw: "",
        values_raw_array: [],
      };
    const key = parts[2];
    const values =
      (parts[3] || "").match(/(~([^"]|$)|~?"[^"]*("|$)|[^,]+)/g) || [];
    return {
      key: key.replace(/^!/, ""),
      not: key.startsWith("!"),
      regex: (parts[3] || "").startsWith("~"),
      raw: filter,
      values_raw: parts[3] || "",
      range: [],
      values: values
        .map((value) => value.replace(/^~?"(.*?)("|$)$/g, "$1"))
        .filter(Boolean),
      values_raw_array: [
        ...values,
        ...((parts[3] || "").match(/,$/) ? [""] : []),
      ],
    };
  });
};

const buildOperation = (
  op: RestSearchQueryOp,
  value: Partial<Record<"boolean" | "number" | "range" | "string", any>>
) => {
  return {
    op: op,
    value: value,
  };
};

export const generateQuery = (
  fields: SearchField[],
  filters: MatchedStringFilter[],
  replacementsMap?: { [key: string]: string }
): OutputQuery => {
  const query = filters
    .filter((a) => !a.key)
    .map((a) => a.raw)
    .join(" ");

  let valid = true;
  const result = [
    ...(query || "")
      .replace(/-/gm, " ")
      .split(" ")
      .filter((a) => a.trim())
      .map((q) => ({
        key: "search_aggregate",
        operations: [
          buildOperation("regexp", {
            string: q, // For - in external_id
          }), // return Operation{} in Operations[]
        ],
      })),
    ...filters
      .filter((a) => a.key)
      .map((a) => {
        const field = fields.find((b) => labelToVariable(b.label) === a.key);
        return {
          key: field?.key || a.key,
          not: a.not,
          regex: a.regex,
          operations: a.values.map((value) => {
            value =
              replacementsMap?.[(field?.key || a.key) + ":" + value] || value;

            if (field?.type === "text" || field?.type?.indexOf("type:") === 0) {
              const isRegex = a.regex;
              value = value.replace(/(^~?"|"$)/g, "");
              return buildOperation(isRegex ? "regexp" : "equals", {
                string: value,
              });
            } else if (field?.type === "boolean") {
              return buildOperation("equals", {
                boolean: value === "1",
              });
            } else if (field?.type === "number" || field?.type === "date") {
              // If only a value without anything else and it is a date, we automatically apply a range
              if (
                value.match(/^[0-9]/) &&
                value.indexOf("->") < 0 &&
                field?.type === "date"
              ) {
                value = `${value}->${value}`;
              }
              let [min, max] = value.split("->") as [
                string | number | Date | null,
                string | number | Date | null
              ];
              if (field?.type === "date") {
                min = min ? new Date(min) : null;
                // For max we apply a special treatment to *include* it
                max = max ? new Date(getPeriodEnd(max as string)) : null;
              } else {
                min = min
                  ? parseFloat(min?.toString()?.replace(/[<>=]/gm, ""))
                  : null;
                max = max
                  ? parseFloat(max?.toString()?.replace(/[<>=]/gm, ""))
                  : null;
                if (isNaN(min as number)) min = null;
                if (isNaN(max as number)) max = null;
              }
              if (value.startsWith(">=")) {
                return buildOperation("range", {
                  [field?.type === "date" ? "range_date" : "range_number"]: {
                    gte: min,
                  },
                });
              }
              if (value.startsWith("<=")) {
                return buildOperation("range", {
                  [field?.type === "date" ? "range_date" : "range_number"]: {
                    lte: min,
                  },
                });
              }
              if (value.includes("->")) {
                return buildOperation("range", {
                  [field?.type === "date" ? "range_date" : "range_number"]: {
                    gte: min,
                    lte: max,
                  },
                });
              }
              return buildOperation("equals", { number: min });
            } else {
              valid = false;
            }
            return buildOperation("equals", { string: value });
          }),
        };
      }),
  ];

  return {
    valid,
    fields: result,
  };
};

/**
 * Transform a map to the correct query format
 * @param map
 * @returns
 */
export const buildQueryFromMap = (
  map: { [key: string]: any },
  queryOp = "equals" as RestSearchQueryOp
) => {
  return Object.keys(_.omitBy(map, (v) => v === undefined))
    .filter(
      (k) => map[k] !== undefined && !(_.isArray(map[k]) && map[k].length === 0)
    )
    .map(
      (key) =>
        ({
          key,
          operations: (
            (_.isArray(map[key]) ? map[key] : [map[key]]) as any[]
          ).map((v: any) => ({
            op: queryOp,
            value:
              typeof v === "object"
                ? v
                : {
                    [typeof v === "boolean"
                      ? "boolean"
                      : typeof v === "string"
                      ? "string"
                      : typeof v === "number"
                      ? "number"
                      : ""]: v,
                  },
          })),
        } as RestSearchQuery)
    );
};

export const convertStrToDate = (str: string) => {
  let value: string = str.replace(/[^0-9-]/g, "");
  const onlyNumbers: string = str.replace(/[\D]/g, "");

  if (onlyNumbers.length == 5) {
    value = `${onlyNumbers.slice(0, 4)}-${onlyNumbers.slice(4)}`;
  }
  if (onlyNumbers.length == 7) {
    value = `${onlyNumbers.slice(0, 4)}-${onlyNumbers.slice(
      4,
      6
    )}-${onlyNumbers.slice(6)}`;
  }
  if (onlyNumbers.length == 8) {
    value = `${onlyNumbers.slice(0, 4)}-${onlyNumbers.slice(
      4,
      6
    )}-${onlyNumbers.slice(6, 8)}`;
  }

  const [newDay, newMonth] = [onlyNumbers.slice(6, 8), onlyNumbers.slice(4, 6)];
  const newYear = onlyNumbers.slice(0, 4);
  const newDate = new Date();
  if (newDay.length === 2) {
    newDate.setDate(parseInt(newDay));
  }
  if (newMonth.length === 2) {
    newDate.setMonth(parseInt(newMonth) - 1);
  }
  if (newYear.length === 4) {
    let year = parseInt(newYear);
    const currentYear = new Date().getFullYear();
    if (currentYear - year > 150) {
      year = currentYear - 150;
    } else if (currentYear - year < -150) {
      year = currentYear + 150;
    }
    newDate.setFullYear(year);
  }
  if (onlyNumbers.length >= 8) {
    return newDate.toISOString().slice(0, 10);
  }
  return value;
};
