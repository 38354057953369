import { Badge } from "@atoms/badge";
import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import { Info, Title } from "@atoms/text";
import { AlertCategories } from "@components/alert-categories";
import { AssigneeTag } from "@components/assignations/tag";
import { UpdateMultipleAlertsModalAtom } from "@components/bulk-alert-status";
import { Table } from "@components/table";
import { AlertsFiltersAtom } from "@features/alerts/state/store";
import { useAlertStates } from "@features/alerts/state/use-alert-states";
import { useAlerts } from "@features/alerts/state/use-alerts";
import { extractCustomerAlertFullName } from "@features/alerts/utils";
import { useAssignments } from "@features/assignments/state/use-assignment";
import { useHasAccess } from "@features/auth/state/use-access";
import { MonitoringScanStateEnum } from "@features/monitoring/enums";
import { useDashboard } from "@features/monitoring/state/use-dashboard";
import { usePendingTasks } from "@features/monitoring/state/use-pending-tasks";
import { ROUTES } from "@features/routes";
import { formatTime } from "@features/utils/dates";
import {
  BuildingOfficeIcon,
  PencilSquareIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { AlertsListFilters } from "./filters";

export const AlertsListPage = () => {
  const { scan, refresh: refreshDashboard } = useDashboard();
  const hasAccess = useHasAccess();
  const { pendingScanTasks } = usePendingTasks();

  const { states } = useAlertStates();
  const setOpen = useSetRecoilState(UpdateMultipleAlertsModalAtom);

  const [searchBar, setSearchBar] = useRecoilState(AlertsFiltersAtom);

  const { alerts, loading, refresh } = useAlerts(searchBar);
  useAssignments("alert", alerts?.data?.map((a) => a?.alert_id) || []);

  useEffect(() => {
    refreshDashboard();
  }, []);

  const columns = ["EXTERNAL-ID", "SCORE", "LAST-UPDATE", "NAME", "", "STATUS"];

  return (
    <Page>
      <Title className="flex-row flex space-x-2 items-center">
        <div className="flex-row flex space-x-2">Alerts</div>
        <div className="flex-row flex space-x-2">
          <Badge>{pendingScanTasks?.tasks_count || 0} new</Badge>{" "}
          {((scan?.global_scan_info || []).find(
            (a) => a.alert_state === MonitoringScanStateEnum.PENDING
          )?.value || 0) > 0 && (
            <Badge className="bg-yellow-500">
              {(scan?.global_scan_info || []).find(
                (a) => a.alert_state === MonitoringScanStateEnum.PENDING
              )?.value || 0}{" "}
              pending
            </Badge>
          )}
        </div>
      </Title>
      <div className="mt-4" />
      <AlertsListFilters
        onChange={(e) => {
          setSearchBar((s) => ({
            query: e.query,
            options: { ...s.options, offset: 0 },
          }));
        }}
      />
      <Table
        rowIndex="alert_id"
        onSelect={
          hasAccess("ALERT_CASEMANAGEMENT")
            ? [
                {
                  icon: (p) => <PencilSquareIcon {...p} />,
                  label: "Update status",
                  callback: async (rows) => {
                    setOpen({
                      open: true,
                      alerts: rows,
                      onFinish: () => {
                        refresh();
                      },
                    });
                  },
                },
              ]
            : undefined
        }
        loading={loading}
        showPagination={true}
        total={alerts?.total || 0}
        data={alerts?.data || []}
        page={
          1 +
          Math.ceil(
            (searchBar?.options?.offset || 0) / (searchBar?.options?.limit || 1)
          )
        }
        initialPagination={{
          page: 1,
          perPage: searchBar.options.limit ?? 10,
          order: searchBar.options.order_way ?? "DESC",
          orderBy: columns.indexOf(searchBar.options.order_by ?? "LAST-UPDATE"),
        }}
        columns={[
          {
            title: "#",
            className: "overflow-hidden text-ellipsis",
            thClassName: "w-40",
            orderable: true,
            render: (row) => <Info>{row.customer_external_customer_id}</Info>,
          },
          {
            title: "Match",
            className: "overflow-hidden text-ellipsis text-right justify-end",
            thClassName: "w-20",
            orderable: true,
            render: (row) => <Info>{row.score}%</Info>,
          },
          {
            title: "Date",
            className: "overflow-hidden text-ellipsis text-right justify-end",
            thClassName: "w-40",
            orderable: true,
            render: (row) => <Info>{formatTime(row.updated_at)}</Info>,
          },
          {
            title: "Name",
            orderable: true,
            render: (row) => (
              <div className="flex items-center">
                {!!(row.customer_trading_name || row.customer_company_name) && (
                  <BuildingOfficeIcon className="h-5 w-5 inline mr-2 shrink-0" />
                )}
                {!(row.customer_trading_name || row.customer_company_name) && (
                  <UserIcon className="h-5 w-5 inline mr-2 shrink-0" />
                )}
                {extractCustomerAlertFullName(row)}
              </div>
            ),
          },
          {
            title: "Candidate",
            orderable: true,
            render: (row) => (
              <div className="flex items-center">
                {row.profile_name_summary}
              </div>
            ),
          },
          {
            title: "Status",
            orderable: true,
            thClassName: "w-40",
            render: (row) => (
              <div className="flex items-center flex-row capitalize whitespace-nowrap">
                {states[parseInt(row.state)] === "new" && (
                  <span className="inline-flex h-3 w-3 relative mr-2">
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-orange-400"></span>
                  </span>
                )}
                {states[parseInt(row.state)] || "Unknown"}
              </div>
            ),
          },
          {
            title: "Category",
            thClassName: "w-64",
            render: (row) => <AlertCategories isCategories={row as any} />,
          },
          {
            title: "",
            className: "text-right",
            headClassName: "justify-end",
            thClassName: "w-24",
            render: (row) => (
              <>
                <AssigneeTag type="alert" id={row.alert_id} />
              </>
            ),
          },
          {
            title: "Actions",
            className: "text-right",
            headClassName: "justify-end",
            thClassName: "w-24",
            render: (row) => (
              <Link to={ROUTES.AlertView.replace(":id", row.alert_id)}>
                <Button size="sm" theme="outlined">
                  View
                </Button>
              </Link>
            ),
          },
        ]}
        onRequestData={async ({ page, perPage, order, orderBy }) => {
          setSearchBar((s) => ({
            ...s,
            options: {
              ...s.options,
              offset: (page - 1) * perPage,
              limit: perPage,
              order_by: columns[orderBy || 0],
              order_way: order || "ASC",
            },
          }));
        }}
      />
    </Page>
  );
};
