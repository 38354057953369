import { Tag } from "@atoms/badge/tag";
import Link from "@atoms/link";
import Select from "@atoms/select";
import { BaseSmall, Info } from "@atoms/text";
import Assignments from "@components/assignations";
import { useHasAccess } from "@features/auth/state/use-access";
import { extractCustomerFullName } from "@features/customers/utils";
import { useThread } from "@features/inbox/state/use-thread";
import { InboxThreadStatus } from "@features/inbox/types";
import {
  INBOX_STATUS_COLORS,
  INBOX_STATUS_LABELS,
} from "@features/inbox/utils";
import { useMessagesLabels } from "@features/messages-labels/state/use-messages-labels";
import { ROUTES } from "@features/routes";
import { stringToColor } from "@features/utils";
import { formatTime } from "@features/utils/dates";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import _ from "lodash";
import { useContext } from "react";
import toast from "react-hot-toast";
import { useSetRecoilState } from "recoil";
import { AttachmentsModalAtom } from "../components/attachments-modal";
import { InboxModalContext } from "../modal";
import { useSearchParams } from "react-router-dom";

export const ThreadHeader = ({
  threadId,
  onEditThread,
}: {
  threadId: string;
  onEditThread: () => void;
}) => {
  const { labels: tags } = useMessagesLabels();
  const [searchParams, setSearchParams] = useSearchParams();
  const { thread, update } = useThread(threadId);
  const { inModal } = useContext(InboxModalContext);
  const openAttachmentsModal = useSetRecoilState(AttachmentsModalAtom);
  const hasAccess = useHasAccess();

  if (!thread) return <></>;

  const isClosed = thread.status === InboxThreadStatus.CLOSED;

  return (
    <>
      <div
        className={
          "flex flex-col border-b dark:border-slate-700 px-3 w-full " +
          (inModal ? "pr-14" : "")
        }
      >
        <div className="h-8 flex flex-row items-center mt-4 w-full z-10">
          <div className="mr-2 relative flex flex-row items-center">
            <Tag
              noColor
              className={
                "h-8 relative pt-1.5 px-2 bg-" +
                INBOX_STATUS_COLORS[thread.status] +
                " text-white"
              }
            >
              {INBOX_STATUS_LABELS[thread.status]}
            </Tag>
          </div>

          {hasAccess("CHAT_AGENT") && (
            <div className="mr-2">
              <Select
                size="sm"
                className="w-32"
                onChange={async (e) => {
                  const status = parseInt(e.target.value);
                  e.target.selectedIndex = 0;
                  await update({ threadId: thread.id, input: { status } });
                  toast.success("Thread updated");
                }}
              >
                <option disabled selected>
                  Change to
                </option>
                <option
                  value={InboxThreadStatus.OPEN}
                  disabled={thread.status === InboxThreadStatus.OPEN}
                >
                  Open
                </option>
                <option
                  value={InboxThreadStatus.REMINDER}
                  disabled={thread.status === InboxThreadStatus.REMINDER}
                >
                  Reminder
                </option>
                <option
                  value={InboxThreadStatus.WAITING}
                  disabled={thread.status === InboxThreadStatus.WAITING}
                >
                  Waiting
                </option>
                <option
                  value={InboxThreadStatus.CLOSED}
                  disabled={thread.status === InboxThreadStatus.CLOSED}
                >
                  Closed
                </option>
              </Select>
            </div>
          )}
          <Info className="block grow text-ellipsis overflow-hidden whitespace-nowrap">
            Created{" "}
            {formatTime(thread.created_at, undefined, {
              keepDate: true,
            })}
            {" - "}
            {thread.message_count} item{thread.message_count > 1 ? "s" : ""}
            {" - "}
            Review groups {(thread.review_groups || []).join(", ") || "(none)"}
          </Info>
          <div className="shrink-0">
            <Assignments
              key={thread.id}
              inline
              type="thread"
              id={thread.id}
              groups={thread.review_groups ?? []}
              readonly={!hasAccess("CHAT_AGENT") || isClosed}
            />
          </div>
        </div>
        <div className="h-6 mt-2 flex flex-row items-center">
          {!thread.attachments?.length && (
            <Tag className="opacity-25">No attachments</Tag>
          )}
          {thread.attachments?.length && (
            <>
              <Tag ellipsis>
                {thread.attachments?.length || 0} attachment
                {thread.attachments?.length > 1 ? "s" : ""}
              </Tag>
              {hasAccess("CHAT_AGENT") && (
                <BaseSmall className="ml-2">
                  <Link
                    onClick={() =>
                      openAttachmentsModal({
                        attachments: thread.attachments,
                        threadId: threadId,
                      })
                    }
                  >
                    Open
                  </Link>
                </BaseSmall>
              )}
            </>
          )}
          <BaseSmall className="mx-2">-</BaseSmall>
          {thread.tags?.length && <BaseSmall className="mr-2">Tags </BaseSmall>}
          {!thread.tags?.length && <Tag className="opacity-25">No tags</Tag>}
          {(thread.tags || [])?.map((tagCode) => {
            const tag = tags.find((t) => t.code === tagCode);
            if (!tag) return null;
            return (
              <Tag
                ellipsis
                key={tag.code}
                noColor
                className="mr-1 capitalize"
                style={{ backgroundColor: stringToColor(tag.code) }}
              >
                {tag.name}
              </Tag>
            );
          })}
          {hasAccess("CHAT_AGENT") && (
            <BaseSmall>
              <Link className="ml-1" onClick={() => onEditThread()}>
                Edit tags
              </Link>
            </BaseSmall>
          )}
        </div>
        <div className="h-8 flex flex-row items-center w-full">
          <BaseSmall className="flex overflow-hidden text-ellipsis whitespace-nowrap gap-1 ">
            Discussion with
            {(thread.customers || []).length === 0
              ? "nobody"
              : (thread.customers || []).map((customer, i) => {
                  const name =
                    _.capitalize(extractCustomerFullName(customer)) +
                    (thread.customers.length < 2 &&
                      " (" + customer.external_id + ")");
                  return (
                    <div
                      key={customer.id}
                      className="flex flex-row items-center gap-x-2 bg-slate-200 rounded-md px-2"
                    >
                      {i > 0 && ", "}
                      <Link
                        className="text-black dark:text-white hover:text-blue-500"
                        target="_blank"
                        to={ROUTES.CustomerView.replace(":id", customer.id)}
                      >
                        {name}
                      </Link>
                      {hasAccess("CHAT_AGENT") && !isClosed && (
                        <>
                          <BaseSmall>
                            <MagnifyingGlassIcon
                              data-tooltip="Other threads with this customer"
                              className="h-4 w-4 cursor-pointer"
                              onClick={() => {
                                const updatedQ = `customer_ids:~"${customer.id}"`;
                                setSearchParams({
                                  ...Object.fromEntries(searchParams),
                                  q: updatedQ,
                                });
                              }}
                            />
                          </BaseSmall>
                        </>
                      )}
                    </div>
                  );
                })}
            <Link
              onClick={() => onEditThread()}
              className="text-black dark:text-white hover:text-blue-500 cursor-pointer"
            >
              Edit customers
            </Link>
          </BaseSmall>

          <div className="grow" />
        </div>
      </div>
    </>
  );
};
