import Avatar from "@atoms/avatar/avatar";
import { Button } from "@atoms/button/button";
import { Input } from "@atoms/input/input-text";
import { InfoSmall } from "@atoms/text";
import { useAuth } from "@features/auth/state/hooks";
import { useThread } from "@features/inbox/state/use-thread";
import {
  ArrowSmallUpIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import toast from "react-hot-toast";

export default function ThreadsCommentsInput(props: {
  threadId: string;
  onCreateMessage: () => void;
  disabled?: boolean;
}) {
  const [loadingComment, setLoadingComment] = useState(false);

  const { comment: commentThread } = useThread(props.threadId);
  const { user } = useAuth();
  const [comment, setComment] = useState("");

  return (
    <div className="flex flex-col p-2 space-x-2 ">
      <div className="flex flex-row p-2">
        <Avatar
          size={6}
          fallback={user?.name ?? "Agent"}
          className="mr-2 shrink-0 mt-1.5"
        />
        <Input
          multiline
          style={{ minHeight: 64 }}
          className="grow rounded-r-none"
          highlight
          disabled={loadingComment}
          value={comment}
          onChange={(e) => setComment(e.target.value ?? "")}
          placeholder="Type your comment..."
        />
        <Button
          size="md"
          className="shrink-0 rounded-l-none h-16"
          disabled={!comment.trim()?.length}
          loading={loadingComment}
          shortcut={["enter"]}
          onClick={async () => {
            setLoadingComment(true);
            const c = comment;
            setComment("");
            try {
              await commentThread(props.threadId, c);
              toast.success("Comment added");
            } catch (e) {
              toast.error("Error while adding comment");
              setComment(c);
            }
            setLoadingComment(false);
          }}
        >
          <ArrowSmallUpIcon className="w-4 h-4 -mx-1" />
        </Button>
        <Button
          size="md"
          className="shrink-0 ml-2"
          onClick={async () => {
            props.onCreateMessage();
          }}
        >
          <PencilSquareIcon className="w-4 h-4 mr-2 -ml-1" />
          New message
        </Button>
      </div>
      <InfoSmall className="pl-8">
        Your comment will <strong>only be visible</strong> to your team members
      </InfoSmall>
    </div>
  );
}
