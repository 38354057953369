import type {
  IdentityAnalysisPayload,
  IdentityAnalysisType,
} from "@features/vid/type";
import { PageBlock, PageBlockHr } from "@atoms/layout/page-block";
import IdentityDocumentDetails from "../../../../components/vid/document-details";
import { Controls } from "@components/vid/controls";
import { flatMapControls } from "@features/sessions/utils";
import IdentityDocuments from "@components/vid/identity-documents";
import FaceScore from "@components/vid/faces/score";
import _ from "lodash";

const IdentityAnalysisDetails = ({
  analysis,
  identityAnalysis,
}: {
  analysis: IdentityAnalysisPayload;
  identityAnalysis: IdentityAnalysisType;
}) => {
  console.log("ok");
  const extracted_info = identityAnalysis.extracted_info;
  const facematch_info = identityAnalysis.facematch_info;
  const similarityScore =
    facematch_info?.controls?.find((a) =>
      a.controls.find((e) => e.identifier === "face.matched.similarity")
    )?.score || (identityAnalysis.facematch_score || 0) / 100;

  return (
    <div className="gap-4 flex flex-col">
      <PageBlock>
        <Controls annotable={false} controls={extracted_info.controls} />
        <PageBlockHr />
        <IdentityDocuments
          type="base64"
          answers_attachment_urls={extracted_info.other_images.map(
            (element) => element.Data
          )}
        />
        <PageBlockHr />
        <IdentityDocumentDetails
          firstname={extracted_info.holder_detail.first_name}
          lastname={extracted_info.holder_detail.last_name}
          birth_date={extracted_info.holder_detail.birth_date}
          gender={extracted_info.holder_detail.gender}
          document_number={extracted_info.document_detail.document_number}
          nationality={extracted_info.holder_detail.nationality}
          emit_country={extracted_info.document_detail.emit_country}
          emit_date={extracted_info.document_detail.emit_date}
          expiration_date={extracted_info.document_detail.expiration_date}
          mrz={extracted_info.mrz}
        />
      </PageBlock>
      {(!_.isEmpty(facematch_info.controls) ||
        !_.isEmpty(identityAnalysis?.faceA)) && (
        <PageBlock>
          {!_.isEmpty(facematch_info.controls) && (
            <Controls
              title="Face controls"
              annotable={false}
              controls={flatMapControls(facematch_info.controls)}
            />
          )}
          {!_.isEmpty(identityAnalysis?.faceA) && (
            <>
              <PageBlockHr />
              <FaceScore
                type="base64"
                similarityScore={similarityScore}
                answers_attachment_urls={(
                  analysis?.base_64_encoded_face_images || []
                ).map((element) => element)}
                identity_faces={
                  "data:image/png;base64, " + identityAnalysis?.faceA
                }
              />
            </>
          )}
        </PageBlock>
      )}
    </div>
  );
};

export default IdentityAnalysisDetails;
