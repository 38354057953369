import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import Link from "@atoms/link";
import { LinkConfirm } from "@atoms/link/confirm";
import { ROUTES } from "@features/routes";
import { useSession } from "@features/sessions/state/use-session";
import { useEffect } from "react";
import { twMerge } from "tailwind-merge";

export const TextWithLinksAndHTML = (props: { text: string }) => {
  const dom = new DOMParser().parseFromString(props.text, "text/html");
  return (
    <TextWithLinksAndHTMLFromDom
      nodes={Array.from(dom.getElementsByTagName("body")[0].childNodes)}
    />
  );
};

export const TextWithLinksAndHTMLFromDom = (props: { nodes: ChildNode[] }) => {
  return (
    <>
      {props.nodes.map((node, i) => {
        if (node.nodeType === 1) {
          const children = TextWithLinksAndHTMLFromDom({
            nodes: Array.from(node.childNodes),
          });

          // Element node
          // Get tag name
          const tagName = (node as Element).tagName.toLowerCase();
          if (tagName === "br") {
            return <br key={i} />;
          } else if (tagName === "i" || tagName === "em") {
            return <i key={i}>{children}</i>;
          } else if (tagName === "b" || tagName === "strong") {
            return <b key={i}>{children}</b>;
          } else if (tagName === "p") {
            return <p key={i}>{children}</p>;
          } else if (tagName === "ul") {
            return (
              <ul key={i} className="list-decimal ml-4">
                {children}
              </ul>
            );
          } else if (tagName === "ol") {
            return (
              <ol key={i} className="list-disc ml-4">
                {children}
              </ol>
            );
          } else if (tagName === "li") {
            return <li key={i}>{children}</li>;
          } else if (tagName === "s") {
            return <s key={i}>{children}</s>;
          } else if (tagName === "u") {
            return <u key={i}>{children}</u>;
          } else if (tagName === "blockquote") {
            return (
              <blockquote key={i} className="border-l-4 border-slate-300 pl-2">
                {children}
              </blockquote>
            );
          }
        }
        return <TextWithLinks key={i} text={node.textContent || ""} />;
      })}
    </>
  );
};

export const TextWithLinks = (props: { text: string }) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const chunks = props.text.split(urlRegex).filter((a) => a); //Split text in list of type [str, link, str, link, str]
  return (
    <>
      {chunks.map((str, i) =>
        i % 2 === 0 ? str : <CustomLinkComponent key={i} url={str} />
      )}
    </>
  );
};

const CustomLinkComponent = (props: { url: string }) => {
  let sessionId = null;
  if (
    (sessionId = props.url.match(/https?:\/\/[^\s]+?\?session_id=([^\s&]+)/))
  ) {
    return <SessionLinkComponent sessionId={sessionId[1]} />;
  }
  return <LinkConfirm href={props.url}>{props.url}</LinkConfirm>;
};

const SessionLinkComponent = ({ sessionId }: { sessionId: string }) => {
  const { session, refresh } = useSession(sessionId);
  useEffect(() => {
    refresh();
  }, [refresh]);

  const lastDecision = session?.decisions?.[0];

  return (
    <Link to={ROUTES.SessionView.replace(":id", sessionId)}>
      <Button size="sm" theme="outlined">
        <Tag
          className={twMerge(
            "-ml-2 mr-2 text-white cursor-pointer",
            lastDecision
              ? lastDecision.type === "NEGATIVE"
                ? "bg-red-500"
                : lastDecision.type === "POSITIVE"
                ? "bg-green-500"
                : "bg-yellow-500"
              : "bg-slate-500"
          )}
          noColor
        >
          {session?.session.state?.toLocaleLowerCase() === "completed"
            ? lastDecision?.label
            : "in progress"}
        </Tag>
        Open session
      </Button>
    </Link>
  );
};
