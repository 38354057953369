import { AssignmentType } from "@features/assignments/types";
import { atomFamily } from "recoil";
import { InboxMessage, ThreadHistoryItemType } from "../types";

export const InboxThreadMessagesAtom = atomFamily<
  {
    data: InboxMessage[];
    total: number;
  },
  string
>({
  key: "InboxThreadMessagesAtom",
  default: { data: [], total: 0 },
});

export const InboxThreadHistoryItemsAtom = atomFamily<
  {
    data: ThreadHistoryItemType[];
    total: number;
  },
  string
>({
  key: "InboxThreadHistoryItemsAtom",
  default: { data: [], total: 0 },
});

export const InboxThreadHistoryAssignmentAtom = atomFamily<
  AssignmentType[],
  string
>({
  key: "InboxThreadHistoryAssignmentAtom",
  default: [],
});
