import { AssignmentType } from "@features/assignments/types";
import { delayRequest } from "@features/utils";
import { LoadingAtom } from "@features/utils/loading";
import _ from "lodash";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { InboxApiClient } from "../api-client/api-client";
import { InboxMessage, ThreadHistoryItemType } from "../types";
import {
  InboxThreadHistoryAssignmentAtom,
  InboxThreadHistoryItemsAtom,
  InboxThreadMessagesAtom,
} from "./store";

export const useThreadMessages = (threadId: string) => {
  const [messages, setMessages] = useRecoilState(
    InboxThreadMessagesAtom(threadId)
  );
  const [historyItems, setHistoryItems] = useRecoilState(
    InboxThreadHistoryItemsAtom(threadId)
  );
  const [assignmentHistory, setAssignmentHistory] = useRecoilState(
    InboxThreadHistoryAssignmentAtom(threadId)
  );
  const [loading, setLoading] = useRecoilState(
    LoadingAtom("useThreadMessages-" + threadId)
  );

  const refresh = useCallback(
    async (options?: { _silent: boolean }) => {
      if (!threadId) return;
      if (!options?._silent) setLoading(true);
      delayRequest("useThreadMessages" + threadId, async () => {
        const [msgs, status, assignments] = await Promise.all([
          InboxApiClient.getMessagesByThread(threadId),
          InboxApiClient.getHistoryByThread(threadId),
          InboxApiClient.getAssignmentHistory(threadId),
        ]);
        setMessages(msgs);
        setHistoryItems(status);
        setAssignmentHistory(assignments);
        if (!options?._silent) setLoading(false);
      });
    },
    [setLoading, threadId, setMessages, setHistoryItems, setAssignmentHistory]
  );

  const feed = _.sortBy(
    [
      ...messages.data.map((e) => ({ type: "message", data: e })),
      ...historyItems.data.map((e) => ({ type: "thread", data: e })),
      ...assignmentHistory.map((e) => ({ type: "assignment", data: e })),
    ] as (
      | { type: "message"; data: InboxMessage }
      | { type: "thread"; data: ThreadHistoryItemType }
      | { type: "assignment"; data: AssignmentType }
    )[],
    (e) => (e.data as any).updated_at || (e.data as InboxMessage).created_at
  ).reverse();

  return { feed, messages, loading, refresh };
};
