import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { RiskApiClient } from "./api-client/api-client";
import { RiskFactorElement, RiskResourceType } from "./types";
import { useHasAccess } from "@features/auth/state/use-access";

export const useRiskFactors = (type: RiskResourceType) => {
  const hasAccess = useHasAccess();
  const riskFactorsQuery = useQuery({
    queryKey: ["riskFactors", type],
    queryFn: async () =>
      hasAccess("RISK_SETTINGS")
        ? (await RiskApiClient.getRiskFactors(type)) || []
        : [],
    onError: (error) => {
      toast.error("Error fetching risk factors: " + error);
    },
    keepPreviousData: true,
  });

  const create = useMutation({
    mutationFn: async (riskElement: RiskFactorElement) => {
      await RiskApiClient.addRiskFactor(type, riskElement);
      return (await RiskApiClient.getRiskFactors(type)).find(
        (r) => r.label === riskElement.label
      );
    },
    onSuccess: () => {
      riskFactorsQuery.refetch();
    },
    onError: (error) => {
      toast.error("Error creating risk factor: " + error);
    },
  });

  return {
    riskFactors: riskFactorsQuery.data || [],
    totalWeight: (riskFactorsQuery.data || []).reduce(
      (acc, curr) => acc + (curr.use_weight ? curr.weight : 0),
      0
    ),
    refresh: riskFactorsQuery.refetch,
    loading: riskFactorsQuery.isLoading || create.isLoading,
    initiating: riskFactorsQuery.isLoading && !riskFactorsQuery.data,
    create: create.mutateAsync,
  };
};
