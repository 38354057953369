import { useDashboardTimeseries } from "@features/monitoring/state/use-dashboard";
import { formatTime } from "@features/utils/dates";
import _ from "lodash";
import { Line } from "react-chartjs-2";
import { commonProductsOptions, getMetric } from "./utils";
import { ALERTS_STATES_COLORS } from "@features/alerts/utils";

export const PastScan = () => {
  const { data } = useDashboardTimeseries();

  const labels = data.map((a) =>
    formatTime(a.date, "", {
      keepDate: false,
      keepSeconds: false,
      keepTime: false,
    })
  );

  const categories = [
    "new",
    "pending",
    "true_hit",
    "true_hit_accepted",
    "true_hit_rejected",
    "false_positive",
    "unknown",
  ];

  const colors = [
    ALERTS_STATES_COLORS[2],
    ALERTS_STATES_COLORS[3],
    ALERTS_STATES_COLORS[4],
    ALERTS_STATES_COLORS[5],
    ALERTS_STATES_COLORS[6],
    ALERTS_STATES_COLORS[7],
    "#AAAAAA",
  ];

  return (
    <div className="mt-2 flex sm:flex-row flex-col justify-between w-full h-72 space-y-4 sm:space-y-0 sm:space-x-4">
      <Line
        data={{
          labels,
          datasets: [
            ...categories.map((c, i) => ({
              label: _.upperFirst(c.replace(/_/g, " ")),
              data: data.map((a) =>
                getMetric(a, ("monitoringmetric_goscan_alerts_" + c) as any)
              ),
              borderColor: colors[i],
              backgroundColor: colors[i] + "EE",
              fill: true,
            })),
          ],
        }}
        options={commonProductsOptions as any}
      />
    </div>
  );
};
