import { Base, Section } from "@atoms/text";
import { useShortcuts } from "@features/shortcuts";
import { Transition } from "@headlessui/react";
import {
  ArrowsPointingOutIcon,
  MinusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ErrorBoundary } from "@views/error-boundary";
import _ from "lodash";
import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { atom, useRecoilState } from "recoil";
import { twMerge } from "tailwind-merge";

const MinimizedState = atom<string[]>({
  key: "ModalsMinimizedState",
  default: [],
});

const ModalsCountState = atom({
  key: "ModalsState",
  default: { open: 0 },
});
const visibleModals = { open: 0 };

let startedClickOnOutsideModal = false;

window.addEventListener("mousedown", () => {
  startedClickOnOutsideModal = false;
});

export const Modal = (props: {
  open?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  closable?: boolean;
  closableWithWarning?: boolean;
  className?: string;
  style?: any;
  positioned?: boolean;
  onMinimize?: (minimized: boolean) => void;
  minimized?: boolean;
  minimizedTitle?: string;
}) => {
  const [open, setOpen] = useState(false);
  const [minimized, setMinimized] = useState(props.minimized);
  const [modalsCountState, setModalsCountState] =
    useRecoilState(ModalsCountState);
  const [minimizedState, setMinimizedState] = useRecoilState(MinimizedState);
  const [level, setLevel] = useState({ open: 0 });
  const modalId = useRef(_.uniqueId("modal-"));

  // Use refs instead of state for dragging
  const isDragging = useRef(false);
  const position = useRef({ x: 0, y: 0 });
  const dragOffset = useRef({ x: 0, y: 0 });
  const modalRef = useRef<HTMLDivElement>(null);

  const handleOnCloseProps = useCallback(() => {
    if (props.closableWithWarning) {
      if (window.confirm("Are you sure you want to close this modal?")) {
        props.onClose && props.onClose();
      }
    } else if (props.closable !== false) {
      props.onClose && props.onClose();
    }
  }, [props.onClose, props.closable, props.closableWithWarning]);

  const onClose = useCallback(() => {
    visibleModals.open += -1;
    console.log("onClose", visibleModals);
    setModalsCountState(_.cloneDeep(visibleModals));
  }, [setModalsCountState]);

  const onOpen = useCallback(() => {
    visibleModals.open += 1;
    console.log("onOpen", visibleModals);
    setLevel(_.cloneDeep(visibleModals));
    setModalsCountState(_.cloneDeep(visibleModals));
  }, [setModalsCountState]);

  const onMinimize = useCallback(() => {
    setMinimizedState((minimizedState) => [
      ...minimizedState.filter((a) => a !== modalId.current),
      modalId.current,
    ]);
  }, [setMinimizedState]);

  const onMaximize = useCallback(() => {
    setMinimizedState((minimizedState) =>
      minimizedState.filter((a) => a !== modalId.current)
    );
  }, [setMinimizedState]);

  useEffect(() => {
    if (props.open !== open || props.minimized !== minimized) {
      setOpen(props.open || false);
      if (props.open && !props.minimized) {
        onOpen();
      } else {
        onClose();
      }
    }
  }, [props.open, open, props.minimized, minimized, onClose, onOpen]);

  useEffect(() => {
    if (props.minimized !== minimized) {
      setMinimized(props.minimized || false);
      if (props.minimized) {
        onMinimize();
      } else {
        onMaximize();
      }
    }
  }, [props.minimized, minimized, onMinimize, onMaximize]);

  // Drag functionality using refs
  const startDragging = (e: React.MouseEvent<HTMLDivElement>) => {
    if (
      modalRef.current &&
      e.target === modalRef.current.querySelector(".modal-header")
    ) {
      isDragging.current = true;
      const rect = modalRef.current.getBoundingClientRect();
      dragOffset.current = {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      };

      // Set initial position if not already set
      if (position.current.x === 0 && position.current.y === 0) {
        position.current = {
          x: rect.left,
          y: rect.top,
        };
      }

      // Set the modal's initial position using direct DOM manipulation
      if (modalRef.current) {
        modalRef.current.style.position = "fixed";
        modalRef.current.style.margin = "0";
        modalRef.current.style.transform = "none";
        modalRef.current.style.top = `${position.current.y}px`;
        modalRef.current.style.left = `${position.current.x}px`;
      }
    }
  };

  const handleDragging = useCallback((e: MouseEvent) => {
    if (isDragging.current && modalRef.current) {
      e.preventDefault();
      position.current = {
        x: e.clientX - dragOffset.current.x,
        y: e.clientY - dragOffset.current.y,
      };

      // Directly update DOM for smooth dragging
      modalRef.current.style.top = `${position.current.y}px`;
      modalRef.current.style.left = `${position.current.x}px`;
    }
  }, []);

  const stopDragging = useCallback(() => {
    isDragging.current = false;
  }, []);

  // Setup drag event listeners
  useEffect(() => {
    document.addEventListener("mousemove", handleDragging);
    document.addEventListener("mouseup", stopDragging);

    return () => {
      document.removeEventListener("mousemove", handleDragging);
      document.removeEventListener("mouseup", stopDragging);
    };
  }, [handleDragging, stopDragging]);

  const zIndex = "z-" + (level.open + 5) + "0";

  // Determine if the modal has been positioned
  const hasCustomPosition = useRef(false);
  if (position.current.x !== 0 || position.current.y !== 0) {
    hasCustomPosition.current = true;
  }

  useEffect(() => {
    if ((!props.open || props.minimized) && hasCustomPosition.current) {
      setTimeout(() => {
        dragOffset.current = { x: 0, y: 0 };
        position.current = { x: 0, y: 0 };
        hasCustomPosition.current = false;
      }, 400);
    }
  }, [props.open, props.minimized]);

  return createPortal(
    <Transition.Root show={open} as={"div"}>
      <div
        data-tooltip={props.minimizedTitle}
        onClick={() => props.onMinimize?.(false)}
        className={twMerge(
          "fixed -bottom-px right-4 z-50 p-2 bg-white flex space-x-4 shadow-xl items-center rounded-t-sm border w-full max-w-3xs hover:shadow-2xl hover:border-gray-300 dark:bg-slate-900 dark:border-slate-800 cursor-pointer transition-all ease-in-out duration-200"
        )}
        style={{
          bottom: props.minimized ? "0" : "-16rem",
          right:
            0.5 + (16 + 0.5) * minimizedState.indexOf(modalId.current) + "rem",
        }}
      >
        <Base className="block grow overflow-hidden whitespace-nowrap text-ellipsis">
          {props.minimizedTitle || "-"}
        </Base>
        <button type="button" title="Restore">
          <ArrowsPointingOutIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>

      <div
        className={twMerge("relative " + zIndex, props.minimized && "hidden")}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 pointer-events-none"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 pointer-events-none"
        >
          <div
            className={twMerge(
              "fixed inset-0 z-50 transition-opacity " +
                (level.open === 1
                  ? "bg-black/25 dark:bg-black/75"
                  : "bg-transparent"),
              open && "opacity-100 ease-out duration-300",
              !open && "opacity-0 pointer-events-none ease-in duration-200"
            )}
          />
        </Transition.Child>

        <div
          className={
            "fixed z-50 inset-0 overflow-y-auto transition-transform " +
            (level.open !== modalsCountState.open && open
              ? "-translate-y-6 sm:scale-95 "
              : level.open !== modalsCountState.open && !open
              ? "translate-y-6 sm:scale-95 "
              : "")
          }
          onMouseDown={(e) => {
            e.stopPropagation();
            startedClickOnOutsideModal = true;
          }}
          onMouseUp={() => {
            if (startedClickOnOutsideModal) {
              startedClickOnOutsideModal = false;
              handleOnCloseProps();
            }
          }}
        >
          <div
            className={
              "flex items-end justify-center min-h-screen text-center sm:block "
            }
          >
            {
              /* This element is to trick the browser into centering the modal contents. */
              !props.positioned && !hasCustomPosition.current && (
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
              )
            }
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom={
                "opacity-0 pointer-events-none sm:translate-y-0 translate-y-4 sm:scale-95"
              }
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo={
                "opacity-0 pointer-events-none sm:translate-y-0 translate-y-4 sm:scale-95"
              }
            >
              <div
                ref={modalRef}
                onClick={(e) => e.stopPropagation()}
                className={twMerge(
                  "align-bottom bg-white dark:bg-slate-900 px-4 pt-5 pb-4 text-left w-full sm:w-auto overflow-hidden shadow-xl transform sm:align-middle sm:p-6 " +
                    "relative inline-block rounded-tr-xl rounded-tl-xl sm:rounded-md sm:my-8 w-full sm:w-full sm:max-w-[600px] " +
                    (props.className || ""),
                  !isDragging && "transition-all"
                )}
                style={{
                  ...(props.style || {}),
                  ...(hasCustomPosition.current
                    ? {
                        position: "fixed",
                        top: position.current.y + "px",
                        left: position.current.x + "px",
                        transform: "none",
                        margin: 0,
                      }
                    : {}),
                }}
                onMouseDown={(e) => {
                  e.stopPropagation();
                  startedClickOnOutsideModal = false;
                  startDragging(e);
                }}
                onMouseUp={() => {
                  // Allow propagation but set startedClickOnOutsideModal just before
                  startedClickOnOutsideModal = false;
                }}
              >
                {/* Add a draggable header */}
                <div
                  className="modal-header h-12 w-full absolute top-0 left-0 cursor-move"
                  title="Drag to move"
                ></div>

                {level.open !== modalsCountState.open && (
                  <div className="absolute z-50 top-0 left-0 w-full h-full bg-white dark:bg-slate-900 opacity-75" />
                )}
                <div
                  className={
                    "absolute z-50 bg-black transition-all pointer-events-none " +
                    (level.open !== modalsCountState.open
                      ? "opacity-25"
                      : "opacity-0")
                  }
                  style={{
                    width: 1000000,
                    height: 1000000,
                    top: -5000,
                    left: -5000,
                  }}
                />
                <div className="z-20 absolute top-0 right-0 pt-4 pr-4 flex space-x-2">
                  {!!props.onMinimize && (
                    <button
                      type="button"
                      className="bg-slate-300 dark:bg-slate-600 rounded-full p-1 text-slate-600 dark:text-slate-300 hover:opacity-75 focus:outline-none "
                      onClick={() => props.onMinimize?.(true)}
                      title="Minimize"
                    >
                      <MinusIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  )}
                  {props.closable !== false && (
                    <button
                      type="button"
                      className="bg-slate-300 dark:bg-slate-600 rounded-full p-1 text-slate-600 dark:text-slate-300 hover:opacity-75 focus:outline-none "
                      onClick={() => handleOnCloseProps()}
                    >
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  )}
                  <CloseShortcut
                    onClose={handleOnCloseProps}
                    isTopMost={open && level.open === visibleModals.open}
                  />
                </div>
                <ErrorBoundary>{props.children}</ErrorBoundary>
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition.Root>,
    document.getElementById("root")!
  );
};

export const CloseShortcut = (props: {
  onClose?: () => void;
  isTopMost: boolean;
}) => {
  useShortcuts(["esc"], () => {
    props.onClose && props.isTopMost && props.onClose();
  });
  return <></>;
};

export const ModalContent = (props: {
  title?: string | ReactNode;
  text?: string;
  buttons?: ReactNode;
  children?: ReactNode;
  icon?: any;
  theme?: "success" | "danger" | "warning" | "gray";
}) => {
  let color = "indigo";
  if (props.theme === "success") color = "green";
  if (props.theme === "danger") color = "red";
  if (props.theme === "warning") color = "orange";
  if (props.theme === "gray") color = "gray";
  return (
    <>
      <div className="sm:flex sm:items-start">
        {props.icon && (
          <div
            className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-${color}-100 sm:mx-0 sm:h-10 sm:w-10`}
          >
            <props.icon
              className={`h-6 w-6 text-${color}-600`}
              aria-hidden="true"
            />
          </div>
        )}
        <div
          className={
            "mt-3 text-center sm:mt-0 sm:text-left " +
            (props.icon ? "sm:ml-4" : "")
          }
        >
          {props.title && (
            <h3 className="mb-4 -mt-2 text-xl font-semibold leading-6 font-medium text-gray-900 dark:text-white pr-6">
              <Section>{props.title}</Section>
            </h3>
          )}
          <div>
            <p className="text-sm text-gray-500 dark:text-white">
              {props.text || ""}
            </p>
          </div>
        </div>
      </div>
      {props.buttons && (
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse text-center sm:text-left">
          {props.buttons}
        </div>
      )}
      {props.children}
    </>
  );
};
