import { InboxApiClient } from "../api-client/api-client";
import { InboxThread, InboxThreadCreateInput } from "../types";

import { queryClient } from "@features/query";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { RestSearchRequest } from "../api-client/type";
import { useCallback, useEffect } from "react";
import { useAuth } from "@features/auth/state/hooks";
import { websocketBus } from "@features/websockets";
import _ from "lodash";
import { useAgents } from "@features/agents/state/use-agents";
import { SchemaSearchField } from "@components/search-bar/utils/types";
import { renameKYCLevelOnSearchField } from "@views/client/customers/customer-details/olkypass";

export const useThreads = (query?: RestSearchRequest) => {
  const { membersAuthList } = useAgents();
  const schema = useQuery({
    queryKey: ["threads-schema"],
    queryFn: membersAuthList && InboxApiClient.getThreadSearchSchema,
    select: useCallback((schema: SchemaSearchField[]) => renameKYCLevelOnSearchField(schema.map((element) => ({
      ...element,
      ...(element.external_key == "assignees" && {
        possible_values: membersAuthList.map((a) => ({
          label: a.name,
          value: a.id.toString(),
        }))
      })
    })))
      , [membersAuthList])
  });

  const threadsList = useQuery<{
    data: InboxThread[];
    total: number;
  } | null>({
    queryKey: ["threadsList", query],
    enabled: !!query,
    queryFn: async () => {
      console.log("called threadsList");
      const threadSearch = await InboxApiClient.getThreadsSearchAdapter(
        query?.query || [],
        query?.options || {}
      );

      return threadSearch;
    },
    cacheTime: 2000,
  });

  const create = useMutation({
    mutationFn: async (input: InboxThreadCreateInput) => {
      return await InboxApiClient.createThread(input);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["threadsList"] });
    },
    onError: (error) => {
      toast.error("Error on creating new thread");
      console.log(error);
    },
  });

  return {
    schema: schema.data || [],
    threads: threadsList?.data,
    create,
    loading: threadsList.isLoading || create.isLoading,
  };
};

export const useRealtimeThreads = () => {
  const { clientId } = useAuth();

  useEffect(() => {
    if (clientId) {
      const room = `client/${clientId}/threads`;
      websocketBus.emit("join", null, { room });
      websocketBus.on(room, (event: any) => {
        _.debounce(() => {
          queryClient.invalidateQueries({
            queryKey: ["inboxThread", event.data.thread_id],
          });
          queryClient.invalidateQueries({
            queryKey: ["threadsList"],
          });
        }, 1000)();
      });

      const room2 = `client/${clientId}/assignment`;
      websocketBus.emit("join", null, { room2 });
      websocketBus.on(room2, (event: any) => {
        queryClient.invalidateQueries({
          queryKey: ["inboxThread", event.data.id],
        });
      });
      return () => {
        websocketBus.emit("leave", null, { room });
        websocketBus.off(room);
        websocketBus.emit("leave", null, { room2 });
        websocketBus.off(room2);
      };
    }
  }, [clientId]);

  return {};
};

