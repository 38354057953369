import { XCircleIcon } from "@heroicons/react/24/outline";
import {
  useRef,
  useState,
  type ChangeEventHandler,
  type DragEventHandler,
  type MouseEventHandler,
} from "react";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";

export interface ImageInfo {
  file: File;
  url: string;
  base64: string;
}

interface DragDropImageProps {
  title: string;
  requestImages?: ImageInfo[];
  onImageUploaded: (imageInfo: ImageInfo[]) => void;
  multiple?: boolean;
  maxFiles?: number;
  className?: string;
}

export const DragDropImage: React.FC<DragDropImageProps> = ({
  title,
  requestImages,
  onImageUploaded,
  multiple = false,
  maxFiles = 5,
  className = "",
}) => {
  const [images, setImages] = useState<ImageInfo[]>(requestImages || []);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragOver: DragEventHandler = (e): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave: DragEventHandler = (e): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop: DragEventHandler = (e): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    if (
      e.dataTransfer &&
      e.dataTransfer.files &&
      e.dataTransfer.files.length > 0
    ) {
      const files = Array.from(e.dataTransfer.files);
      processFiles(files);
    }
  };

  const processFiles = (files: File[]): void => {
    const imageFiles = files.filter(
      (file) => file.type && file.type.match("image.*")
    );

    if (imageFiles.length === 0) {
      toast.error("Please select an image");
      return;
    }

    const filesToProcess = multiple
      ? imageFiles.slice(0, maxFiles - images.length)
      : [imageFiles[0]];
    if (multiple && imageFiles.length > maxFiles - images.length) {
      toast.error(`You cannot add more than ${maxFiles} images.`);
    }

    const newImages = multiple ? [...images] : [];

    filesToProcess.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target && e.target.result) {
          const base64String = e.target.result as string;
          const imageInfo = {
            file: file,
            url: base64String,
            base64: base64String.split(",")[1],
          };
          newImages.push(imageInfo);
          if (
            newImages.length ===
            filesToProcess.length + (multiple ? images.length : 0)
          ) {
            setImages(newImages.reverse());
            if (onImageUploaded) {
              onImageUploaded(newImages);
            }
          }
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleButtonClick: MouseEventHandler = (e): void => {
    e.stopPropagation();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = Array.from(e.target.files);
      processFiles(files);
    }
  };

  const handleRemoveImage =
    (index: number): MouseEventHandler<HTMLButtonElement> =>
    (e) => {
      e.stopPropagation();
      const newImages = [...images];
      newImages.splice(index, 1);
      setImages(newImages.reverse());
      if (onImageUploaded) {
        console.log("remove", newImages);
        return onImageUploaded(newImages);
      }
    };

  return (
    <div className={twMerge("relative p-4 w-96 mx-auto", className)}>
      <div
        className={twMerge(
          "relative border-2 border-dashed rounded-lg py-2 text-center transition-colors h-30 px-4",
          isDragging
            ? "border-blue-500 bg-blue-50"
            : "border-gray-300 hover:border-blue-400"
        )}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleButtonClick}
      >
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          className="hidden"
          accept="image/*"
          multiple={multiple}
        />
        <h1 className="text-sm font-bold mb-2">{title}</h1>
        {multiple && (
          <h6 className="absolute right-4 top-4 font-extralight text-sm">
            max {maxFiles}
          </h6>
        )}
        {images.length > 0 ? (
          <div
            className={twMerge(
              "flex w-full",
              images.length > 1 &&
                "grid grid-cols-5 gap-2 overflow-auto no-scrollbar"
            )}
          >
            {images.map((image, index) => (
              <div className="relative" key={image.file.name}>
                <img
                  src={image.url}
                  alt="Uploaded"
                  className="rounded-lg shadow-md h-16 w-16 object-cover"
                />
                <button
                  className="absolute bg-dark-500 rounded-4xl bg-white text-black cursor-pointer top-1 right-1"
                  onClick={handleRemoveImage(index)}
                >
                  <XCircleIcon width={20} />
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div className="h-16 content-center">
            <div className=" text-4l text-gray-400">
              <p className="text-gray-700">Drag or drop an image here</p>
              <p className="text-sm text-gray-500">Or click to select a file</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DragDropImage;
