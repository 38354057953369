import { queryClient } from "@features/query";
import { useMutation, useQuery } from "react-query";
import { ScenariosApiClient } from "../api-client/api-client";
import { ScenarioType } from "../types";
import { transformScenarioUpdate } from "../utils";

export const useScenario = (id: string) => {
  const scenario = useQuery<ScenarioType>(["scenarios", id], () =>
    ScenariosApiClient.getScenario(parseInt(id))
  );

  const save = useMutation({
    mutationFn: async (input: ScenarioType) => {
      // transform the input to match the API
      const scenarioUpdate = transformScenarioUpdate(input);
      const tmp = await ScenariosApiClient.saveScenario(scenarioUpdate);
      queryClient.invalidateQueries("scenarios");
      return tmp;
    },
  });

  return {
    scenario: scenario.data,
    loading: scenario.isLoading || save.isLoading,
    refresh: scenario.refetch,
    save: save.mutateAsync,
  };
};
