import { useFetch } from "@features/utils";
import type { IdentityAnalysisPayload, IdentityAnalysisType } from "../type";
import toast from 'react-hot-toast';

export class IdentityAnalysisApiClient {
  static postIdentityAnalysis = async (postIdentityAnalysis: IdentityAnalysisPayload): Promise<IdentityAnalysisType | null> => {
    try {
      const response = await useFetch(`/web/identity-analysis`, {
        method: "POST",
        body: JSON.stringify(postIdentityAnalysis),
      });

      if (!response.ok) {
        toast.error(`Server error ${response.status}: internal problem detected`)
      }
      const data = await response.json();
      return data as IdentityAnalysisType;
    } catch (err) {
      toast.error(`Server error: ${err}`);
      throw err;
    }
  }
};

