import { Button } from "@atoms/button/button";
import { ReportsQueryInputAtom } from "@features/press/state/store";
import { usePressReports } from "@features/press/state/use-press-reports";
import { ROUTES } from "@features/routes";
import { useControlledEffect } from "@features/utils";
import {
  ArrowLeftIcon,
  Bars4Icon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";

export const ReportDetailsNavigation = ({ id }: { id: string }) => {
  const { reports, search } = usePressReports();
  const filters = useRecoilValue(ReportsQueryInputAtom);

  const index = (reports?.data || []).findIndex((a) => a.id === id);
  const previous = (reports?.data || [])[index - 1];
  const next = (reports?.data || [])[index + 1];

  useControlledEffect(() => {
    if (!previous || !next) {
      search({ ...filters });
    }
  }, [previous?.id, next?.id, JSON.stringify(filters)]);

  return (
    <div className="float-right">
      <div className="m-auto inline-flex items-center">
        <Link
          className="flex"
          to={
            (!!previous?.id &&
              ROUTES.PressReportView.replace(":id", previous?.id)) ||
            ""
          }
        >
          <Button
            size="sm"
            disabled={!previous?.id}
            theme="outlined"
            className="rounded-r-none"
          >
            <ArrowLeftIcon className="h-5 w-5" />
            <span className="lg:ml-2 lg:inline hidden"> Previous report</span>
          </Button>
        </Link>

        <Link to={ROUTES.Reporting} className="flex">
          <Button size="sm" theme="outlined" className="rounded-none -ml-px">
            <Bars4Icon className="h-5 w-5" />
            <span className="lg:ml-2 lg:inline hidden"> List of reports</span>
          </Button>
        </Link>

        <Link
          to={
            (!!next?.id && ROUTES.PressReportView.replace(":id", next?.id)) ||
            ""
          }
          className="flex"
        >
          <Button
            size="sm"
            disabled={!next?.id}
            theme="outlined"
            className="rounded-l-none  -ml-px"
          >
            <span className="lg:inline lg:mr-2 hidden">Next report </span>
            <ArrowRightIcon className="h-5 w-5" />
          </Button>
        </Link>
      </div>
    </div>
  );
};
