import { useAuth } from "@features/auth/state/hooks";
import { queryClient } from "@features/query";
import { websocketBus } from "@features/websockets";
import { useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { InboxApiClient } from "../api-client/api-client";
import { InboxThread, InboxThreadCreateInput } from "../types";
import { useThreadMessages } from "./use-thread-messages";

export const useThread = (id: string) => {
  const { refresh: refreshHistory } = useThreadMessages(id);

  const thread = useQuery<InboxThread | null>({
    queryKey: ["inboxThread", id],
    queryFn: async () => {
      if (!id) {
        return null;
      }
      const thread = await InboxApiClient.getThread(id);
      await refreshHistory();
      return thread;
    },
    onError: (error) => {
      toast.error("Error loading inbox thread");
      console.log(error);
    },
    cacheTime: 2000,
  });

  const update = useMutation({
    mutationFn: async (params: {
      threadId: string;
      input: Partial<InboxThreadCreateInput>;
    }) => {
      if (!id) return;
      return await InboxApiClient.editThread(
        params.threadId,
        params.input as InboxThreadCreateInput
      );
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["inboxThread", id] });
    },
    onError: (error) => {
      toast.error("Error on updating thread");
      console.log(error);
    },
  });

  const comment = useCallback(
    async (threadId: string, comment: string) => {
      await update.mutateAsync({ threadId: threadId, input: { comment } });
    },
    [update]
  );

  return {
    thread: thread.data,
    loading: thread.isLoading || update.isLoading,
    refresh: thread.refetch,
    update: update.mutateAsync,
    comment,
  };
};

export const useRealtimeThread = (id: string) => {
  const { clientId } = useAuth();

  useEffect(() => {
    if (clientId && id) {
      const room3 = `client/${clientId}/threads/${id}/messages`;
      websocketBus.emit("join", null, { room3 });
      websocketBus.on(room3, (event: any) => {
        if (event.data.thread_id === id) {
          queryClient.invalidateQueries({
            queryKey: ["inboxThread", id],
          });
        }
      });
      return () => {
        websocketBus.emit("leave", null, { room3 });
        websocketBus.off(room3);
      };
    }
  }, [clientId, id]);

  return {};
};
