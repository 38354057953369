import { Input } from "@atoms/input/input-text";
import { Info } from "@atoms/text";
import { useAllFields } from "@views/client/customers/import/utils";
import Fuse from "fuse.js";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import { FieldInSearchBar } from "./components/field";
import { SearchField } from "./utils/types";
import { labelToVariable } from "./utils/utils";

type PropsFilterModal = {
  schema: { table: string; fields: SearchField[] };
  replaceAtCursor: (str: string, offset: number, focus: boolean) => void;
  onClose: () => void;
};

const FilterModal = ({
  schema,
  replaceAtCursor,
  onClose,
}: PropsFilterModal) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const fields = useAllFields();

  const [search, setSearch] = useState("");

  const fieldList = schema.fields.map((i) => {
    const format = fields.customerFields.find(
      (e) => e.label === i.label
    )?.format;
    if (format) {
      return { ...i, format };
    } else {
      return { ...i };
    }
  });

  const fuse = new Fuse(fieldList, {
    includeScore: true,
    threshold: 0.6,
    keys: ["label", "key"],
  });
  const searchArray = fuse.search(search);

  // On click outside close modal
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    },
    [onClose]
  );

  // Add event listener to detect clicks outside the modal
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col h-full gap-4 p-2" ref={modalRef}>
      <Input
        autoFocus
        placeholder="Search among fields"
        size="sm"
        className="shrink-0 w-full p-4 mt-1 border border-x-slate-200 rounded"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <SimpleBar className="flex flex-col h-96 overflow-auto -mx-4 px-2">
        {searchArray.length === 0 && search ? (
          <Info>Field not found</Info>
        ) : (
          (searchArray.length > 0 ? searchArray : fieldList).map((el: any) => {
            const isSearchArray = "item" in el;
            const item: SearchField = isSearchArray ? el.item : el;
            return (
              <div
                key={item.key}
                className="cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-800 p-2 rounded-md"
                onClick={() => {
                  switch (item.type) {
                    case "text": {
                      if (item.allowed_ops.includes("regexp")) {
                        replaceAtCursor(
                          `${labelToVariable(item.label || "")}:~""`,
                          -1,
                          true
                        );
                      } else {
                        replaceAtCursor(
                          `${labelToVariable(item.label || "")}:""`,
                          -1,
                          true
                        );
                      }
                      break;
                    }
                    case "boolean": {
                      replaceAtCursor(
                        `${labelToVariable(item.label || "")}:1`,
                        0,
                        true
                      );
                      break;
                    }
                    case "number": {
                      replaceAtCursor(
                        `${labelToVariable(item.label || "")}:>=0`,
                        0,
                        true
                      );
                      break;
                    }
                    case "date": {
                      replaceAtCursor(
                        `${labelToVariable(item.label || "")}:${moment().format(
                          "YYYY-MM-DD"
                        )}`,
                        0,
                        true
                      );
                      break;
                    }
                  }
                }}
              >
                <FieldInSearchBar field={item} />
              </div>
            );
          })
        )}
      </SimpleBar>
    </div>
  );
};

export default FilterModal;
