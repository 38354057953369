import Avatar from "@atoms/avatar/avatar";
import { useAgents } from "@features/agents/state/use-agents";
import { useAssignmentState } from "@features/assignments/state/use-assignment";
import { twMerge } from "tailwind-merge";

export const AssigneeTag = ({
  type,
  id,
  className,
}: {
  type:
    | "alert"
    | "customer"
    | "session"
    | "thread"
    | "transaction"
    | "pressreport";
  id: string;
  className?: string;
}) => {
  const { assignment } = useAssignmentState(type, id);
  const { members } = useAgents();

  return (
    <div className={twMerge("relative", className)}>
      {!!assignment?.members?.length && (
        <span
          data-tooltip={assignment?.members
            ?.map(
              (member) => members.find((inst) => inst.id === member.id)?.name
            )
            .join(", ")}
        >
          {assignment?.members?.map((member, i) => {
            const fullMember = members.find((inst) => inst.id === member.id);
            if (!fullMember) return null;
            return (
              <div
                key={i}
                className={twMerge("relative inline-block", i > 0 && "-ml-4")}
                style={{ zIndex: 10 - i }}
              >
                <Avatar
                  className="border border-white dark:border-transparent"
                  size={6}
                  fallback={fullMember?.name}
                  src={fullMember?.avatar}
                />
              </div>
            );
          })}
        </span>
      )}
    </div>
  );
};
