import { Info, SectionSmall } from "@atoms/text";
import { Control } from "./control";
import type { Control as ControlType } from "@features/vid/type";
import { Fragment } from "react/jsx-runtime";

type ControlGroup = {
  key: string;
  title: string;
};

type ControlsProps = {
  title?: string;
  describe?: string;
  controls: ControlType[];
  groups?: ControlGroup[];
  annotable?: boolean;
};

export function Controls({
  title,
  describe,
  controls,
  groups,
  annotable,
}: ControlsProps) {
  let firstNodes: ControlGroup[] = [];
  if (groups) {
    firstNodes = groups;
  } else {
    const nodes = getFirstNodes(controls);
    firstNodes = nodes.map((node) => ({
      key: node.identifier,
      title: node.titleMsg,
    }));
  }
  firstNodes = firstNodes.filter(
    (group) =>
      controls.filter((control) => control.identifier === group.key).length > 0
  );

  let removeDuplicatesChildren: string[] = [];
  const convertToTree = (
    group: ControlGroup,
    depth = 1,
    depthMap: boolean[] = []
  ): JSX.Element => {
    const control = controls.find(
      (control) => control.identifier === group.key
    );
    if (!control) return <></>;
    if (removeDuplicatesChildren.includes(group.key)) return <></>;
    removeDuplicatesChildren = [...removeDuplicatesChildren, group.key];

    const controlsAsGroup = controls
      .filter(
        (control) =>
          control.identifier.startsWith(group.key) &&
          control.identifier !== group.key
      )
      .map((control) => ({
        key: control.identifier,
        title: control.titleMsg,
      }));

    // Use child functions to create the tree
    return (
      <Fragment key={control.titleMsg}>
        <Control
          result={control.result}
          title={control.titleMsg}
          description={control.resultMsg}
          details={control.details}
          identifier={control.identifier}
          depth={depth}
          depthMap={[...depthMap, (controlsAsGroup || []).length > 0]}
          annotable={annotable}
        />
        {(controlsAsGroup || []).map((sub: ControlGroup, i: number) =>
          convertToTree(sub, depth + 1, [
            ...depthMap,
            i < (controlsAsGroup || []).length - 1,
          ])
        )}
      </Fragment>
    );
  };

  return (
    <div className="flex flex-col space-y-2">
      <SectionSmall>{title || "Controls"}</SectionSmall>
      <Info>
        {describe ||
          "These controls have been generated automatically by our systems. Please make further verifications if needed."}
      </Info>
      <div className="pt-2">
        <div className="flex flex-wrap">
          {firstNodes.map((group, i) => {
            return (
              <div className="relative grow shrink-0 w-1/5" key={i}>
                {convertToTree(group)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const getFirstNodes = (controls: ControlType[]) => {
  const firstNodes: ControlType[] = [];
  const names = controls.map((control) => control.identifier);

  controls.forEach((control) => {
    let hasParent = false;
    names.forEach((name) => {
      if (control.identifier.startsWith(name) && name !== control.identifier) {
        hasParent = true;
      }
    });
    if (!hasParent) {
      firstNodes.push(control);
    }
  });
  return firstNodes;
};
