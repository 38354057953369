import { atom, atomFamily } from "recoil";
import {
  InstantPressRequestOptions,
  PressReportBody,
  PressReportCompleteWithHistory,
} from "../types";
import { RestSearchRequest } from "@components/search-bar/utils/types";

export const InstantReportOptionsAtom = atom<
  Partial<InstantPressRequestOptions>
>({
  key: "InstantReportOptionsAtom",
  default: (() => {
    // Load from url
    const urlParams = new URLSearchParams(window.location.search);
    const saved = {
      report_id: urlParams.get("report_id") || "",
      entity_type: parseInt(urlParams.get("entity_type") || "1"),
      name: urlParams.get("name") || "",
      date_of_birth: urlParams.get("date_of_birth") || "",
      country_codes: urlParams.get("country_codes")?.split(",") || [],
    };
    return saved as InstantPressRequestOptions;
  })(),
});

export const InstantReportAtom = atom<PressReportBody | null>({
  key: "InstantReportAtom",
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newValue) => {
        localStorage.setItem(
          "saved.pressinstantreport",
          JSON.stringify(newValue)
        );
      });
    },
  ],
  default: (() => {
    let saved = null;
    const item = localStorage.getItem("saved.pressinstantreport");
    if (item !== null) {
      try {
        saved = JSON.parse(item);
      } catch (e) {
        // No-op
      }
    }
    return saved as PressReportBody;
  })(),
});

export const ReportsQueryInputAtom = atom<RestSearchRequest>({
  key: "ReportsQueryInputAtom",
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newValue) => {
        localStorage.setItem(
          "press_options",
          JSON.stringify(newValue?.options)
        );
      });
    },
  ],
  default: (() => {
    let saved: Partial<RestSearchRequest["options"]> = {};
    try {
      saved = JSON.parse(localStorage.getItem("press_options") || "{}");
    } catch (e) {
      console.error(e);
    }
    return {
      query: [],
      options: {
        order_by: saved.order_by || "updated_at",
        order_way: saved.order_way || "DESC",
        limit: saved.limit || 10,
      },
    };
  })(),
});

export const PressReportAtom = atomFamily<
  PressReportCompleteWithHistory | null,
  string
>({
  key: "PressReportAtom",
  default: () => null,
});

export const PressReportBodyAtom = atomFamily<PressReportBody | null, string>({
  key: "PressReportBodyAtom",
  default: () => null,
});
