import { Info, SectionSmall } from "@atoms/text";
import { defaultAlertStates } from "@features/alerts/utils";
import { CUSTOMERS_EDD_STATES_ID } from "@features/customers/utils";
import { useDashboard } from "@features/monitoring/state/use-dashboard";

export const OutstandingTasks = (props: {
  scanEnabled: boolean;
  riskEnabled: boolean;
  onboardingEnabled: boolean;
  chatEnabled: boolean;
  kytEnabled: boolean;
  pressEnabled: boolean;
}) => {
  const { risk, scan, onboarding, chat, kyt, press } = useDashboard();

  return (
    <div className="mt-2 flex flex-col sm:flex-row embed-flex-auto-layout justify-between min-h-40 overflow-auto space-y-2 sm:space-x-2 sm:space-y-0">
      {props.riskEnabled && (
        <OutstandingNumber
          label="Go!Risk"
          total={
            risk?.global_risk_info?.edd_states?.find(
              (a) => a.id === CUSTOMERS_EDD_STATES_ID.ACTION_REQUIRED
            )?.value
          }
          amount={risk?.outstanding?.amount}
          days={risk?.outstanding?.days}
        />
      )}
      {props.chatEnabled && (
        <OutstandingNumber
          label="Go!Chat"
          total={chat?.all_threads_by_status?.open_threads_count}
          amount={chat?.outstanding?.amount}
          days={chat?.outstanding?.days}
        />
      )}
      {props.scanEnabled && (
        <OutstandingNumber
          label="Go!Scan"
          total={
            scan?.global_scan_info?.find(
              (a) => a.alert_state === defaultAlertStates.new
            )?.value
          }
          amount={scan?.outstanding?.amount}
          days={scan?.outstanding?.days}
        />
      )}
      {props.onboardingEnabled && (
        <OutstandingNumber
          label="Go!Vid"
          total={
            (onboarding?.status_statistics?.total_without_status || 0) +
            (onboarding?.status_statistics?.items
              ?.filter((a) => a.status.type === "NEUTRAL")
              ?.reduce((acc, a) => acc + a.total, 0) || 0)
          }
          amount={onboarding?.outstanding?.amount}
          days={onboarding?.outstanding?.days}
        />
      )}
      {props.kytEnabled && (
        <OutstandingNumber
          label="Go!KYT"
          total={
            kyt?.last_30_days_transactions_by_status?.delayed_transactions_count
          }
          amount={kyt?.outstanding?.amount}
          days={kyt?.outstanding?.days}
        />
      )}
      {props.pressEnabled && (
        <OutstandingNumber
          label="Go!Press"
          total={press?.reports_by_edd_status?.action_required_count}
          amount={press?.outstanding?.amount}
          days={press?.outstanding?.days}
        />
      )}
    </div>
  );
};

export const OutstandingNumber = ({
  amount,
  total,
  days,
  label,
}: {
  label: string;
  amount?: number;
  total?: number;
  days?: number;
}) => {
  let color = "bg-white dark:bg-slate-900 text-black dark:text-white";

  if (total === undefined) {
    color += " opacity-50";
  }

  if (amount && amount > 0) {
    color = "bg-red-500 text-white";
  } else if (total && total > 0) {
    color = "bg-yellow-500 text-white";
  }
  if (amount && amount === 0 && total && total === 0) {
    color = "bg-green-500 text-white";
  }

  return (
    <div
      className={
        color +
        " w-full p-4 flex flex-col justify-center items-center rounded border"
      }
      style={{
        boxSizing: "border-box",
      }}
    >
      <SectionSmall noColor className="block">
        {label}
      </SectionSmall>
      <div className="text-5xl font-bold block mb-4">
        {total === undefined ? "-" : total || "0"}
      </div>
      {(amount || 0) > 0 && (
        <Info noColor className="w-full text-center">
          <b>
            {amount || 0} late ({days || 0}d old)
          </b>
        </Info>
      )}
      {(amount || 0) === 0 && (
        <Info noColor className="opacity-50 w-full text-center">
          No outstanding tasks
        </Info>
      )}
    </div>
  );
};
