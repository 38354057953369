import "@features/i18n";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import initReactFastclick from "react-fastclick";
import { QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import "simplebar-react/dist/simplebar.min.css";
import "./index.css";
import InitialRouter from "./views";
import { queryClient } from "@features/query";

if (document.location.hostname !== "localhost") {
  Sentry.init({
    dsn: "https://b7739eeb13d04a77aef6be3aac2bb2c4@sentry.adm.algoreg.com/3",
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0.2,
    environment: document.location.hostname,
  });
}

initReactFastclick();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <BrowserRouter>
          <InitialRouter />
        </BrowserRouter>
      </RecoilRoot>
    </QueryClientProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);
