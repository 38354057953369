import { MaxWidthPage } from "@atoms/layout/page";
import { PageBlock } from "@atoms/layout/page-block";
import { SectionSmall, Title } from "@atoms/text";
import { useBilling } from "@features/billing/use-billing";
import {
  useDashboard,
  useDashboardTimeseries,
} from "@features/monitoring/state/use-dashboard";
import { useControlledEffect } from "@features/utils";
import {
  ArcElement,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import {
  isEmbed,
  useEmbedUtils,
} from "../../../features/utils/use-embed-utils";
import { Chat } from "./live/chat";
import { KYT } from "./live/kyt";
import { OutstandingTasks } from "./live/outstanding-tasks";
import { Risk } from "./live/risk";
import { Scan } from "./live/scan";
import { Vid } from "./live/vid";
import { PastChat } from "./past/chat";
import { PastOutstandingTasks } from "./past/outstanding-tasks";
import { PastRisk } from "./past/risk";
import { PastScan } from "./past/scan";
import { PastVid } from "./past/vid";
import { TimeseriesSelector } from "./timeseries-selector";
import { EmbedDashboard } from "./embed";
import { Press } from "./live/press";
import { PastPress } from "./past/press";
import { useEffect } from "react";
import { useHasAccess } from "@features/auth/state/use-access";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@features/routes";

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(PointElement, Tooltip, Legend);
ChartJS.register(LineElement, Tooltip, Legend);
ChartJS.register(Filler, Tooltip, Legend);

export const DashboardPage = () => {
  const { refresh } = useDashboard();
  const { period } = useDashboardTimeseries();
  const hasAccess = useHasAccess();
  const navigate = useNavigate();

  useControlledEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    if (!hasAccess("MONITORING") && !isEmbed()) {
      navigate(ROUTES.Customers);
    }
  }, [hasAccess, navigate]);

  const { embed } = useEmbedUtils();

  if (embed) return <EmbedDashboard />;

  return (
    <MaxWidthPage>
      <TimeseriesSelector />
      <Title>Dashboard</Title>

      {!period[0] && <Live />}
      {!!period[0] && <Past />}
    </MaxWidthPage>
  );
};

const Live = () => {
  const {
    isKYTEnabled,
    isRiskEnabled,
    isOnboardingEnabled,
    isChatEnabled,
    isScanEnabled,
    isPressEnabled,
  } = useBilling();
  const { displayProduct } = useEmbedUtils();

  return (
    <>
      <SectionSmall className="mt-4">All products tasks</SectionSmall>
      <OutstandingTasks
        scanEnabled={isScanEnabled && displayProduct("goscan")}
        riskEnabled={isRiskEnabled && displayProduct("gorisk")}
        onboardingEnabled={isOnboardingEnabled && displayProduct("govid")}
        chatEnabled={isChatEnabled && displayProduct("gochat")}
        kytEnabled={isKYTEnabled && displayProduct("gokyt")}
        pressEnabled={isPressEnabled && displayProduct("gopress")}
      />

      <hr className="my-6" />

      {isRiskEnabled && displayProduct("gorisk") && (
        <>
          <SectionSmall className="mt-4">Go!Risk</SectionSmall>
          <div className="flex flex-row">
            <PageBlock className="mt-2 grow">
              <Risk />
            </PageBlock>
          </div>
        </>
      )}

      {isChatEnabled && displayProduct("gochat") && (
        <>
          <SectionSmall className="mt-4">Go!Chat</SectionSmall>
          <div className="flex flex-row">
            <PageBlock className="mt-2 grow">
              <Chat />
            </PageBlock>
          </div>
        </>
      )}

      {isScanEnabled && displayProduct("goscan") && (
        <>
          <SectionSmall className="mt-4">Go!Scan</SectionSmall>
          <div className="flex flex-row">
            <PageBlock className="mt-2 grow">
              <Scan />
            </PageBlock>
          </div>
        </>
      )}

      {isOnboardingEnabled && displayProduct("govid") && (
        <>
          <SectionSmall className="mt-4">Go!Vid</SectionSmall>
          <div className="flex flex-row">
            <PageBlock className="mt-2 grow">
              <Vid />
            </PageBlock>
          </div>
        </>
      )}

      {isKYTEnabled && displayProduct("gokyt") && (
        <>
          <SectionSmall className="mt-4">Go!KYT</SectionSmall>
          <div className="flex flex-row">
            <PageBlock className="mt-2 grow">
              <KYT />
            </PageBlock>
          </div>
        </>
      )}

      {isPressEnabled && displayProduct("gopress") && (
        <>
          <SectionSmall className="mt-4">Go!Press</SectionSmall>
          <div className="flex flex-row">
            <PageBlock className="mt-2 grow">
              <Press />
            </PageBlock>
          </div>
        </>
      )}
    </>
  );
};

const Past = () => {
  const {
    isKYTEnabled,
    isRiskEnabled,
    isOnboardingEnabled,
    isChatEnabled,
    isScanEnabled,
    isPressEnabled,
  } = useBilling();
  const { displayProduct } = useEmbedUtils();

  return (
    <>
      <SectionSmall className="mt-4">All products tasks</SectionSmall>

      <PastOutstandingTasks
        scanEnabled={isScanEnabled && displayProduct("goscan")}
        riskEnabled={isRiskEnabled && displayProduct("gorisk")}
        onboardingEnabled={isOnboardingEnabled && displayProduct("govid")}
        chatEnabled={isChatEnabled && displayProduct("gochat")}
        kytEnabled={isKYTEnabled && displayProduct("gokyt")}
        pressEnabled={isPressEnabled && displayProduct("gopress")}
      />

      <hr className="my-6" />

      {isRiskEnabled && displayProduct("gorisk") && (
        <>
          <SectionSmall className="mt-4">Go!Risk</SectionSmall>
          <div className="flex flex-row">
            <PageBlock className="mt-2 grow">
              <PastRisk />
            </PageBlock>
          </div>
        </>
      )}

      {isChatEnabled && displayProduct("gochat") && (
        <>
          <SectionSmall className="mt-4">Go!Chat</SectionSmall>
          <div className="flex flex-row">
            <PageBlock className="mt-2 grow">
              <PastChat />
            </PageBlock>
          </div>
        </>
      )}

      {isScanEnabled && displayProduct("goscan") && (
        <>
          <SectionSmall className="mt-4">Go!Scan</SectionSmall>
          <div className="flex flex-row">
            <PageBlock className="mt-2 grow">
              <PastScan />
            </PageBlock>
          </div>
        </>
      )}

      {isOnboardingEnabled && displayProduct("govid") && (
        <>
          <SectionSmall className="mt-4">Go!Vid</SectionSmall>
          <div className="flex flex-row">
            <PageBlock className="mt-2 grow">
              <PastVid />
            </PageBlock>
          </div>
        </>
      )}

      {isKYTEnabled && displayProduct("gokyt") && (
        <>
          <SectionSmall className="mt-4">Go!KYT</SectionSmall>
          <div className="flex flex-row">
            <PageBlock className="mt-2 grow">
              <KYT />
            </PageBlock>
          </div>
        </>
      )}

      {isPressEnabled && displayProduct("gopress") && (
        <>
          <SectionSmall className="mt-4">Go!Press</SectionSmall>
          <div className="flex flex-row">
            <PageBlock className="mt-2 grow">
              <PastPress />
            </PageBlock>
          </div>
        </>
      )}
    </>
  );
};
