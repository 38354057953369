import { Info, SectionSmall } from "@atoms/text";
import { ModalImage } from "@components/modal-image";
import Env from "@config/environment";
import { getAnswersAttachementUrl } from "../utils";
export enum FaceDocumentType {
  Upload = "uploaded_file",
  Identity = "identity_face",
  IdentityBack = "identity_back",
  IdentityFront = "identity_front",
  Face = "face",
}
export type QuestionAnswerAttachmentType = {
  type: FaceDocumentType;
  id: string;
};

type FaceScoreProps = {
  identity_faces?: QuestionAnswerAttachmentType | string;
  answers_attachment_urls?: QuestionAnswerAttachmentType[] | string[];
  similarityScore?: number;
  type?: "url" | "base64";
};

const FaceScore = ({
  identity_faces,
  answers_attachment_urls,
  similarityScore = 0,
  type = "url",
}: FaceScoreProps) => {
  const identityFaces = identity_faces;
  const answersAttachmentUrls = answers_attachment_urls;

  const answersAttachmentListUrls = getAnswersAttachementUrl(
    type,
    answersAttachmentUrls || []
  );

  const identityFacesUrl =
    typeof identityFaces === "string"
      ? identityFaces
      : `${Env.server}/api/v1/downloads/${identityFaces?.id}`;

  return (
    <div className="flex flex-col space-y-2">
      <SectionSmall>Faces</SectionSmall>
      {identityFaces ? (
        <Info>
          Score obtained matching webcam faces and identity face below :
        </Info>
      ) : (
        <Info>Faces detected during the session</Info>
      )}
      <div className="flex flex-row gap-2 flex-wrap justify-center overflow-x-auto py-2">
        {answersAttachmentUrls?.length === 0 && (
          <Info>No faces were detected during the session.</Info>
        )}
        {answersAttachmentListUrls?.map((url, index) => (
          <ModalImage
            key={index}
            className="w-24 h-24 object-cover rounded-md border border-slate-300 "
            small={url}
            large={url}
            alt="Face"
          />
        ))}
        {identityFaces && (
          <>
            <div className="w-24 h-24 text-5xl justify-center flex items-center pb-2">
              {similarityScore > 0.9 ? "=" : "≠"}
            </div>
            <ModalImage
              className="w-auto h-24 object-cover rounded-md border border-slate-300"
              alt="Face"
              small={identityFacesUrl}
              large={identityFacesUrl}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default FaceScore;
