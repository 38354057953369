import { Button } from "@atoms/button/button";
import Link from "@atoms/link";
import { CustomersQueryInputAtom } from "@features/customers/state/store";
import { useCustomers } from "@features/customers/state/use-customers";
import { ROUTES } from "@features/routes";
import { useControlledEffect } from "@features/utils";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Bars4Icon,
} from "@heroicons/react/24/outline";
import { useRecoilValue } from "recoil";

export const CustomerNavigation = ({ id }: { id: string }) => {
  const { customers, search } = useCustomers();

  const filters = useRecoilValue(CustomersQueryInputAtom);

  const index = (customers?.data || []).findIndex((a) => a.customer_id === id);
  const previous = (customers?.data || [])[index - 1];
  const next = (customers?.data || [])[index + 1];

  useControlledEffect(() => {
    if (!previous || !next) {
      search({ ...filters });
    }
  }, [previous?.customer_id, next?.customer_id, JSON.stringify(filters)]);

  return (
    <div className="float-right">
      <div className="m-auto inline-flex items-center">
        <Link
          className="flex"
          to={
            !!previous?.customer_id &&
            ROUTES.CustomerView.replace(":id", previous?.customer_id)
          }
        >
          <Button
            size="sm"
            disabled={!previous?.customer_id}
            theme="outlined"
            className="rounded-r-none"
          >
            <ArrowLeftIcon className="h-5 w-5" />
            <span className="lg:ml-2 lg:inline hidden"> Previous customer</span>
          </Button>
        </Link>

        <Link to={ROUTES.Customers} className="flex">
          <Button size="sm" theme="outlined" className="rounded-none -ml-px">
            <Bars4Icon className="h-5 w-5" />
            <span className="lg:ml-2 lg:inline hidden"> List of customers</span>
          </Button>
        </Link>

        <Link
          to={
            !!next?.customer_id &&
            ROUTES.CustomerView.replace(":id", next?.customer_id)
          }
          className="flex"
        >
          <Button
            size="sm"
            disabled={!next?.customer_id}
            theme="outlined"
            className="rounded-l-none  -ml-px"
          >
            <span className="lg:inline lg:mr-2 hidden">Next customer </span>
            <ArrowRightIcon className="h-5 w-5" />
          </Button>
        </Link>
      </div>
    </div>
  );
};
