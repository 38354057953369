import { Checkbox } from "@atoms/input/input-checkbox";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import { InfoSmall, SectionSmall } from "@atoms/text";
import { useAuth } from "@features/auth/state/hooks";
import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";
import { useMatrixEntries } from "@features/custom-matrix/state/use-matrix-entries";
import { UpdateCustomersRequest } from "@features/customers/types";
import { useControlledEffect } from "@features/utils";
import { SwatchIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { useRef } from "react";

export const CustomerCustomFields = ({
  form,
  setForm,
}: {
  form: UpdateCustomersRequest;
  setForm: (form: UpdateCustomersRequest) => void;
}) => {
  const { agent } = useAuth();
  const { fields: _fields } = useCustomFields();

  const allowedReviewGroups = agent?.review_groups || [];

  const fields = _fields.filter((field) => field.field_source === 3);
  const groups = _.sortBy(
    _.uniq(fields.filter((a) => a.field_source === 3).map((a) => a.group)),
    (a) => a || "zzzzz"
  );

  const canWrite = (expectedReviewGroups: string[]) => {
    return (
      expectedReviewGroups.includes("*") ||
      allowedReviewGroups.length === 0 ||
      _.intersection(expectedReviewGroups, allowedReviewGroups).length > 0
    );
  };

  return (
    <>
      {groups.map((group) => {
        const groupFields = _.sortBy(
          fields.filter((a) => a.group === group),
          (f) => f.header_name
        );
        return (
          <div id="customFields" className="mt-6" key={group}>
            <div className="flex flex-row justify-between">
              <SectionSmall>{group || "Other fields"}</SectionSmall>
              <SwatchIcon className="w-5 opacity-50 inline-block mr-1" />
            </div>
            {groupFields.map((field) => (
              <InputLabel
                key={field.field_id}
                className="mt-4"
                label={
                  <>
                    {_.capitalize(field.header_name)}
                    <InfoSmall className="ml-2">{field.label}</InfoSmall>
                  </>
                }
                input={
                  <>
                    {field.field_type === 3 && (
                      <Checkbox
                        disabled={!canWrite(field.review_groups)}
                        label={_.capitalize(field.label)}
                        value={
                          form.customers[0].fields[field.label] === "true" ||
                          form.customers[0].fields[field.label] === "1" ||
                          (form.customers[0].fields[field.label] as any) ===
                            true
                        }
                        onChange={(e) => {
                          setForm({
                            ...form,
                            customers: [
                              {
                                ...form.customers[0],
                                fields: {
                                  ...form.customers[0].fields,
                                  [field.label]: e ? "true" : "false",
                                },
                              },
                            ],
                          });
                        }}
                      />
                    )}
                    {field.field_type !== 3 && (
                      <>
                        {field.format.type?.match(/matrix_/) ? (
                          <MatrixInput
                            disabled={!canWrite(field.review_groups)}
                            multiple={field.format.multiple}
                            matrixId={field.format.type.split("_")[1]}
                            value={form.customers[0].fields[field.label]}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                customers: [
                                  {
                                    ...form.customers[0],
                                    fields: {
                                      ...form.customers[0].fields,
                                      [field.label]: e,
                                    },
                                  },
                                ],
                              });
                            }}
                          />
                        ) : (
                          <Input
                            disabled={!canWrite(field.review_groups)}
                            type={field.field_type !== 4 ? "number" : "text"}
                            placeholder={
                              _.capitalize(field.label) +
                              (field.format?.multiple
                                ? " (separated by ;)"
                                : "")
                            }
                            value={form.customers[0].fields[field.label]}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                customers: [
                                  {
                                    ...form.customers[0],
                                    fields: {
                                      ...form.customers[0].fields,
                                      [field.label]: e.target.value,
                                    },
                                  },
                                ],
                              });
                            }}
                          />
                        )}
                      </>
                    )}
                  </>
                }
              />
            ))}
          </div>
        );
      })}
    </>
  );
};

const MatrixInput = ({
  matrixId,
  value,
  onChange,
  multiple,
  disabled,
}: {
  matrixId: string;
  value: string;
  onChange: (e: any) => void;
  multiple?: boolean;
  disabled?: boolean;
}) => {
  const initialAdditionalValues = useRef(value);
  const { matrix, refresh } = useMatrixEntries(matrixId);

  useControlledEffect(() => {
    if (matrix.length === 0) refresh();
  }, []);

  console.log({ matrix });

  return (
    <SelectMultiple
      disabled={disabled}
      selectionLimit={multiple ? undefined : 1}
      value={(typeof value === "string"
        ? (value || "").split(/,|;/)
        : value || []
      ).filter((a) => a)}
      onChange={(e) => onChange(e.join(";"))}
      options={_.sortBy(
        _.uniqBy(
          matrix.length > 0
            ? [
                ...matrix.map((entry) => ({
                  value: entry.key,
                  label: entry.label,
                })),
                ...(typeof initialAdditionalValues.current === "string"
                  ? (initialAdditionalValues.current || "").split(/,|;/)
                  : initialAdditionalValues.current || []
                )
                  .filter((a) => a)
                  .map((a) => ({ value: a, label: a })),
              ]
            : [],
          "value"
        ),
        (value) => {
          const toInt = parseInt(value.label);
          return isNaN(toInt) ? value.label : toInt;
        }
      )}
    />
  );
};
