import { Info } from "@atoms/text";
import { DASHBOARD_SCAN_STATE } from "@features/monitoring/enums";
import { useDashboard } from "@features/monitoring/state/use-dashboard";
import { formatNumber } from "@features/utils";
import { formatDuration, formatTime } from "@features/utils/dates";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { ScanChart } from "./charts/scan";
import { useBilling } from "@features/billing/use-billing";

ChartJS.register(ArcElement, Tooltip, Legend);

export const Scan = () => {
  const { scan, risk } = useDashboard();
  const { plan } = useBilling();

  if (!scan?.last_scan_info?.state) return <div className="h-44" />;

  return (
    <div className="flex embed-flex-auto-layout flex-col sm:flex-row">
      <div className="sm:w-1/3 py-4" style={{ minWidth: "316px" }}>
        <ScanChart />
      </div>
      <div className="sm:w-1/3 sm:ml-8">
        <div className="mb-2">
          <Info>Scanning status</Info>
          <br />
          <div className="overflow-hidden mr-2 text-base">
            <div
              className={
                "mr-2 rounded-full w-3 h-3 inline-block " +
                (scan?.ongoing_scan_info?.state === 3
                  ? "bg-green-500"
                  : scan?.ongoing_scan_info?.state === 1
                  ? "bg-yellow-500"
                  : "bg-red-500")
              }
            />
            {scan?.ongoing_scan_info?.start_date
              ? DASHBOARD_SCAN_STATE[scan?.ongoing_scan_info?.state || 1]
              : "Not started"}

            {scan?.ongoing_scan_info?.state !== 3 && (
              <> ({scan?.ongoing_scan_info?.progress || 0}%)</>
            )}
          </div>
        </div>
        <div className="mb-2">
          <Info>Last full scan</Info>
          <br />
          <div className="overflow-hidden mr-2 text-base">
            {scan?.last_scan_info?.end_date
              ? formatTime(scan?.last_scan_info?.start_date || 0, undefined, {
                  keepTime: true,
                  keepDate: true,
                })
              : "Never"}{" "}
            {scan?.last_scan_info?.state === 3 &&
              `(${formatDuration(
                Math.max(
                  0,
                  new Date(scan?.last_scan_info?.end_date || 0).getTime() -
                    new Date(scan?.last_scan_info?.start_date || 0).getTime()
                )
              )})`}
            {scan?.last_scan_info?.state !== 3 &&
              `(Failed after ${formatDuration(
                Math.max(
                  0,
                  new Date(scan?.last_scan_info?.end_date || 0).getTime() -
                    new Date(scan?.last_scan_info?.start_date || 0).getTime()
                )
              )})`}
          </div>
        </div>
        <div className="mb-2">
          <Info>Customers scanned</Info>
          <br />
          <div className="overflow-hidden mr-2 text-base">
            {scan?.last_scan_info?.state === 3 && (
              <>{formatNumber(scan?.last_scan_info?.customer_records || 0)}</>
            )}
            {scan?.last_scan_info?.state !== 3 && (
              <>
                {Math.ceil(
                  ((risk?.global_risk_info.risk_levels.reduce(
                    (acc, a) => a.value + acc,
                    0
                  ) || 0) *
                    (scan?.last_scan_info?.progress || 0)) /
                    100
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="grow sm:ml-4">
        <div className="mb-2">
          <Info>Watchlists last update</Info>
          <br />
          <div className="overflow-hidden mr-2 text-base">
            {formatTime(scan?.last_dj_import_info?.end_date || 0, undefined, {
              keepTime: true,
              keepDate: true,
            })}
          </div>
          <div className="bg-green-500 w-3 h-3 mr-2 inline-block rounded-full" />
          <span className="text-sm">Sanctions lists (SAN)</span>
          <br />
          <div className="bg-green-500 w-3 h-3 mr-2 inline-block rounded-full" />
          <span className="text-sm">PEP lists</span>
          <br />
          <div className="bg-green-500 w-3 h-3 mr-2 inline-block rounded-full" />
          <span className="text-sm">
            Special Interest Entities lists (SIP/SIE)
          </span>
          <br />
          {plan.scan_available_lists.includes("ame") && (
            <>
              <div className="bg-green-500 w-3 h-3 mr-2 inline-block rounded-full" />
              <span className="text-sm">Adverse Medias</span>
              <br />
            </>
          )}
          {plan.scan_available_lists.includes("soc") && (
            <>
              <div className="bg-green-500 w-3 h-3 mr-2 inline-block rounded-full" />
              <span className="text-sm">State Owned Entities</span>
              <br />
            </>
          )}
        </div>
        <div className="mb-2">
          <Info>Watchlists total records</Info>
          <br />
          <div className="overflow-hidden mr-2 text-base">
            {formatNumber(
              scan?.last_dj_import_info?.records_after_prefiltering || 0
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
