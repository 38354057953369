import { useDashboard } from "@features/monitoring/state/use-dashboard";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  PRESS_REPORT_RISK_COUNT_COLORS,
  PRESS_REPORT_RISK_COUNT_NAMES,
} from "./press";

ChartJS.register(ArcElement, Tooltip, Legend);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const PressRiskLevelsChart = () => {
  const { press } = useDashboard();

  const pressRiskLevelsInfo = Object.keys(
    press?.reports_by_active_risk_level || {}
  )
    .map((a) => ({
      label: a,
      value: (press?.reports_by_active_risk_level || ({} as any))[a] as number,
    }))
    .filter((a) => a.value > 0)
    .reverse();

  return (
    <>
      <div className="h-20 w-40 mb-4">
        <Bar
          options={{
            animations: false as any,
            indexAxis: "y" as const,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  title: () => "",
                },
              },
            },
            interaction: {
              mode: "nearest",
              axis: "y",
              intersect: false,
            },
            scales: {
              x: {
                grid: {
                  display: false,
                },
                border: {
                  display: false,
                },
                ticks: {
                  display: false,
                },
              },
              y: {
                position: "right",
                grid: {
                  display: false,
                },
                border: {
                  display: false,
                },
              },
            },
            maintainAspectRatio: false,
          }}
          data={{
            labels: pressRiskLevelsInfo.map(
              (a) =>
                `${
                  (PRESS_REPORT_RISK_COUNT_NAMES as any)[a.label] || "Unknown"
                }`
            ),
            datasets: [
              {
                borderRadius: Number.MAX_VALUE,
                borderSkipped: false,
                minBarLength: 8,
                data: pressRiskLevelsInfo.map((a) => a.value),
                backgroundColor: pressRiskLevelsInfo.map(
                  (a) =>
                    (PRESS_REPORT_RISK_COUNT_COLORS as any)[a.label] ||
                    "#000000"
                ),
              },
            ],
          }}
        />
      </div>
    </>
  );
};
