import Select from "@atoms/select";
import { Base } from "@atoms/text";
import { FIELD_TYPES } from "@features/custom-fields/enum";
import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";
import { CustomFieldType } from "@features/custom-fields/types";
import { useContexts } from "@features/kyt/state/use-contexts";
import {
  RiskNodeField,
  RiskResourceType,
} from "@features/risk-decisions/types";
import { useRiskFactors } from "@features/risk-decisions/use-risks-decisions";
import { CUSTOM_CUSTOMER_INTERNAL_FIELD } from "@features/risk-decisions/utils";
import { useEffect, useState } from "react";

const defaultKYTSID = "kyt.rolling.1m.all.all.all.all.sum";

export const FieldSelector = (props: {
  type: RiskResourceType;
  withRelations?: boolean;
  editable?: boolean;
  value: {
    id?: number;
    string_identifier?: string;
    entity: RiskNodeField["entity"];
  };
  onChange: (value: {
    string_identifier?: string;
    id?: number;
    entity: RiskNodeField["entity"];
  }) => void;
  fieldTypes?: ("text" | "number" | "boolean")[];
}) => {
  const { fields, loading, refresh } = useCustomFields();
  const {
    riskFactors: factors,
    loading: riskLoading,
    refresh: riskRefresh,
  } = useRiskFactors("customer");
  const { contexts, loading: loadingContexts } = useContexts();
  const [useKytCustomValue, setUseKytCustomValue] = useState(false);

  useEffect(() => {
    if (factors.length === 0) riskRefresh();
    if (fields.length === 0) refresh();
  }, [fields.length, refresh, factors.length, riskRefresh]);

  const allowRelationFields = props.withRelations;
  const fieldTypesNumber = (props.fieldTypes || []).map(
    (f) => ({ number: 2, boolean: 3, text: 4 }[f])
  );

  const onChange = (value: {
    string_identifier?: string;
    id?: number;
    entity: RiskNodeField["entity"];
  }) => {
    if (value.id || value.entity === "transaction") setUseKytCustomValue(false);
    props.onChange(ensureValidity(props.type, fields, value));
  };

  useEffect(() => {
    if (props.value.string_identifier?.indexOf("kyt") === 0) {
      setUseKytCustomValue(true);
    }
  }, [props.value.string_identifier]);

  const sid = props.value.string_identifier;

  const sortArray = (arr: CustomFieldType[]) => {
    return arr.sort((a, b) => a.label.localeCompare(b.label));
  };
  const groupedFields = fields
    .filter((f) => allowRelationFields || ![5, 6].includes(f.field_source))
    .filter(
      (f) => !fieldTypesNumber.length || fieldTypesNumber.includes(f.field_type)
    )
    .reduce(
      (acc, field) => {
        if (field.label.endsWith("_pep")) {
          acc.pep.push(field);
        } else if (
          field.label.endsWith("_si") ||
          field.label.includes("_san")
        ) {
          acc.si.push(field);
        } else if (field.label.endsWith("_so")) {
          acc.so.push(field);
        } else {
          acc.other.push(field);
        }
        return acc;
      },
      {
        pep: [] as CustomFieldType[],
        si: [] as CustomFieldType[],
        so: [] as CustomFieldType[],
        other: [] as CustomFieldType[],
      }
    );

  return (
    <>
      {props.type === "kyt" && (
        <div className="flex relative focus:z-10">
          <Select
            disabled={!props.editable}
            className="disabled:opacity-50 nowheel nodrag w-full rounded-none grow"
            name="entity"
            defaultValue={props.value?.entity}
            onChange={(e) => {
              onChange({
                ...props.value,
                entity: e.target.value as RiskNodeField["entity"],
              });
            }}
          >
            <option value="transaction">Transaction</option>
            <option value="from">From customer</option>
            <option value="to">To customer</option>
            <option value="to_institution">To institution</option>
            <option value="from_institution">From institution</option>
          </Select>
        </div>
      )}
      <div className="flex relative focus:z-10">
        {!riskLoading && !loading && props.value?.entity !== "transaction" && (
          <Select
            disabled={!props.editable}
            className="disabled:opacity-50 nowheel nodrag w-full rounded-none grow"
            placeholder="Select a field"
            value={
              useKytCustomValue
                ? "kyt"
                : props.value.string_identifier
                ? props.value.string_identifier
                : props.value.id
            }
            onChange={(e) => {
              if (e.target.value === "kyt") {
                setUseKytCustomValue(true);
                onChange({
                  ...props.value,
                  string_identifier: defaultKYTSID,
                  id: undefined,
                });
              } else if (e.target.value.includes("risk.factor.")) {
                setUseKytCustomValue(false);
                onChange({
                  ...props.value,
                  string_identifier: e.target.value,
                  id: 1,
                });
              } else if (
                CUSTOM_CUSTOMER_INTERNAL_FIELD.includes(e.target.value)
              ) {
                onChange({
                  ...props.value,
                  string_identifier: e.target.value,
                  id: undefined,
                });
              } else {
                setUseKytCustomValue(false);
                onChange({
                  ...props.value,
                  string_identifier: undefined,
                  id: parseInt(e.target.value),
                });
              }
            }}
            name="field"
          >
            <option value="" className="text-gray-200" disabled selected>
              Select a field
            </option>
            {groupedFields && (
              <>
                <optgroup label="Internal fields">
                  {sortArray(groupedFields.other)
                    .filter((a) => [2, 5, 7].includes(a.field_source))
                    .map((field: CustomFieldType, i: number) => (
                      <option
                        key={"field-" + field.id + "-" + i}
                        value={field.field_id}
                      >
                        {field.label}
                      </option>
                    ))}
                  <option value="age">age</option>
                </optgroup>
                <optgroup label="Custom fields">
                  {sortArray(groupedFields.other)
                    .filter((a) => [3, 6, 8].includes(a.field_source))
                    .map((field: CustomFieldType, i: number) => (
                      <option
                        key={"field-" + field.id + "-" + i}
                        value={field.field_id}
                      >
                        {field.label}
                      </option>
                    ))}
                </optgroup>
                <optgroup label="Computed fields">
                  {sortArray(groupedFields.other)
                    .filter((a) => [4].includes(a.field_source))
                    .map((field: CustomFieldType, i: number) => (
                      <option
                        key={"field-" + field.id + "-" + i}
                        value={field.field_id}
                      >
                        {field.label}
                      </option>
                    ))}
                </optgroup>
                <optgroup label="Politically Exposed Person">
                  {sortArray(groupedFields.pep).map(
                    (field: CustomFieldType, i: number) => (
                      <option
                        key={"field-" + field.id + "-" + i}
                        value={field.field_id}
                      >
                        {field.label}
                      </option>
                    )
                  )}
                </optgroup>
                <optgroup label="Special Interest">
                  {sortArray(groupedFields.si).map(
                    (field: CustomFieldType, i: number) => (
                      <option
                        key={"field-" + field.id + "-" + i}
                        value={field.field_id}
                      >
                        {field.label}
                      </option>
                    )
                  )}
                </optgroup>
                <optgroup label="State-Owned">
                  {sortArray(groupedFields.so).map(
                    (field: CustomFieldType, i: number) => (
                      <option
                        key={"field-" + field.id + "-" + i}
                        value={field.field_id}
                      >
                        {field.label}
                      </option>
                    )
                  )}
                </optgroup>
              </>
            )}

            <optgroup label="Risk Factors">
              {factors.map((risk) => (
                <option
                  key={"risk.factor." + risk.label}
                  value={"risk.factor." + risk.label}
                >
                  {risk.label}
                </option>
              ))}
            </optgroup>

            <optgroup label="Others">
              <option value="kyt">KYT custom value</option>
            </optgroup>
          </Select>
        )}
        {props.value?.entity === "transaction" && (
          <Select
            disabled={!props.editable}
            className="disabled:opacity-50 nowheel nodrag w-full rounded-none grow"
            placeholder="Select a field"
            defaultValue={sid || ""}
            onChange={(e) => {
              onChange({
                ...props.value,
                id: undefined,
                string_identifier: e.target.value,
              });
            }}
            name="field"
          >
            <option value="" className="text-gray-200" disabled selected>
              Select a field
            </option>
            {Object.keys(getTransactionFields()).map((key) => (
              <option key={"field" + key} value={key}>
                {key}
              </option>
            ))}
          </Select>
        )}
      </div>
      {useKytCustomValue && !loadingContexts && (
        <div className="flex relative focus:z-10 w-full grow">
          <div className="w-full grow m-2 rounded-md bg-blue-500 overflow-hidden">
            <div className="p-2 space-y-2">
              <Select
                defaultValue={sid?.split(".")[1] || "rolling"}
                onChange={(e) => {
                  onChange({
                    ...props.value,
                    string_identifier:
                      sid
                        ?.split(".")
                        .map((_, i) => (i === 1 ? e.target.value : _))
                        .map((_, i) =>
                          i === 2
                            ? e.target.value === "rolling"
                              ? "1m"
                              : 0
                            : _
                        )
                        .join(".") || defaultKYTSID,
                  });
                }}
              >
                <option value="rolling">Rolling</option>
                <option value="month">Month</option>
                <option value="year">Year</option>
              </Select>
              <KYTCustomSelect
                defaultValue="1m"
                index={2}
                onChange={(newSID: string) =>
                  onChange({ ...props.value, string_identifier: newSID })
                }
                options={[
                  ...((sid?.split(".")[1] === "rolling" &&
                    Array.from(Array(18)).map((_, i) => ({
                      label: i + 1 + "m",
                      value: i + 1 + "m",
                    }))) ||
                    []),
                  ...((sid?.split(".")[1] === "month" &&
                    Array.from(Array(18)).map((_, i) => ({
                      label: i > 0 ? `${i}m ago` : `Month to date`,
                      value: i.toString(),
                    }))) ||
                    []),
                  ...((sid?.split(".")[1] === "year" && [
                    { label: "Year to date", value: "0" },
                  ]) ||
                    []),
                ]}
                sid={sid || ""}
              />
              <KYTCustomSelect
                defaultValue="all"
                index={3}
                onChange={(newSID: string) =>
                  onChange({ ...props.value, string_identifier: newSID })
                }
                options={
                  contexts?.detected.map((context) => ({
                    label: context,
                    value: context,
                  })) || []
                }
                sid={sid || ""}
              />
              <KYTCustomSelect
                defaultValue="all"
                index={4}
                onChange={(newSID: string) =>
                  onChange({ ...props.value, string_identifier: newSID })
                }
                options={[
                  { label: "All methods", value: "all" },
                  { label: "Card", value: "card" },
                  { label: "Bank", value: "bank" },
                  { label: "Crypto", value: "crypto" },
                  { label: "Exchange", value: "exchange" },
                ]}
                sid={sid || ""}
              />
              <KYTCustomSelect
                defaultValue="1m"
                index={5}
                onChange={(newSID: string) =>
                  onChange({ ...props.value, string_identifier: newSID })
                }
                options={[
                  { label: "All directions", value: "all" },
                  { label: "In", value: "in" },
                  { label: "Out", value: "out" },
                ]}
                sid={sid || ""}
              />
              <KYTCustomSelect
                defaultValue="1m"
                index={6}
                onChange={(newSID: string) =>
                  onChange({ ...props.value, string_identifier: newSID })
                }
                options={[
                  { label: "All statuses", value: "all" },
                  { label: "Accepted", value: "accepted" },
                  { label: "Rejected", value: "rejected" },
                ]}
                sid={sid || ""}
              />
              <KYTCustomSelect
                defaultValue="1m"
                index={7}
                onChange={(newSID: string) =>
                  onChange({ ...props.value, string_identifier: newSID })
                }
                options={[
                  { label: "Volume (sum)", value: "sum" },
                  { label: "Count", value: "count" },
                  { label: "Max", value: "max" },
                  { label: "Min", value: "min" },
                ]}
                sid={sid || ""}
              />
            </div>
            <Base
              className="font-mono text-sm text-center w-full block py-2 px-2 text-white bg-blue-600 break-all	"
              noColor
            >
              {sid}
            </Base>
          </div>
        </div>
      )}
    </>
  );
};

export const getTransactionFields = () => {
  return {
    "transaction.converted_amount": "number",
    "transaction.external_id": "text",
    "transaction.amount": "number",
    "transaction.currency": "text",
    "transaction.converted_currency": "text",
    "transaction.blockchain": "text",
    "transaction.tx_hash": "text",
    "transaction.full_name": "text",
    "transaction.account_type": "text",
    "transaction.domicile_code": "text",
    "transaction.device.fingerprint": "text",
    "transaction.device.user_agent": "text",
    "transaction.device.language": "text",
    "transaction.device.geolocation": "text",
    "transaction.device.country": "text",
    "transaction.device.ip": "text",
    "transaction.device.session_id": "text",
    "transaction.device.session_duration": "number",
    "transaction.device.3ds": "boolean",
    "transaction.device.2fa": "boolean",
    "transaction.payment_institution.code": "text",
    "transaction.payment_institution.name": "text",
    "transaction.payment_institution.country": "text",
    "transaction.payment_method.type": "text",
    "transaction.payment_method.code": "text",
    "transaction.payment_method.country": "text",
  };
};

export const useGetFieldType = () => {
  const { fields, refresh } = useCustomFields();

  useEffect(() => {
    if (fields.length === 0) {
      refresh();
    }
  }, [fields.length, refresh]);

  return (field: RiskNodeField) => {
    if (field?.entity === "transaction") {
      return (
        (getTransactionFields() as any)[field.string_identifier || ""] || "text"
      );
    }

    const selectedField = fields.find((f) => f.field_id === field?.id);

    const isBooleanField = selectedField?.field_type === FIELD_TYPES.BOOLEAN;
    const isStringField = selectedField?.field_type === FIELD_TYPES.TEXT;

    return isBooleanField ? "boolean" : isStringField ? "text" : "number";
  };
};

const ensureValidity = (
  type: RiskResourceType,
  fields: CustomFieldType[],
  value: {
    id?: number;
    string_identifier?: string;
    entity: RiskNodeField["entity"];
  }
) => {
  if (!value.entity) {
    if (type === "kyt") {
      value = {
        ...value,
        entity: "transaction",
      };
    } else {
      value = {
        ...value,
        entity: "customer",
      };
    }
  }

  if (value.entity === "transaction") {
    if (!value.string_identifier) {
      console.log("change identifier");
      value = {
        ...value,
        id: undefined,
        string_identifier: "transaction.converted_amount",
      };
    }
  } else if (
    !value.id &&
    value.string_identifier?.indexOf("kyt") !== 0 &&
    !CUSTOM_CUSTOMER_INTERNAL_FIELD.includes(value.string_identifier || "")
  ) {
    value = {
      ...value,
      id: fields.find((a) => a.label === "external_id")?.field_id || 1,
      string_identifier: value.string_identifier?.includes("risk.factor.")
        ? value.string_identifier
        : undefined,
    };
  }

  return value;
};

const KYTCustomSelect = ({
  index,
  sid,
  defaultValue,
  options,
  onChange,
}: {
  index: number;
  sid: string;
  defaultValue: string;
  options: { value: string; label: string }[];
  onChange: (newSID: string) => void;
}) => (
  <Select
    defaultValue={sid?.split(".")[index] || defaultValue}
    onChange={(e) => {
      onChange(
        sid
          .split(".")
          .map((_, i) => (i === index ? e.target.value : _))
          .join(".") || defaultKYTSID
      );
    }}
  >
    {options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </Select>
);
