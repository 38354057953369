import Card from "@atoms/card";
import { Page } from "@atoms/layout/page";
import { FloatingTabs } from "@atoms/tabs";
import { Info, Section, Title } from "@atoms/text";
import Assignment from "@components/assignations";
import { PageLoader } from "@components/page-loader";
import { Table } from "@components/table";
import { useCustomerBy } from "@features/customers/state/use-customer-by";
import { QuestionType } from "@features/scenarios/types";
import { useSession } from "@features/sessions/state/use-session";
import { AlertType, Device } from "@features/sessions/types";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import FaceMatchReview from "./components/face-match-review";
import IdentityDocumentReview from "./components/identity-document-review";
import SessionCaptchaReview from "./components/session-captcha-review";
import SessionCustomerOverview from "./components/session-customer-overview";
import { SessionDocuments } from "./components/session-documents";
import { SessionInformationsReview } from "./components/session-informations-review";
import SessionLocationReview from "./components/session-location-review";
import ScenarioMonitoring from "./components/session-logs";
import SessionSummary from "./components/session-summary";
import WatchListReview from "./components/session-watchlist-review";
import { SessionDecisionsTimeline } from "./components/timeline";
import { SessionNavigation } from "./navigation";
import { copyToClipboard } from "@features/utils";
import { formatDate, formatTime } from "@features/utils/dates";
import { AnnotationProvider } from "@components/annotations/provider";

export default function SessionDetailsPage() {
  const { id } = useParams();
  const { session, loading, refresh } = useSession(id || "");
  const { customer } = useCustomerBy({
    external_ids: session?.session?.external_id || "",
  });

  useEffect(() => {
    try {
      refresh();
    } catch (e) {
      console.error(e);
    }
  }, [id, refresh]);

  if (!session && loading) return <PageLoader />;
  if (!session)
    return (
      <Page>
        <Info className="w-full h-full text-center">
          This session does not exist.
        </Info>
      </Page>
    );

  const finalDecision = ((session.decisions ?? []) as any).findLast(
    (d: any) => d.last
  );

  return (
    <Page>
      <Title className="flex flex-row mb-4">
        <div className="grow">Session details</div>
        <SessionNavigation id={id!} />
      </Title>
      <AnnotationProvider>
        <SessionSummary
          id={id}
          documentInformation={
            session.alerts
              .find((a) => a.code === AlertType.Identity)
              ?.identity_contents?.at(-1)?.document_detail
          }
          sessionCustomer={session.onboarding_customer}
          details={session.session}
          scoring={session.scoring}
          decision={finalDecision}
          alertsSummary={session.alerts as any}
          scoringPreferences={session.scoring_preferences_used}
        />

        {session.session?.state?.toLocaleLowerCase() !== "completed" && (
          <Card
            className="bg-slate-400 mt-4"
            text={
              <div className="text-white text-center flex flex-row items-center justify-center">
                <InformationCircleIcon className="h-5 w-5 inline mr-2" />
                This session is not completed yet.
              </div>
            }
          />
        )}

        <div className="mt-4 flex flex-row h-full space-x-2">
          <div className="w-1/4 min-w-1/4 flex flex-col space-y-4">
            <Assignment
              id={id!}
              type="session"
              groups={session?.review_groups || []}
            />
            <SessionDecisionsTimeline
              session_id={session.session?.session_id}
            />
          </div>
          <div className="grow mt-4 flex flex-col space-y-4">
            <div className="flex flex-col space-y-2">
              <FloatingTabs
                value="jump"
                onChange={(tab) => {
                  // Find the element by ID
                  const element = document.getElementById(tab as string);
                  if (element) {
                    // Find closest "overflow-auto" parent
                    const scrollable = element.closest(".overflow-auto");
                    if (scrollable) {
                      // Get the element's position relative to the top of the page
                      const offsetTop = element.offsetTop;

                      // Scroll to 100px before the element smoothly
                      scrollable.scrollTo({
                        top: offsetTop - 120, // Adjust by 100px
                        behavior: "smooth",
                      });
                    }
                  }
                }}
                tabs={[
                  ...(session.alerts.find((a) => a.code === "identity")?.score
                    ? ([
                        {
                          value: "identity-question",
                          label: "Identity",
                        },
                      ] as any)
                    : []),
                  ...(session.alerts.find((a) => a.code === "face")?.score
                    ? ([
                        {
                          value: "face-question",
                          label: "Face",
                        },
                      ] as any)
                    : []),
                  ...(session.questions.filter(
                    (a) => a.type === QuestionType.Information
                  ).length > 0
                    ? ([
                        {
                          value: "information-question",
                          label: "Scenario",
                        },
                      ] as any)
                    : []),
                  ...(session.documents.length > 0
                    ? ([
                        {
                          value: "documents",
                          label: "Documents",
                        },
                      ] as any)
                    : []),
                  ...(session.scoring_preferences_used?.watchlist?.enabled
                    ? ([
                        {
                          value: "watchlist",
                          label: "Watchlist",
                        },
                      ] as any)
                    : []),

                  ...(session.scoring_preferences_used?.geolocation?.enabled
                    ? ([
                        {
                          value: "location",
                          label: "Location",
                        },
                      ] as any)
                    : []),
                  ...(session.scoring_preferences_used?.captcha?.enabled
                    ? ([
                        {
                          value: "captcha",
                          label: "Captcha",
                        },
                      ] as any)
                    : []),
                  {
                    value: "customer",
                    label: "Customer",
                  },
                ]}
              />

              {session.alerts.find((a) => a.code === "identity")?.score !==
                undefined && (
                <IdentityDocumentReview
                  alert={session.alerts.find((a) => a.code === "identity")!}
                  anonymized={session.session.anonymized}
                />
              )}
              {session.alerts.find((a) => a.code === "face")?.score !==
                undefined && (
                <FaceMatchReview
                  alert={session.alerts.find((a) => a.code === "face")!}
                  anonymized={session.session.anonymized}
                />
              )}
              {session.questions.filter(
                (a) => a.type === QuestionType.Information
              ).length > 0 && (
                <SessionInformationsReview
                  alert={
                    session.alerts.find(
                      (a) => a.code === AlertType.Information
                    )!
                  }
                />
              )}
              {session.documents.length > 0 && (
                <SessionDocuments
                  documents={session.documents}
                  alert={session.alerts.find(
                    (a) => a.code === AlertType.FileUpload
                  )}
                />
              )}
              {session.scoring_preferences_used?.watchlist?.enabled && (
                <WatchListReview
                  alert={session.alerts.find((a) => a.code === "watchlist")!}
                  sessionCustomer={session.onboarding_customer}
                  scoringPreferences={session.scoring_preferences_used}
                />
              )}
              {session.scoring_preferences_used?.geolocation?.enabled && (
                <SessionLocationReview
                  alert={session.alerts.find((a) => a.code === "location")!}
                  anonymized={session.session.anonymized}
                  ip={session.session.ip}
                />
              )}
              {session.scoring_preferences_used?.captcha?.enabled && (
                <SessionCaptchaReview
                  alert={session.alerts.find((a) => a.code === "captcha")!}
                />
              )}
            </div>

            {customer?.data?.id && (
              <SessionCustomerOverview customer_id={customer?.data?.id} />
            )}

            {session.phone_verifed && (
              <div className="flex flex-col space-y-2">
                <Section>Phone logs</Section>
                <SessionPhoneOverview phoneVerifed={session.phone_verifed} />
              </div>
            )}

            <div className="flex flex-col space-y-2">
              <Section>Devices logs</Section>
              <SessionDevicesOverview devices={session.devices} />
            </div>

            <div className="flex flex-col space-y-2">
              <Section>Session logs</Section>
              <ScenarioMonitoring monitorings={session.monitoring} />
            </div>
          </div>
        </div>
      </AnnotationProvider>
    </Page>
  );
}

const SessionDevicesOverview = ({ devices }: { devices: Device[] }) => {
  const columns = [
    {
      title: "browser_name",
      render: (item: Device) => (
        <div className="flex items-center">{item.browser_name}</div>
      ),
    },
    {
      title: "browser_version",
      render: (item: Device) => (
        <div className="flex items-center">{item.browser_version}</div>
      ),
    },
    {
      title: "plateform_os",
      render: (item: Device) => (
        <div className="flex items-center">{item.plateform_os}</div>
      ),
    },
    {
      title: "created_at",
      render: (item: Device) =>
        item.created_at ? (
          <div className="flex items-center">
            {formatDate(item.created_at)} {formatTime(item.created_at)}
          </div>
        ) : (
          "-"
        ),
    },
    {
      title: "",
      render: (item: Device) => (
        <div
          className="bg-slate-100 hover:bg-slate-300 rounded-md p-1 cursor-pointer"
          onClick={() => {
            copyToClipboard(
              `${item.browser_name} version(${item.browser_version}), ${item.plateform_os}`
            );
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
            />
          </svg>
        </div>
      ),
    },
  ];

  return (
    <Table columns={columns} data={devices || []} showPagination={false} />
  );
};

const SessionPhoneOverview = ({ phoneVerifed }: { phoneVerifed: string }) => {
  const columns = [
    {
      title: "phone_verifed",
      render: (item: string) => <div className="flex items-center">{item}</div>,
    },
    {
      title: "",
      render: (item: string) => (
        <div
          className="bg-slate-100 hover:bg-slate-300 rounded-md p-1 cursor-pointer"
          onClick={() => {
            copyToClipboard(`${item}`);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
            />
          </svg>
        </div>
      ),
    },
  ];

  return (
    <Table columns={columns} data={[phoneVerifed]} showPagination={false} />
  );
};
