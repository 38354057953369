import { ROUTES } from "@features/routes";
import { isEmbed } from "@features/utils/use-embed-utils";
import { Modals } from "@views/modals";
import { Navigate, Outlet, Route } from "react-router-dom";
import { Header } from "./_layout/header";
import { Sidebar } from "./_layout/sidebar";
import { AdministrationPage } from "./account/administration";
import ReportingPage from "./account/audit-and-reporting";
import { BillingPage } from "./account/billing";
import { UsersManagementPage } from "./account/users-management";
import { CustomersDetailsPage } from "./customers/customer-details";
import { CustomersListPage } from "./customers/customers";
import { CustomerImportPage } from "./customers/import";
import { DashboardPage } from "./dashboard";
import InboxPage from "./inbox";
import KytConfigurationPage from "./kyt/configurations";
import KytExplorePage, { ExploreFromTransaction } from "./kyt/explore";
import { KytRulesPage } from "./kyt/rules/index";
import { KytRulePage } from "./kyt/rules/rule";
import SavedSearchesPage from "./kyt/saved-searches";
import LiveTransactionsPage from "./kyt/transactions";
import TransactionDetailsPage from "./kyt/transactions/transaction-details";
import PressInstantReportPage from "./press/instant-report";
import PressReportDetailsPage from "./press/report-details";
import { PressReportsListPage } from "./press/reports";
import { PressSettingsPage } from "./press/settings";
import { PressDecisionTreePage } from "./press/settings/decision-tree";
import { RiskFactorsPage } from "./risk";
import CustomerRiskFactorsPage from "./risk/single-risk";
import { AlertsDetailsPage } from "./scan/alert-details";
import { AlertsListPage } from "./scan/alerts";
import ScanDecisionTreePage from "./scan/decision-tree";
import { NameSearchPage } from "./scan/name-search";
import { PostFilteringListPage } from "./scan/post-filtering";
import { PostFilteringEditPage } from "./scan/post-filtering/edit";
import { CustomFieldsPage } from "./settings/custom-fields";
import { CustomMatricesPage } from "./settings/custom-matrices";
import { DevelopersPage } from "./settings/developers";
import { DocumentTagsSetup } from "./settings/document-tags";
import InboxSettingsPage from "./settings/inbox-settings";
import InboxTemplatesPage from "./settings/inbox-templates";
import { MessageTagsSetup } from "./settings/messages-tags";
import { SessionStatusSetupPage } from "./settings/session-status";
import ScenarioDetailsPage from "./vid/scenario-details";
import ScenarioIntegrationSettings from "./vid/scenario-integration-settings";
import ScenariosList from "./vid/scenarios";
import SessionDetailsPage from "./vid/session-details";
import SessionsListPage from "./vid/sessions";
import { IdentityCheckPage } from "./vid/identity-check";

export const BackOfficeRoutes = () => {
  return (
    <Route element={<Layout />}>
      <Route path={ROUTES.Dashboard} element={<DashboardPage />} />
      <Route path={ROUTES.Customers} element={<CustomersListPage />} />
      <Route path={ROUTES.CustomerManager} element={<CustomerImportPage />} />
      <Route path={ROUTES.CustomerView} element={<CustomersDetailsPage />} />
      <Route path={ROUTES.Alerts} element={<AlertsListPage />} />
      <Route path={ROUTES.AlertView} element={<AlertsDetailsPage />} />
      <Route path={ROUTES.NameSearch} element={<NameSearchPage />} />
      <Route path={ROUTES.PostFiltering} element={<PostFilteringListPage />} />
      <Route
        path={ROUTES.PostFilteringView}
        element={<PostFilteringEditPage />}
      />
      <Route
        path={ROUTES.ScanDecisionTree}
        element={<ScanDecisionTreePage />}
      />
      <Route path={ROUTES.Sessions} element={<SessionsListPage />} />
      <Route path={ROUTES.SessionView} element={<SessionDetailsPage />} />
      <Route path={ROUTES.IdentityCheck} element={<IdentityCheckPage />} />
      <Route path={ROUTES.Scenarios} element={<ScenariosList />} />
      <Route path={ROUTES.ScenarioView} element={<ScenarioDetailsPage />} />
      <Route
        path={ROUTES.ScenarioIntegrationSettings}
        element={<ScenarioIntegrationSettings />}
      />
      <Route path={ROUTES.Inbox} element={<InboxPage />} />
      <Route
        path={ROUTES.InboxHome}
        element={<Navigate to={ROUTES.Inbox.replace(/:id/, "all")} />}
      />
      <Route path={ROUTES.InboxTemplates} element={<InboxTemplatesPage />} />
      <Route
        path={ROUTES.LiveTransactions}
        element={<LiveTransactionsPage />}
      />
      <Route
        path={ROUTES.TransactionView}
        element={<TransactionDetailsPage />}
      />
      <Route path={ROUTES.Explore} element={<KytExplorePage />} />
      <Route
        path={ROUTES.ExploreTransaction}
        element={<ExploreFromTransaction />}
      />
      <Route path={ROUTES.SavedSearches} element={<SavedSearchesPage />} />
      <Route path={ROUTES.KytRules} element={<KytRulesPage />} />
      <Route path={ROUTES.KytRuleDetails} element={<KytRulePage />} />
      <Route
        path={ROUTES.KytConfiguration}
        element={<KytConfigurationPage />}
      />
      <Route
        path={ROUTES.DeprecatedImportSetup}
        element={<CustomFieldsPage />}
      />
      <Route path={ROUTES.CustomFields} element={<CustomFieldsPage />} />
      <Route path={ROUTES.Matrix} element={<CustomMatricesPage />} />
      <Route path={ROUTES.DocumentTagsSetup} element={<DocumentTagsSetup />} />
      <Route
        path={ROUTES.InboxMessageTagsSetup}
        element={<MessageTagsSetup />}
      />
      <Route
        path={ROUTES.InboxMessageSetting}
        element={<InboxSettingsPage />}
      />
      <Route
        path={ROUTES.SessionStatusSetup}
        element={<SessionStatusSetupPage />}
      />
      <Route path={ROUTES.Developers} element={<DevelopersPage />} />
      <Route path={ROUTES.Agents} element={<UsersManagementPage />} />
      <Route path={ROUTES.CustomerRiskFactors} element={<RiskFactorsPage />} />
      <Route
        path={ROUTES.CustomerRiskFactorsDetails}
        element={<CustomerRiskFactorsPage />}
      />
      <Route path={ROUTES.Reporting} element={<ReportingPage />} />
      <Route path={ROUTES.Administration} element={<AdministrationPage />} />
      <Route path={ROUTES.Billing} element={<BillingPage />} />
      <Route
        path={ROUTES.PressInstantReport}
        element={<PressInstantReportPage />}
      />
      <Route
        path={ROUTES.PressDecisionTree}
        element={<PressDecisionTreePage />}
      />
      <Route path={ROUTES.PressSettings} element={<PressSettingsPage />} />
      <Route
        path={ROUTES.PressReportsSearch}
        element={<PressReportsListPage />}
      />
      <Route
        path={ROUTES.PressReportView}
        element={<PressReportDetailsPage />}
      />
    </Route>
  );
};

export const Layout = () => {
  // Get ?embed=1 from URL
  const embed = isEmbed();

  if (embed) return <Outlet />;

  return (
    <div className="flex w-full grow flex-col bg-slate-50 dark:bg-slate-900 h-full embed-apply-post-css">
      <Header />
      <div className="grow flex min-h-0">
        <Sidebar />
        <div className="grow min-h-0 overflow-auto embed-apply-post-css">
          <Outlet />
        </div>
      </div>
      <div className="relative z-20">
        <Modals />
      </div>
    </div>
  );
};
