import { FieldAllowedOp } from "@components/search-bar/utils/types";
import { CustomerStateType } from "@features/risk-decisions/utils";
import { CustomerRiskStatus, RiskType } from "./utils";

export type CustomerSearchField = {
  allowed_ops: FieldAllowedOp[];
  display_name: string;
  external_key: string;
  possible_additional_keys: {
    display_name: string;
    key: string;
    value_type: "string" | "number" | "boolean" | "mixed" | "date";
  }[];
  possible_values: {
    label: string;
    value: string;
  }[];
  value_type: "string" | "number" | "boolean" | "date" | "mixed";
};

export type CustomerAllDetailType = {
  details: CustomerDetailType;
  risk_factors: CustomerRiskFactorsType;
  edd_history: CustomerEddHistoryType;
};

export enum KYCLevelType {
  KYC_LEVEL_UNKNOWN = 0,
  KYC_LEVEL_DECLARATIVE = 1,
  KYC_LEVEL_VERIFIED = 2,
  KYC_LEVEL_AUTHENTIFIED0 = 3,
  KYC_LEVEL_AUTHENTIFIED1 = 4,
  KYC_LEVEL_AUTHENTIFIED2 = 5,
  KYC_LEVEL_AUTHENTIFIED3 = 6,
}

export type KYCLevel = { label: string; color: string; type: KYCLevelType };

// Just put a colour because that's how it's used afterwards "className={’bg-’ + color}"
export const KYC_LEVEL: { [key: number]: KYCLevel } = {
  [KYCLevelType.KYC_LEVEL_UNKNOWN]: {
    label: "None",
    color: "slate-500",
    type: KYCLevelType.KYC_LEVEL_UNKNOWN,
  },
  [KYCLevelType.KYC_LEVEL_DECLARATIVE]: {
    label: "Déclaratif",
    color: "red-600",
    type: KYCLevelType.KYC_LEVEL_DECLARATIVE,
  },
  [KYCLevelType.KYC_LEVEL_VERIFIED]: {
    label: "Vérifié",
    color: "yellow-600",
    type: KYCLevelType.KYC_LEVEL_VERIFIED,
  },
  [KYCLevelType.KYC_LEVEL_AUTHENTIFIED0]: {
    label: "Authentifié",
    color: "green-600",
    type: KYCLevelType.KYC_LEVEL_AUTHENTIFIED0,
  },
  [KYCLevelType.KYC_LEVEL_AUTHENTIFIED1]: {
    label: "Authentifié Bronze",
    color: "green-600",
    type: KYCLevelType.KYC_LEVEL_AUTHENTIFIED1,
  },
  [KYCLevelType.KYC_LEVEL_AUTHENTIFIED2]: {
    label: "Authentifié Argent",
    color: "green-600",
    type: KYCLevelType.KYC_LEVEL_AUTHENTIFIED2,
  },
  [KYCLevelType.KYC_LEVEL_AUTHENTIFIED3]: {
    label: "Authentifié Or",
    color: "green-600",
    type: KYCLevelType.KYC_LEVEL_AUTHENTIFIED3,
  },
};

export type CustomerDetailType = {
  custom_fields: {
    fieldtype: number;
    name: string;
    value: any;
    id: number;
  }[];
  customer: {
    id: string;
    disabled: boolean;
    client_id: string;
    parent_external_id: string;
    external_id: string;
    alias_ids: string[] | null;
    account_number: string;
    revision_number: string;
    customer_type: string;
    first_name: string;
    middle_name: string;
    last_name: string;
    date_of_birth: string;
    language: string;
    nationality_code: string;
    nationality_codes: string[];
    domicile_code: string;
    domicile_codes: string[];
    company_name: string;
    trading_name: string;
    registration_number: string;
    account_type: number;
    contacts: string;
    phone: string;
    review_groups: string[] | null;
    created_at: string;
    created_by: number;
    custom_fields: any;
    name_variations: {
      first_name?: string;
      middle_name?: string;
      last_name?: string;
      date_of_birth?: string;
      company_name?: string;
      trading_name?: string;
    }[];
    kyc_level: KYCLevelType;
  };
  overall_risk: CustomerRiskStatus;

  //Computed fields
  may_be_pep: ComputedFieldValue;
  may_be_san: ComputedFieldValue;
  pep: ComputedFieldValue;
  str: ComputedFieldValue;
  se: ComputedFieldValue;
  tm: ComputedFieldValue;
  open_chat_categories: ComputedFieldValue;
  closed_chat_categories: ComputedFieldValue;
  vid_failed: ComputedFieldValue;
  vid_success: ComputedFieldValue;
  vid_outdated: ComputedFieldValue;
  vid_in_progress: ComputedFieldValue;

  edd_status: {
    id: number;
    agent_name: string;
    revision_number: number;
    edd_state: number;
    comment: string;
    customer_id: string;
    client_id: number;
    created_at: string;
    next_review_date: string;
  };
};

type ComputedFieldValue = {
  name: string;
  field_type: number;
  value: boolean | number | string;
};

export type CustomerRiskFactorsType = {
  risk_factors: {
    id: number;
    label: string;
    risk: CustomerRiskStatus;
    fields: {
      id: number;
      name: string;
      value: string | number | boolean;
      field_type: number;
    }[];
  }[];
  total_number_of_risk_factors: number;
};

export type CustomerEddHistoryType = {
  edd_revisions: CustomerEddRevision[];
  totalnumberofrevisions: number;
};

export type CustomerEddRevision = {
  id: number;
  agent_name: string;
  revision_number: number;
  edd_state: number;
  comment: string;
  customer_id: string;
  client_id: number;
  created_at: string;
  next_review_date: string;
};

export type CustomerType = {
  id: string | null;
  alias_ids?: string[];
  customer_id: string;
  customer_revision_id: string;
  external_id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  risk: CustomerRiskStatus;
  account_type: number;
  language: string;
  domicile_code: string;
  nationality_code: string;
  company_name: string;
  trading_name: string;
  concat_name: string;
  edd_state: number;
  full_names: string;
  review_groups: string[] | null;
  disabled: boolean;
};

export type CustomerSearchType = Omit<CustomerType, "edd_state"> & {
  edd_state: CustomerStateType;
  overall_risk: RiskType;
  risk_factors: { [key: string]: RiskType };
  customer_last_updated_at: number;
  edd_last_updated_at: number;
  edd_comment: string;
  date_of_birth: number;
  custom_fields: { [key: string]: string };
  computed_fields: {
    vid_failed: string;
    vid_success: string;
    vid_outdated: string;
    vid_in_progress: string;
    open_chat_categories: string;
    closed_chat_categories: string;
    is_pep: boolean;
    is_san: boolean;
    may_be_pep: boolean;
    may_be_san: boolean;
    [key: string]: boolean | number | string;
  };
  contacts: string[];
  kyt_data: {
    [context: string]: {
      in: { [key: string]: number };
      out: { [key: string]: number };
      all: { [key: string]: number };
    };
  };
  kyc_level: string;
};

export type CachedCustomerType = {
  contacts: string;
  customer_id: string;
  external_id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  account_type: number;
  language: string;
  domicile_code: string;
  date_of_birth: string;
  nationality_code: string;
  company_name: string;
  trading_name: string;
  review_groups: string[] | null;
  custom_fields?: any;
  disabled: boolean;
  details?: CustomerDetailType;
};

// Customer relationship
export type CustomerRelationships = {
  child_relations: CustomerRelation[];
  parent_relations: CustomerRelation[];
};

export type CustomerRelation = {
  relation_external_id: string;
  relation_type: number;
  customer_risk: number;
  customer: RelatedCustomer;
};

export type ChildParentCustomerRelation = {
  relation_external_id: string;
  relation_type: number;
  parent: { customer: RelatedCustomer; risk: CustomerRiskStatus };
  child: { customer: RelatedCustomer; risk: CustomerRiskStatus };
};

export type RelatedCustomer = {
  id: string;
  client_id: string;
  external_id: string;
  account_number: string;
  revision_number: string;
  customer_type: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  date_of_birth: string;
  nationality_code: string;
  domicile_code: string;
  company_name: string;
  trading_name: string;
  registration_number: string;
  account_type: number;
  review_groups: string[] | null;
  created_at: string;
  customer_risk: number;
};

export type UpdateCustomersRequest = {
  verify_input: boolean;
  disable_risk_scan: boolean;
  customers: {
    //Common fields
    account_type: "F" | "N" | "S" | "U";
    external_id: string;
    parent_external_id: string;
    review_groups: string;

    //Individual fields
    first_name: string;
    middle_name: string;
    last_name: string;
    date_of_birth: string;
    nationality_codes: string[];
    domicile_codes: string[];

    //Company fields
    account_number: string;
    company_name: string;
    trading_name: string;
    registration_number: string;

    contacts: string;
    phone: string;

    variations: {
      //Complete API
      first_name?: string;
      middle_name?: string;
      last_name?: string;
      company_name?: string;
      trading_name?: string;
    }[];
    fields: {
      [key: string]: string;
    };

    comment?: string;
    disabled?: boolean;
  }[];
  relations: {
    relation_external_id: string;
    relation_type: string;
    parent_customer_external_id: string;
    child_customer_external_id: string;
    fields: {
      [key: string]: any;
    };
  }[];
};

export type CustomerSharedField = {
  edd_state: string;
  kyc_level: string;
};

export type UpdateCustomerSocketEventData = {
  client_id: number;
  update_by_agent_id: number;
  customer_id: string;
};
