import { Info, SectionSmall } from "@atoms/text";
import { ModalImage } from "@components/modal-image";
import type { QuestionAnswerAttachmentType } from "./faces/score";
import { getAnswersAttachementUrl } from "./utils";

type FaceScoreProps = {
  answers_attachment_urls?: QuestionAnswerAttachmentType[] | string[];
  type?: "url" | "base64";
};

const IdentityDocuments = ({
  answers_attachment_urls,
  type = "url",
}: FaceScoreProps) => {
  const answersAttachmentUrls = answers_attachment_urls;
  const answersAttachmentListUrls = getAnswersAttachementUrl(
    type,
    answersAttachmentUrls || []
  );

  return (
    <div className="flex flex-col space-y-2">
      <SectionSmall>Identity Documents</SectionSmall>
      <Info>Document image taken during session.</Info>
      <div className="flex flex-row space-x-2 overflow-x-auto py-2">
        {answersAttachmentUrls?.length === 0 && (
          <Info>No identity document found</Info>
        )}
        {answersAttachmentListUrls?.map((url, index) => (
          <div key={index}>
            <ModalImage
              className="w-auto h-32 object-cover rounded-md border border-slate-300"
              small={url}
              large={url}
              alt={"image" + index}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default IdentityDocuments;
