import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import Tabs from "@atoms/tabs";
import { Info, Title } from "@atoms/text";
import { PageLoader } from "@components/page-loader";
import { ROUTES } from "@features/routes";
import { useScenario } from "@features/scenarios/state/use-scenario";
import { ScenarioType } from "@features/scenarios/types";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { Bars4Icon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { CreateSessionModalAtom } from "./components/create-session-modal";
import ScenarioIntegrationTab from "./components/integration-tab";
import ScenarioTab from "./components/scenario-tab";
import ScenarioScoringTab from "./components/scoring-tab";

export default function ScenarioDetailsPage() {
  const { id } = useParams();
  const {
    scenario: scenarioAPI,
    loading,
    save,
    refresh,
  } = useScenario(id ?? "");
  const [tab, setTab] = useState<"scenario" | "scoring" | "integration">(
    "scenario"
  );

  const setModal = useSetRecoilState(CreateSessionModalAtom);

  const [scenario, setScenario] = useState<ScenarioType>();

  useEffect(() => {
    setScenario(scenarioAPI);
  }, [scenarioAPI]);

  const hasChanges = useCallback(() => {
    return !_.isEqual(scenario, scenarioAPI);
  }, [scenario, scenarioAPI]);

  if (!scenarioAPI && loading) return <PageLoader />;

  return (
    <Page>
      <div className="float-right">
        <div className="m-auto inline-flex items-center">
          <Link className="flex" to={ROUTES.Scenarios}>
            <Button size="sm" theme="outlined">
              <Bars4Icon className="h-5 w-5 mr-2" /> Back to all scenarios
            </Button>
          </Link>
        </div>
      </div>
      {!scenario && (
        <Info className="w-full text-center">
          This scenario does not exist.
        </Info>
      )}
      {!!scenario && (
        <>
          <div className="flex flex-row justify-between pr-4">
            <Title>Scenario '{scenario?.label}'</Title>
            <div className="flex flex-row space-x-2">
              {hasChanges() && (
                <Button
                  theme="primary"
                  size="sm"
                  onClick={async () => {
                    try {
                      await save(scenario);
                      toast.success("Scenario saved successfully");
                      refresh();
                    } catch (e) {
                      console.error(e);
                      toast.error("Failed to save scenario");
                    }
                  }}
                >
                  <PencilSquareIcon className="h-5 w-5 mr-2" />
                  Save Changes
                </Button>
              )}
              <Button
                theme="outlined"
                size="sm"
                onClick={() => {
                  setModal({
                    open: true,
                    showScenarioSelector: false,
                    session: {
                      scenario_code: scenario?.code ?? "",
                      external_id: "",
                      language: scenario?.language ?? "",
                    },
                  });
                }}
              >
                Generate a session
              </Button>
            </div>
          </div>
          <Tabs
            className="mb-5"
            value={tab}
            onChange={(e) => {
              setTab(e as any);
            }}
            tabs={[
              {
                value: "scenario",
                label: "Configuration",
              },
              {
                value: "scoring",
                label: "Scoring",
              },
              {
                value: "integration",
                label: "Integration",
              },
            ]}
          ></Tabs>
          <div className="flex flex-row w-full">
            {tab === "scenario" && (
              <ScenarioTab
                scenario={scenario}
                onChange={(sc: ScenarioType) => {
                  setScenario({
                    ...sc,
                  });
                }}
              />
            )}
            {tab === "scoring" && (
              <ScenarioScoringTab
                scenario={scenario}
                onChange={(sc: ScenarioType) => {
                  setScenario({
                    ...sc,
                  });
                }}
              />
            )}
            {tab === "integration" && (
              <ScenarioIntegrationTab
                scenario={scenario}
                onChange={(sc: ScenarioType) => {
                  setScenario({
                    ...sc,
                  });
                }}
              />
            )}
          </div>
        </>
      )}
    </Page>
  );
}
