import { Tag } from "@atoms/badge/tag";
import { Loader } from "@atoms/loader";
import { Base, Info } from "@atoms/text";
import { AlertCategories } from "@components/alert-categories";
import { useCustomerReviewAlert } from "@features/alerts/state/use-customer-review";
import { defaultAlertStates } from "@features/alerts/utils";
import { CustomerAllDetailType } from "@features/customers/types";
import { useControlledEffect } from "@features/utils";

export const ScanReviewContent = ({
  customer,
}: {
  customer: CustomerAllDetailType;
}) => {
  const { review: alertReview, refresh: refreshAlertsReview } =
    useCustomerReviewAlert(customer?.details.customer.external_id || "");

  useControlledEffect(() => {
    refreshAlertsReview();
  }, []);

  const alertsTrueHits = [
    ...(alertReview.per_state[defaultAlertStates.true_hit] || []),
    ...(alertReview.per_state[defaultAlertStates.true_hit_rejected] || []),
    ...(alertReview.per_state[defaultAlertStates.true_hit_accepted] || []),
  ];

  return (
    <>
      {alertReview.loading && <Loader />}
      {!alertReview.loading && (
        <>
          {(!alertReview.total ||
            (Object.keys(alertReview.per_state).length === 1 &&
              parseInt(Object.keys(alertReview.per_state)[0]) ===
                defaultAlertStates.false_positive)) && (
            <>
              <Tag
                noColor
                className={
                  "text-white capitalize block !px-3 !py-1 text-md bg-green-500"
                }
              >
                Not found
              </Tag>

              <Info className="block mt-2">
                {!(
                  Object.keys(alertReview.per_state).length === 1 &&
                  parseInt(Object.keys(alertReview.per_state)[0]) ===
                    defaultAlertStates.false_positive
                ) && <>No screening match for this customer.</>}
                {Object.keys(alertReview.per_state).length === 1 &&
                  parseInt(Object.keys(alertReview.per_state)[0]) ===
                    defaultAlertStates.false_positive && (
                    <>This customer found but marked as a false positive.</>
                  )}
              </Info>
            </>
          )}

          {alertsTrueHits.length > 0 && (
            <>
              <div className="mt-1" />
              <AlertCategories
                isCategories={alertsTrueHits.reduce(
                  (a: any, acc: any) =>
                    ({
                      is_pep: a.is_pep || acc.is_pep,
                      is_sip: a.is_sip || acc.is_sip,
                      is_sie: a.is_sie || acc.is_sie,
                      is_rca: a.is_rca || acc.is_rca,
                      is_sop: a.is_sop || acc.is_sop,
                      is_soe: a.is_soe || acc.is_soe,
                      is_ame: a.is_ame || acc.is_ame,
                      is_rca_sip: a.is_rca_sip || acc.is_rca_sip,
                      is_rca_sie: a.is_rca_sie || acc.is_rca_sie,
                      is_rca_pep: a.is_rca_pep || acc.is_rca_pep,
                      is_rca_sop: a.is_rca_sop || acc.is_rca_sop,
                      is_rca_soe: a.is_rca_soe || acc.is_rca_soe,
                      is_rca_san: a.is_rca_san || acc.is_rca_san,
                      is_san: a.is_san || acc.is_san,
                    } as any),
                  alertsTrueHits[0]
                )}
              />

              <Base className="block mt-1">
                According to {alertsTrueHits.length} hit(s) marked as true
                hit(s).{" "}
                {((alertReview.per_state[defaultAlertStates.true_hit_accepted]
                  ?.length || 0) > 0 ||
                  (alertReview.per_state[defaultAlertStates.true_hit_rejected]
                    ?.length || 0) > 0) && (
                  <>
                    (
                    {alertReview.per_state[defaultAlertStates.true_hit_accepted]
                      ?.length || 0}{" "}
                    accepted,{" "}
                    {alertReview.per_state[defaultAlertStates.true_hit_rejected]
                      ?.length || 0}{" "}
                    refused).
                  </>
                )}
              </Base>
            </>
          )}

          {(alertReview.per_state[defaultAlertStates.new]?.length || 0) > 0 && (
            <div className="p-2 bg-slate-500/10 rounded-md border mt-2">
              <span>
                <Tag>
                  <span className="inline-flex h-3 w-3 relative">
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-orange-400"></span>
                  </span>{" "}
                  New / Pending
                </Tag>{" "}
                <Info>
                  You have{" "}
                  {(alertReview.per_state[defaultAlertStates.new]?.length ||
                    0) +
                    (alertReview.per_state[defaultAlertStates.pending]
                      ?.length || 0)}{" "}
                  hit(s) pending additional information or not yet reviewed.
                </Info>
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
};
