import { DoughnutChart } from "@components/dognut-chart";
import {
  CUSTOMERS_EDD_STATES,
  CUSTOMERS_EDD_STATES_COLORS,
} from "@features/customers/utils";
import { useDashboard } from "@features/monitoring/state/use-dashboard";
import { formatNumber } from "@features/utils";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const RiskChart = () => {
  const { risk } = useDashboard();

  const riskInfoAssignment = Object.values(
    risk?.global_risk_info.assigned_edd_states || {}
  ).filter((a) => a.value > 0);

  const riskInfoEdd = Object.values(
    risk?.global_risk_info.edd_states || {}
  ).filter((a) => a.value > 0);

  return (
    <>
      <div className="w-80 h-40">
        <DoughnutChart
          options={{
            animations: false as any,
            plugins: {
              legend: {
                position: "right",
                labels: {
                  usePointStyle: true,
                },
              },
            },
            maintainAspectRatio: false,
          }}
          data={{
            labels: riskInfoEdd.map(
              (a) =>
                `${
                  (CUSTOMERS_EDD_STATES as any)[a.id] || "Unknown"
                }: ${formatNumber(a.value)}`
            ),
            datasets: [
              ...(!riskInfoEdd.length
                ? [
                    {
                      label: "No data",
                      backgroundColor: ["rgba(220,220,220,1.0)"],
                      data: [1],
                    },
                  ]
                : []),
              {
                data: riskInfoEdd.map((a) => a.value),
                backgroundColor: riskInfoEdd.map(
                  (a) => (CUSTOMERS_EDD_STATES_COLORS as any)[a.id] || "#000000"
                ),
              },
              {
                label: "Assigned",
                data: riskInfoAssignment.map((a) => a.value),
                backgroundColor: riskInfoAssignment.map(
                  (a) => (CUSTOMERS_EDD_STATES_COLORS as any)[a.id] || "#000000"
                ),
              },
            ],
          }}
        />
      </div>
    </>
  );
};
