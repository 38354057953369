import { Tag } from "@atoms/badge/tag";
import { PageBlockHr } from "@atoms/layout/page-block";
import { Base, Info, SectionSmall } from "@atoms/text";
import Env from "@config/environment";
import { SessionAlertType } from "@features/sessions/types";
import { flatMapControls } from "@features/sessions/utils";
import { twMerge } from "tailwind-merge";
import { AlertTag } from "./alert-tag";
import { SessionBlock } from "./session-block";
import IdentityDocumentDetails from "@components/vid/document-details";
import { Controls } from "@components/vid/controls";
import IdentityDocuments from "@components/vid/identity-documents";

export default function IdentityDocumentReview({
  anonymized,
  ...props
}: {
  alert: SessionAlertType;
  anonymized: boolean;
}) {
  return (
    <SessionBlock
      id="identity-question"
      title="Identity Document"
      subtitle="Identity Document verification and extraction"
      suffix={<AlertTag state={props.alert.state} />}
      tries={props.alert.questions?.length}
      render={(tryIndex) => {
        const holder_detail =
          props.alert.identity_contents?.[tryIndex as number]?.holder_detail;
        const document_detail =
          props.alert.identity_contents?.[tryIndex as number]?.document_detail;
        return (
          <>
            {props.alert.score !== undefined && (
              <>
                {props.alert.questions?.[tryIndex as number]?.answers
                  ?.videos[0] &&
                  !anonymized && (
                    <>
                      <div className="flex flex-wrap gap-4 mt-4 justify-center">
                        <video
                          className="bg-black h-[32rem] w-auto object-cover rounded-md border border-slate-300"
                          src={`${Env.server}${
                            props.alert.questions?.[tryIndex as number]?.answers
                              ?.videos[0].url
                          }`}
                          controls
                          preload="auto"
                        />
                      </div>
                    </>
                  )}
                <PageBlockHr />
                <Controls
                  annotable
                  controls={flatMapControls(
                    props.alert.controls?.[tryIndex as number] || []
                  )}
                />
                {(tryIndex as number) + 1 === props.alert.questions?.length && (
                  <>
                    <PageBlockHr />
                    <div className="flex flex-col space-y-2">
                      <SectionSmall>Customer Data matching</SectionSmall>
                      <Info>
                        Comparison of customer given information and identity
                        document information.
                      </Info>
                      <div className="flex flex-row space-x-4 py-2">
                        <ScoreCard
                          name="First Name"
                          score={
                            props.alert.intermediate_scoring?.first_name || 0
                          }
                        />
                        <ScoreCard
                          name="Last Name"
                          score={
                            props.alert.intermediate_scoring?.last_name || 0
                          }
                        />
                        <ScoreCard
                          name="Date of Birth"
                          score={
                            props.alert.intermediate_scoring?.date_of_birth || 0
                          }
                        />
                        {props.alert.intermediate_scoring?.nationality !==
                          undefined && (
                          <ScoreCard
                            name="Nationality"
                            score={
                              props.alert.intermediate_scoring?.nationality || 0
                            }
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
                {!anonymized && (
                  <>
                    <PageBlockHr />
                    <IdentityDocuments
                      answers_attachment_urls={
                        props.alert.questions?.[tryIndex as number]?.answers
                          ?.attachment_urls
                      }
                    />
                    <PageBlockHr />
                    <IdentityDocumentDetails
                      firstname={holder_detail?.first_name}
                      lastname={holder_detail?.last_name}
                      birth_date={holder_detail?.birth_date}
                      gender={holder_detail?.gender}
                      document_number={document_detail?.document_number}
                      nationality={holder_detail?.nationality}
                      emit_country={document_detail?.emit_country}
                      emit_date={document_detail?.emit_date}
                      expiration_date={document_detail?.expiration_date}
                      mrz={
                        props.alert.identity_contents?.[tryIndex as number]?.mrz
                      }
                    />
                  </>
                )}
              </>
            )}
          </>
        );
      }}
    />
  );
}

const ScoreCard = ({ score, name }: { score: number; name: string }) => {
  return (
    <div
      className={twMerge(
        "w-1/4 flex flex-col rounded p-2 border border-slate-600 space-y-2 grow bg-slate-500/10",
        score <= 75 && "bg-red-500/10"
      )}
    >
      <div className="flex flex-row justify-between">
        <Base>{name}</Base>
        <Tag>{Math.floor(score || 0) ?? 0}%</Tag>
      </div>
      <div
        className={twMerge(
          "bg-slate-200 h-2 overflow-hidden rounded",
          score <= 75 && "bg-red-200"
        )}
      >
        <div
          className={twMerge(
            "h-full",
            score > 95 && "bg-green-400",
            score > 75 && score <= 95 && "bg-orange-400",
            score <= 75 && "bg-red-400"
          )}
          style={{
            width: `${score ?? 0}%`,
          }}
        ></div>
      </div>
    </div>
  );
};
