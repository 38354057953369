import { Button } from "@atoms/button/button";
import InputDate from "@atoms/input/input-date";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import Select from "@atoms/select";
import { SearchBar } from "@components/search-bar";
import {
  RestSearchQuery,
  RestSearchRequest,
} from "@components/search-bar/utils/types";
import { buildQueryFromMap } from "@components/search-bar/utils/utils";
import { useAgents } from "@features/agents/state/use-agents";
import { useReviewGroups } from "@features/agents/state/use-review-groups";
import { AuthJWT } from "@features/auth/jwt";
import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";
import { CustomMatrixApiClient } from "@features/custom-matrix/api-client/api-client";
import { CustomersApiClient } from "@features/customers/api-client/api-client";
import { useCustomers } from "@features/customers/state/use-customers";
import { extractCustomerFullName } from "@features/customers/utils";
import { useTransactions } from "@features/kyt/state/use-transactions";
import { KytTransactionsRequestOptions } from "@features/kyt/types";
import {
  KYT_TRANSACTION_STATES_LIST,
  newBlockchainCurrencies,
} from "@features/kyt/utils";
import { useControlledEffect } from "@features/utils";
import { useStoredState } from "@features/utils/use-stored-state";
import _, { isEqual } from "lodash";
import { useState } from "react";

type SearchFiltersType = Partial<{
  search: string;
  review_groups: string[] | null;
  only_without_review_groups: boolean;
  assigned_to_member_id: number[] | null;
  only_without_assignment: boolean;
}>;

export const KytTransactionsListFilter = ({
  value,
  onChange,
  loading,
}: {
  value: RestSearchRequest;
  onChange: (params: RestSearchRequest) => void;
  loading?: boolean;
}) => {
  const { reviewGroups } = useReviewGroups({ access: "KYT" });
  const { schema } = useTransactions();

  const { fields: customFields } = useCustomFields();
  const transactionsCustomFields = customFields.filter(
    (field) => field.field_source === 8
  );

  const monthAgo = new Date();
  monthAgo.setMonth(monthAgo.getMonth() - 1);

  const [advancedQuery, setAdvancedQuery] = useState<RestSearchQuery[]>([]);
  const [formFilter, setFormFilters] = useStoredState<
    Partial<KytTransactionsRequestOptions>
  >("kyt_search_form", {
    date: { gte: monthAgo.toISOString() },
  });

  const [filters, setFilters] = useStoredState<Partial<SearchFiltersType>>(
    "kyt_search",
    {}
  );

  const { members } = useAgents();

  const { schema: schemaCustomer } = useCustomers();
  const kytContexts = [
    ...(schemaCustomer.find((a) => a.external_key === "kyt_contexts")
      ?.possible_values || [{ label: "No context", value: "no_context" }]),
  ];

  const blockchainCurrencies = newBlockchainCurrencies();
  const currencyOptions = [
    ...(formFilter.crypto_blockchain
      ? [
          { value: "converted", label: "~ Converted (EUR)" },
          ...blockchainCurrencies[formFilter.crypto_blockchain].map(
            (currency) => ({
              value: currency.code,
              label: currency.label,
            })
          ),
        ]
      : [
          { value: "converted", label: "~ Converted (EUR)" },
          { value: "EUR", label: "EUR" },
          ...Object.keys(blockchainCurrencies)
            .flatMap((blockchain) =>
              blockchainCurrencies[blockchain].map((currency) => currency)
            )
            .map((currency) => {
              if (currency?.code?.includes("CUSTOM_")) {
                currency.code = currency?.code?.replace(/\bCUSTOM_/g, "");
              }
              return {
                value: currency.code,
                label: currency.label,
              };
            }),
        ]),
  ];

  // Need members actives
  const membersActives = members.filter(
    (agent) =>
      agent.active &&
      agent.clients.filter(
        (client) => client.active && client.id === AuthJWT.clientId
      ).length > 0
  );

  const handleSearchTransaction = async () => {
    const { ...form } = _.omit(formFilter, ["date", "amount"]);
    const formAmount = _.omitBy(formFilter?.amount, _.isNil);
    const formDate = _.omitBy(formFilter?.date, _.isNil);

    if (form?.currency === "converted" || !form?.currency) {
      form.converted_amount = { gte: formAmount.gte, lte: formAmount.lte };
      delete form.currency;
      delete formAmount.gte;
      delete formAmount.lte;
    }

    const formDateLte = formDate.lte && (new Date(formDate.lte) as Date);
    if (formDateLte) {
      formDateLte.setTime(formDateLte.getTime() + 24 * 60 * 60 * 1000);
      formDateLte.setTime(formDateLte.getTime() - 1);
    }

    onChange({
      query: [
        ...advancedQuery,
        ...buildQueryFromMap({
          ...form,
          ...(Object.keys(formDate).length > 0 && {
            date: {
              range_date: { ...formDate, lte: formDateLte },
            },
          }),
          ...(Object.keys(formAmount).length > 0 && {
            amount: {
              range_number: formAmount,
            },
          }),
          ...(Object.keys(form.converted_amount || {}).length > 0 && {
            converted_amount: {
              range_number: form.converted_amount,
            },
          }),
        }),
        ...buildQueryFromMap({
          review_groups: filters.only_without_review_groups
            ? undefined
            : filters.review_groups,
          assignees: filters.only_without_assignment
            ? members.map((a) => a.id)
            : undefined,
        }).map((a) => ({ ...a, not: true })),
      ],
      options: { ...value.options, offset: 0 },
    });
  };

  const searchByName = async (searchName: string) => {
    // call user if realExternal ID or customers
    const res = await CustomersApiClient.getCustomersAdvanced(
      searchName
        ?.replace(/-/gm, " ")
        .split(" ")
        .filter((a: string) => a.trim())
        .map((q: string) => ({
          key: "search_aggregate",
          operations: [
            {
              op: "regexp",
              value: {
                string: q, // For - in external_id
              },
            },
          ],
        })),
      {}
    );
    return res.data.map((user) => ({
      name: extractCustomerFullName(user),
      externalId: user.external_id,
    }));
  };

  const [didInitialFilter, setDidInitialFilter] = useState(false);
  useControlledEffect(() => {
    if (!didInitialFilter && advancedQuery?.length) {
      handleSearchTransaction();
      setDidInitialFilter(true);
    }
  }, [advancedQuery]);

  return (
    <div className="flex flex-col space-y-2 xl:flex-row xl:space-x-2 xl:space-y-0">
      <div className="w-full xl:w-1/2 flex flex-col">
        <label className="block text-sm mb-1 font-medium text-gray-700 dark:text-slate-400 whitespace-nowrap overflow-hidden text-ellipsis">
          Search
        </label>
        <SearchBar
          debounce={1}
          schema={{
            table: "transactions",
            fields: (schema.data?.fields || []).map((field) => {
              const customField = transactionsCustomFields?.find(
                (a) => a.label === field.label
              );
              const displayName = customField?.header_name || field.label;
              const isMatrix = customField?.format?.type?.match(/matrix_/);

              return {
                key: field.key,
                label: displayName,
                keywords: (
                  displayName +
                  " " +
                  field.label.replace(/ /gm, "") +
                  " " +
                  field.key
                ).split(/( |_)/),
                allowed_ops: field.allowed_ops.filter((a: string) =>
                  isMatrix ? a !== "regexp" : true
                ),
                type: field.type,
              };
            }),
          }}
          onSuggest={async (_table: string, column: string, query) => {
            if (column === "assignees") {
              return membersActives.map(({ id, name }) => ({
                value: id.toString(),
                label: name,
              }));
            }
            if (column === "context") {
              return kytContexts.map((el) => ({
                value: el.value.toLowerCase(),
                label: el.label,
              }));
            }
            if (column === "review_groups") {
              return _.sortBy(
                reviewGroups.map((el) => ({
                  value: el.toLowerCase(),
                  label: el,
                })),
                "label"
              );
            }
            if (column === "currency") {
              return _.sortBy(
                currencyOptions.map(({ value, label }) => {
                  return {
                    value: value.toLowerCase(),
                    label,
                  };
                }),
                "label"
              );
            }
            if (column === "state") {
              return _.sortBy(
                KYT_TRANSACTION_STATES_LIST.map(({ id, label }) => ({
                  value: id.toString(),
                  label,
                })),
                "label"
              );
            }

            const matrixCustomFieldId = transactionsCustomFields
              .find(
                (a) =>
                  column === "fields." + a.label &&
                  a.format?.type?.match(/matrix_/)
              )
              ?.format?.type?.split("_")?.[1];
            if (matrixCustomFieldId) {
              try {
                const res = await CustomMatrixApiClient.getMatrix(
                  matrixCustomFieldId
                );
                return _.sortBy(
                  res?.map((a) => {
                    return {
                      value: a.key,
                      label: a.label,
                    };
                  }),
                  "label"
                );
              } catch (error) {
                console.log("error getting matrix", error);
              }
            }

            if (
              [
                "from.real_external_id",
                "to.real_external_id",
                "from.external_id",
                "to.external_id",
                "customers",
              ].includes(column)
            ) {
              return query
                ? _.sortBy(
                    (await searchByName(query || "")).map(
                      ({ name, externalId }) => ({
                        value: externalId,
                        label: name ? `${name} (${externalId})` : externalId,
                      })
                    ),
                    "label"
                  )
                : [];
            }
            return [];
          }}
          onChange={(str: any) => {
            if (str.valid && !isEqual(str.fields, advancedQuery)) {
              setAdvancedQuery(str.fields);
            }
          }}
        />
      </div>
      <div className="w-full xl:w-2/6 flex flex-col">
        <div className="flex flex-row relative">
          <InputLabel
            label="Created After"
            className="w-full xl:w-1/2 focus-within:z-10 -mr-px"
            input={
              <InputDate
                className="rounded-r-none"
                highlight
                value={formFilter?.date?.gte ?? null}
                onChange={(e) =>
                  setFormFilters({
                    ...formFilter,
                    date: { ...formFilter.date, gte: e?.toISOString() ?? null },
                  })
                }
              />
            }
          />
          <InputLabel
            label="Created Before"
            className="w-full xl:w-1/2 focus-within:z-10"
            input={
              <InputDate
                className="rounded-l-none"
                highlight
                value={formFilter.date?.lte ?? null}
                onChange={(e) =>
                  setFormFilters({
                    ...formFilter,
                    date: { ...formFilter.date, lte: e?.toISOString() ?? null },
                  })
                }
              />
            }
          />
        </div>
      </div>
      <div className="w-full xl:w-1/3 flex flex-col">
        <InputLabel
          label="Amount"
          input={
            <div className="flex relative">
              <Input
                placeholder="Min"
                className="rounded-r-none focus:z-10 -mr-px"
                highlight
                value={formFilter.amount?.gte ?? ""}
                type="number"
                onChange={(e) =>
                  setFormFilters({
                    ...formFilter,
                    amount: {
                      ...formFilter.amount,
                      gte: parseFloat(e.target.value) || null,
                    },
                  })
                }
              />
              <Input
                placeholder="Max"
                className="w-full rounded-none focus:z-10"
                highlight
                value={formFilter.amount?.lte ?? ""}
                type="number"
                onChange={(e) =>
                  setFormFilters({
                    ...formFilter,
                    amount: {
                      ...formFilter.amount,
                      lte: parseFloat(e.target.value) || null,
                    },
                  })
                }
              />
              <SelectMultiple
                highlight
                selectionLimit={1}
                placeholder="Currency"
                className="focus:z-10 -ml-px rounded-l-none min-w-28"
                value={formFilter.currency ? [formFilter.currency] : []}
                onChange={(e) => {
                  return setFormFilters({
                    ...formFilter,
                    currency: e.length > 0 ? e[0] : null,
                  });
                }}
                options={currencyOptions}
              />
            </div>
          }
        />
      </div>
      <InputLabel
        className="xl:w-1/6"
        label="Context"
        input={
          <Select
            value={
              formFilter.context === ""
                ? "no_context"
                : formFilter.context || "all"
            }
            onChange={(e) =>
              setFormFilters({
                ...formFilter,
                context:
                  e.target.value === "all"
                    ? null
                    : e.target.value === "no_context"
                    ? ""
                    : e.target.value || null,
              })
            }
          >
            {kytContexts.map((context) => (
              <option key={context.value} value={context.value}>
                {context.label}
              </option>
            ))}
          </Select>
        }
      />
      <InputLabel
        className="xl:w-1/6"
        label="Review groups"
        input={
          <SelectMultiple
            highlight
            value={
              filters.only_without_review_groups
                ? ["none"]
                : filters.review_groups || []
            }
            options={[
              { value: "none", label: "(None)" },
              ..._.sortBy(
                reviewGroups.map((c) => ({
                  value: c,
                  label: c,
                })),
                "label"
              ),
            ]}
            onChange={(e) =>
              setFilters({
                ...filters,
                only_without_review_groups:
                  e.includes("none") && filters.only_without_review_groups
                    ? // aka : already included "none"
                      false // unselect "none"
                    : e.includes("none"),
                review_groups:
                  e.includes("none") && filters.only_without_review_groups
                    ? e.splice(e.indexOf("none"), 1) && e
                    : !e.includes("none")
                    ? e
                    : null,
              })
            }
          />
        }
      />
      <Button
        className="xl:flex xl:self-end xl:px-4"
        theme="primary"
        size="md"
        onClick={() => handleSearchTransaction()}
        shortcut={["enter"]}
        loading={loading}
      >
        Search
      </Button>
    </div>
  );
};
