import { useAuth } from "@features/auth/state/hooks";
import { queryClient } from "@features/query";
import { websocketBus } from "@features/websockets";
import _ from "lodash";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { MonitoringApiClient } from "../api-client/api-client";
import {
  PendingTasksMonitoringType,
  UpdateTasksSocketEventData,
} from "../types";
import { useHasAccess } from "@features/auth/state/use-access";

export const usePendingTasks = () => {
  const hasAccess = useHasAccess();
  const pendingScanTasks = useQuery<PendingTasksMonitoringType>({
    queryKey: ["pendingScanTasks"],
    enabled: hasAccess("ALERT"),
    queryFn: async () => {
      return await MonitoringApiClient.getScanTasks();
    },
    onError: (error) => {
      console.log("Error loading pending scan tasks", error);
    },
    refetchOnWindowFocus: false,
    cacheTime: 2000,
  });

  const pendingCustomersTasks = useQuery<PendingTasksMonitoringType>({
    queryKey: ["pendingCustomersTasks"],
    enabled: hasAccess("CUSTOMER_READ"),
    queryFn: async () => {
      return await MonitoringApiClient.getCustomersTasks();
    },
    onError: (error) => {
      console.log("Error loading pending customers tasks", error);
    },
    refetchOnWindowFocus: false,
    cacheTime: 2000,
  });

  const pendingOnboardingTasks = useQuery<PendingTasksMonitoringType>({
    queryKey: ["pendingOnboardingTasks"],
    enabled: hasAccess("SESSION_READ"),
    queryFn: async () => {
      return await MonitoringApiClient.getOnboardingTasks();
    },
    onError: (error) => {
      console.log("Error loading pending onboarding tasks", error);
    },
    refetchOnWindowFocus: false,
    cacheTime: 2000,
  });

  const pendingChatTasks = useQuery<PendingTasksMonitoringType>({
    queryKey: ["pendingChatTasks"],
    enabled: hasAccess("CHAT"),
    queryFn: async () => {
      return await MonitoringApiClient.getChatTasks();
    },
    onError: (error) => {
      console.log("Error loading pending chat tasks", error);
    },
    refetchOnWindowFocus: false,
    cacheTime: 2000,
  });

  const pendingPressTasks = useQuery<PendingTasksMonitoringType>({
    queryKey: ["pendingPressTasks"],
    enabled: hasAccess("PRESS"),
    queryFn: async () => {
      return await MonitoringApiClient.getPressTasks();
    },
    onError: (error) => {
      console.log("Error loading pending press tasks", error);
    },
    refetchOnWindowFocus: false,
    cacheTime: 2000,
  });

  return {
    pendingScanTasks: pendingScanTasks.data,
    pendingCustomersTasks: pendingCustomersTasks.data,
    pendingOnboardingTasks: pendingOnboardingTasks.data,
    pendingChatTasks: pendingChatTasks.data,
    pendingPressTasks: pendingPressTasks.data,
    loading:
      pendingScanTasks.isLoading ||
      pendingCustomersTasks.isLoading ||
      pendingOnboardingTasks.isLoading ||
      pendingChatTasks.isLoading ||
      pendingPressTasks.isLoading,
  };
};

export const usePendingTasksRealtime = () => {
  const { clientId } = useAuth();

  const debouncedRefreshCustomersTaskCount = _.debounce(
    () => {
      queryClient.invalidateQueries({
        queryKey: ["pendingCustomersTasks"],
      });
    },
    5000,
    { leading: true, trailing: true }
  );

  const debouncedRefreshScanTaskCount = _.debounce(
    () => {
      queryClient.invalidateQueries({
        queryKey: ["pendingScanTasks"],
      });
    },
    5000,
    { leading: true, trailing: true }
  );

  const debouncedRefreshOnboardingTaskCount = _.debounce(
    () => {
      queryClient.invalidateQueries({
        queryKey: ["pendingOnboardingTasks"],
      });
    },
    5000,
    { leading: true, trailing: true }
  );

  const debounceRefreshChatTaskCount = _.debounce(
    () => {
      queryClient.invalidateQueries({
        queryKey: ["pendingChatTasks"],
      });
    },
    5000,
    { leading: true, trailing: true }
  );

  const debouncedRefreshPressTaskCounts = _.debounce(
    () => {
      queryClient.invalidateQueries({
        queryKey: ["pendingPressTasks"],
      });
    },
    5000,
    { leading: true, trailing: true }
  );

  useEffect(() => {
    if (clientId) {
      const room = `client/${clientId}/tasks`;
      websocketBus.emit("join", null, { room });
      websocketBus.on(room, (event: { data: UpdateTasksSocketEventData }) => {
        switch (event.data.task_count_category) {
          case "customers":
            debouncedRefreshCustomersTaskCount();
            break;
          case "scan":
            debouncedRefreshScanTaskCount();
            break;
          case "onboarding":
            debouncedRefreshOnboardingTaskCount();
            break;
          case "chat":
            debounceRefreshChatTaskCount();
            break;
          case "press":
            debouncedRefreshPressTaskCounts();
            break;
          default:
            break;
        }
      });
      return () => {
        websocketBus.emit("leave", null, { room });
        websocketBus.off(room);
      };
    }
  }, [clientId]);

  return {};
};
